import Api from '../../api/Api'
const tahunajaran = {

    //set namespace true
    namespaced: true,

    //state
    state: {
       
        tahunajaranall:[],
        tahunajarans: [],
        tahunajarandetail: {},
        baru: {},
        active:{},
    },

    //mutations
    mutations: {
        SET_ALL_DATA(state, tahunajaranall) {
            state.tahunajaranall = tahunajaranall
        },

       
        SET_DATA(state, tahunajarans) {
            state.tahunajarans = tahunajarans
        },
    
        SET_BARU(state, baru) {
            state.baru = baru
        },
    
        SET_ACTIVE(state,active) {
            state.active = active
        },
    
        SET_DETAIL_DATA(state, tahunajarandetail) {
    
        //set value state "role"
        state.tahunajarandetail = tahunajarandetail
        },
    },

    _actions: {
      
      
        getAll({ commit }) {
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/tahunajaran/getall`)
                .then(response => {
                    // console.log('ini tahunahjatan',response.data.data)
                    commit('SET_ALL_DATA', response.data.data)
                })
        },

        getDetail({ commit }, payload) {

            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/tahunajaran/edit/${payload}`)
                .then(response => {
                    //console.log('ini', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('SET_DETAIL_DATA', response.data.data)

                })
        },
        getData({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/tahunajaran?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_DATA', response.data.data)
                })
        },

        getBaru({ commit }) {
            // //console.log('ini parsing',payload);
           
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/tahunajaran/baru`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_BARU', response.data.data)
                })
        },

        getActive({ commit }) {
            // //console.log('ini parsing',payload);
           
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/tahunajaran/active`)
                .then(response => {
                    // console.log('ini parent',response.data.data)
                    commit('SET_ACTIVE', response.data.data)
                })
        },

        destroy({ commit }, payload) {
            // Define variable token
            const token = localStorage.getItem('token')

            return new Promise((resolve, reject) => {
                Api.defaults.headers.common['Authorization'] = "Bearer " + token
                Api.get(`/tahunajaran/delete/${payload}`)
                    .then(response => {
                        // Commit ke mutation SET_user
                        commit('SET_user', response.data.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.error(error.response.data)
                        reject(error.response.data)
                    })
            })
        },


        store({ commit }, formData) {
          

            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/tahunajaran', formData)

                    .then(response => {

                        //console.log(response.data.data)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        update({ commit }, { tahunajaranId, payload }) {



            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

               
                Api.post(`/tahunajaran/update/${tahunajaranId}`, payload)

                    .then(response => {

                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        updatetoactive({ commit }, payload ) {

            
            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

               
                Api.post(`/tahunajaran/active`, payload)

                    .then(response => {
                        //console.log('hasil', response.data.data)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },
        
    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
        getAll(state) {
            return state.tahunajaranall
        },

        getDetail(state) {
            return state.tahunajarandetail
        },

        getData(state) {
            return state.tahunajarans
        },

        getActive(state) {
            return state.active
        },

        getBaru(state) {
            return state.baru
        },

      
    }

}

export default tahunajaran