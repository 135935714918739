import moment from 'moment';

const mixins = {

  methods: {
    tanggalIndo(date) {
      // const dates = moment(date).tz('Asia/Jakarta'); // Menentukan zona waktu
      // return dates.format('D MMMM Y');
      return moment(date).format('D MMMM Y');
    },

    tanggalIndoThree(date) {
      return moment(date).format('D MMM Y');
    },
  }
}

export default mixins