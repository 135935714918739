<template>
    <div class="container-fluid p-5">
        <div class="row mt-4">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="d-flex justify-content-between">
                            <h3>Input Nilai Angka</h3>

                            <div class="d-flex gap-3">
                                <p>Nama Pengajar : {{ guru.fullname }}</p>
                                <router-link :to="{ name: 'admin-penilaian' }"
                                    class="btn btn-sm btn-primary">Kembali</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div>
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="exampleFormControlInput1">Pilih kelas yang diajar:<span
                                            class="text-danger">*</span></label>
                                    <select class="form-control" v-model="data.kelasid" @change="changeSiswa">
                                        <option value="">-- select kelas --</option>
                                        <option v-for="k in kelas" :key="k.id" :value="k.id" :id-ta="k.id_ta"
                                            :id-mapel="k.idmapel" :jenis-penilaian="k.jenis_penilaian"
                                            :id-unit="k.id_unit">{{ k.classname
                                            }} -
                                            {{ k.nama_mapel }}</option>
                                    </select>

                                </div>
                            </div>
                        </div>


                         <!-- {{ jenissemesteraktif ? jenissemesteraktif.jenis_penilaian_aktif : '' }} -->

                        <div class="mt-5" v-if="data.kelasid > 0">

                            <div class="table-responsive table-container">
                                <table id="datatable-search" class="table table-bordered"
                                    style="border: 2px solid black; font-size: 12px;">
                                    <thead class="thead-light" style="border: 2px solid black;">
                                        <tr style="max-height: 5px; border: 2px solid black;">
                                            <th
                                                style="text-align: center; vertical-align: middle; position: sticky; left: 0; top:0; background-color:#fff; z-index: 2;border: 2px solid black; ">
                                            </th>
                                            <th v-for="ranah in kategori" :key="ranah.id"
                                                style="text-align: center; vertical-align: middle; border: 2px solid black;"
                                                :colspan="`${ranah.jumlah_generate}`">
                                                {{ ranah.subkategori.jumlahgenerate }}{{ ranah.nama_kategoripenilaian }}
                                            </th>
                                            <th colspan="5"
                                                style="text-align: center; vertical-align: middle; border: 2px solid black;">
                                                Rekap Nilai</th>
                                        </tr>
                                        <tr style="border: 2px solid black;">
                                            <th
                                                style="text-align: center; vertical-align: middle; position: sticky; left: 0; top:0; background-color:#f2f2f2; z-index: 2; border: 2px solid black;">
                                                Nama Siswa</th>
                                                
                                            <template v-for="ranah in kategori">
                                                <th v-for="n in ranah.jumlah_generate" :key="n"
                                                    style="text-align: center; vertical-align: middle; border: 2px solid black;"
                                                    align="center">{{ ranah.nama_subkategoripenilaian }}{{
                                                        ranah.jumlah_generate !== 1 ? n : '' }}</th>


                                            </template>

                                            <th
                                                style="text-align: center; vertical-align: middle; border: 2px solid black;">
                                                P</th>
                                            <th
                                                style="text-align: center; vertical-align: middle; border: 2px solid black;">
                                                K</th>
                                            <th
                                                style="text-align: center; vertical-align: middle; border: 2px solid black;">
                                                NM</th>
                                            <th
                                                style="text-align: center; vertical-align: middle; border: 2px solid black;">
                                                NP</th>
                                            <th
                                                style="text-align: center; vertical-align: middle; border: 2px solid black;">
                                                NA</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="m in siswa" :key="m.id" style="border: 2px solid black;">
                                            <td
                                                style="position: sticky; left: 0; background-color:#fff; z-index:3; border: 2px solid black;">
                                                {{ m.nama }} </td>
                                            <template v-for="ranah in kategori">
                                                <td v-for="(n, index) in ranah.jumlah_generate" :key="index"
                                                    align="center"
                                                    style="background-color:#fff;z-index: -2; border: 2px solid black; max-width: 30px;">

                                                    <input class="form-nilai" type="number" min="0" max="100"
                                                        :data-id="m[`id_tugas_${n}${ranah.nama_subkategoripenilaian}`]"
                                                        :order="n"
                                                        :v-model="m[`nilai_tugas_${n}${ranah.nama_subkategoripenilaian}`]"
                                                        :value="m[`nilai_tugas_${n}${ranah.nama_subkategoripenilaian}`]"
                                                        :idsiswa="m.id_siswa" :idsubkategori="ranah.id_sub"
                                                        :idkategori="ranah.id"
                                                        style="text-align: center;max-width: 30px;"
                                                        @keydown.enter="handleNilai($event, n, index)">
                                                </td>

                                            </template>

                                            <td
                                                style="background-color:#fff;z-index: -2; border: 2px solid black;text-align: center;">

                                                <span
                                                    v-if="Math.round(m['average_p'] == null ? 0 : m['average_p']) !== 0">
                                                    {{ Math.round(m['average_p'] == null ? 0 : m['average_p']) }}
                                                </span>

                                            </td>

                                            <td
                                                style="background-color:#fff;z-index: -2; border: 2px solid black;text-align: center;">
                                                <span
                                                    v-if="Math.round(m['average_k'] == null ? 0 : m['average_k']) !== 0">
                                                    {{ Math.round(m['average_k'] == null ? 0 : m['average_k']) }}
                                                </span>

                                            </td>


                                            <td
                                                style="background-color:#fff;z-index: -2; border: 2px solid black;text-align: center;">
                                                <span
                                                    v-if="Math.round(m['average_nm'] == null ? 0 : m['average_nm']) !== 0">
                                                    {{ Math.round(m['average_nm'] == null ? 0 : m['average_nm']) }}
                                                </span>

                                            </td>

                                            <td style="background-color:#fff;z-index: -2; border: 2px solid black;">
                                                <input class="form-nilai" type="number" min="0" max="100"
                                                    :idsiswa="m.id_siswa" style=" text-align: center;max-width: 30px;"
                                                    :value="m['average_pa']"
                                                    @keydown.enter="handlePernyataan($event, n, index)">
                                            </td>




                                            <td style="background-color:#fff;z-index: -2; border: 2px solid black;text-align: center;"
                                                v-if="getNilai(m.id_siswa) != ''">
                                                {{ getNilai(m.id_siswa) }}

                                            </td>

                                            <td style="background-color:#fff;z-index: -2; border: 2px solid black;text-align: center;"
                                                v-else>
                                                <span
                                                    v-if="Math.round(m['average_na'] == null ? 0 : m['average_na']) !== 0">
                                                    {{ Math.round(m['average_na'] == null ? 0 : m['average_na']) }}
                                                </span>

                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapMutations } from "vuex";
import { computed, ref, reactive, onMounted, onUnmounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Swal from 'sweetalert2';
import { useRouter } from 'vue-router';

// const body = document.getElementsByTagName("body")[0];
export default {
    name: "inputnilai",
    // created() {
    //     this.$store.state.hideConfigButton = true;
    //     this.toggleDefaultLayout();
    //     body.classList.remove("bg-gray-100");
    // },
    // beforeUnmount() {
    //     this.$store.state.hideConfigButton = false;
    //     this.toggleDefaultLayout();
    //     body.classList.add("bg-gray-100");
    // },

    setup() {

        const router = useRouter();
        const goBack = () => {
            router.go(-1);
        }

        const inputRefs = ref({});
        const data = reactive({
            kelasid: '',
            idunit: '',
            jenis: '',
            pages: 'penilaian-view',
            jenisPenilaian: '',
            idmapel: ''
        });

        const guru = reactive({
            fullname: '',
            nickname: '',
            gender: '',
            birthplace: '',
            birthdate: '',
            marital_status: '',
            wni: '',
            religion: '',
            edulevel: '',
            address: '',
            id_province: '',
            id_city: '',
            id_district: '',
            id_subdistrict: '',
            cellphone: '',
            email: '',
            lang: '',
            nuptk: '',
        });


        const validation = ref([]);
        const store = useStore();
        const route = useRoute();

        onMounted(async () => {
            await store.dispatch("guru/getDetail", route.params.id);
            await store.dispatch("bebanmengajar/getKelasGuruData", route.params.id);
            // await store.dispatch("bebanmengajar/getSiswa")
            await store.dispatch("nilaipernyataan/getData");

            await store.dispatch("kategoripenilaian/getKategori");
            const inputs = document.querySelectorAll('.form-nilai');
            inputs.forEach((input) => {
                input.addEventListener('keydown', focusNextInput());
            });
            const permissions = localStorage.getItem('permission');

            if (!permissions.includes(data.pages)) {
                router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
            }

        });

        onUnmounted(() => {
            const inputs = document.querySelectorAll('.form-nilai');
            inputs.forEach((input) => {
                input.removeEventListener('keydown', focusNextInput());
            });
        });



        const kelas = computed(() => {
            return store.getters["bebanmengajar/getKelasGuruData"];
        });

        const siswa = computed(() => {
            return store.getters["bebanmengajar/getSiswa"];
        });

        const kategori = computed(() => {
            return store.getters["kategoripenilaian/getKategori"];
        });

        const changeSiswa = async (event) => {
            data.jenisPenilaian = event.target.options[event.target.selectedIndex].getAttribute('jenis-penilaian');
            data.idunit = event.target.options[event.target.selectedIndex].getAttribute('id-unit');
            const selectedIdMapel = event.target.options[event.target.selectedIndex].getAttribute('id-mapel');
            data.idmapel = event.target.options[event.target.selectedIndex].getAttribute('id-mapel');
            const selectedIdUnit = event.target.options[event.target.selectedIndex].getAttribute('id-unit');
            await store.dispatch("bebanmengajar/getSiswa", { id: data.kelasid, idmapel: selectedIdMapel, guruid: route.params.id });
            await store.dispatch("nilaipernyataan/getData", data.kelasid);
            await store.dispatch("aturannilai/getAktif", selectedIdUnit);
            // data.jenis = result ? result.jenis_penilaian_aktif : '';
            await store.dispatch("kategoripenilaian/getKategori", data.kelasid);
        };


        const nilaipernyataans = computed(() => {
            return store.getters["nilaipernyataan/getData"];
        });

        const jenissemesteraktif = computed(() => {
            return store.getters["aturannilai/getAktif"];
        });

        const getNilai = (idSiswa) => {
            const nilai = nilaipernyataans.value.find(item => {

                return (
                    item.id_siswa === idSiswa
                );
            });

            return nilai ? nilai.nilai : '';

        };



        const focusNextInput = (e) => {


            var total = 0;

            // Loop melalui setiap objek dalam kategori.value dan tambahkan jumlahnya ke total
            for (var i = 0; i < kategori.value.length; i++) {
                total += kategori.value[i].jumlah_generate;
            }

            // Hasilnya akan disimpan dalam variabel 'total'
            // console.log("Total jumlah generate:", total);


            // const posisi = index;

            if (e.key === 'Enter' || e.key === 'Tab') {
                e.preventDefault();
                const targetInput = e.target;
                const inputs = document.querySelectorAll('.form-nilai');
                const currentIndex = Array.from(inputs).indexOf(targetInput);
                const nextIndex = currentIndex + (total + 1);

                if (nextIndex >= 0 && nextIndex < inputs.length) {
                    inputs[nextIndex].focus();
                } else {
                    const nextIndex = currentIndex + 1;
                    inputs[nextIndex].focus();
                    // Jika nextIndex di luar rentang, lakukan penanganan yang sesuai
                    // console.log('Elemen tidak ditemukan atau indeks di luar rentang.');
                    // Atau lakukan sesuatu yang lain sesuai kebutuhan aplikasi Anda
                }
            }

        };


        async function updateDataPernyataan(e) {


            const inputValue = e.target.value;
            const inputElement = e.target;
            if (inputValue != "") {
                if (inputValue > 100) {
                    Swal.fire({
                        title: "GAGAL!",
                        text: "nilai maksimal 100",
                        icon: "error",
                        showConfirmButton: false,
                        timer: 2000,
                    });

                } else if (inputValue < 0) {
                    Swal.fire({
                        title: "GAGAL!",
                        text: "nilai diantara 0 sd 100",
                        icon: "error",
                        showConfirmButton: false,
                        timer: 2000,
                    });

                } else {

                    if (inputValue.length) {
                        let formData = new FormData();

                        if (inputElement && inputElement.hasAttribute('data-id')) {
                            formData.append('nilai', e.target.value)
                            formData.append('id_siswa', e.target.attributes[4].nodeValue)
                            formData.append('bebanmengajar_id', data.kelasid)
                        } else {

                            formData.append('nilai', e.target.value)
                            formData.append('id_siswa', e.target.attributes[4].nodeValue)
                            formData.append('bebanmengajar_id', data.kelasid)
                        }

                        await store.dispatch('nilaipernyataan/store', formData)
                            .then(() => {
                                store.dispatch("nilaipernyataan/getData");
                                store.dispatch("bebanmengajar/getSiswa", { id: data.kelasid, idmapel: data.idmapel, guruid: route.params.id })
                            })
                            .catch((error) => {

                                validation.value = error;

                                Swal.fire({
                                    title: "GAGAL!",
                                    text: validation.value.error,
                                    icon: "error",
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                            });
                    } else if (inputValue.trim() === "") {
                        // console.log('trim', e.target.attributes[4].nodeValue);
                        let formData = new FormData();
                        if (inputElement && inputElement.hasAttribute('data-id')) {
                            formData.append('nilai', e.target.value)
                            formData.append('id_siswa', e.target.attributes[4].nodeValue)
                            formData.append('bebanmengajar_id', data.kelasid)
                        } else {
                            formData.append('nilai', e.target.value)
                            formData.append('id_siswa', e.target.attributes[4].nodeValue)
                            formData.append('bebanmengajar_id', data.kelasid)
                        }
                        await store.dispatch('nilaipernyataan/store', formData)
                            .then(() => {
                                store.dispatch("bebanmengajar/getSiswa", { id: data.kelasid, idmapel: data.idmapel, guruid: route.params.id })
                                store.dispatch("nilaipernyataan/getData");
                            })
                            .catch((error) => {

                                validation.value = error;

                                Swal.fire({
                                    title: "GAGAL!",
                                    text: validation.value.error,
                                    icon: "error",
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                            });
                    }
                }

            }

        }


        async function updateData(e) {


            const inputValue = e.target.value;


            const inputElement = e.target;

            if (inputValue != "") {
                if (inputValue > 100) {
                    Swal.fire({
                        title: "GAGAL!",
                        text: "nilai maksimal 100",
                        icon: "error",
                        showConfirmButton: false,
                        timer: 2000,
                    });

                } else if (inputValue < 0) {
                    Swal.fire({
                        title: "GAGAL!",
                        text: "nilai diantara 0 sd 100",
                        icon: "error",
                        showConfirmButton: false,
                        timer: 2000,
                    });

                }
                else {
                    if (inputValue.length != 0) {
                        let formData = new FormData();

                        if (inputElement && inputElement.hasAttribute('data-id')) {
                            // console.log('data-id', e.target);
                            formData.append('nilai', e.target.value)
                            formData.append('id', e.target.dataset['id'] ?? "")
                            formData.append('order', e.target.attributes[5].nodeValue)
                            formData.append('id_siswa', e.target.attributes[7].nodeValue)
                            formData.append('id_subkategorinilai', e.target.attributes[8].nodeValue)
                            formData.append('id_kategori', e.target.attributes[9].nodeValue)
                            formData.append('bebanmengajar_id', data.kelasid)
                            formData.append('id_mapel', data.idmapel)
                            formData.append('unit', data.idunit)
                            formData.append('jenis_semester', data.jenis)
                            formData.append('jenis_penilaian', data.jenisPenilaian)
                        } else {
                            // console.log('data-ids', e.target);
                            formData.append('nilai', e.target.value)
                            formData.append('id', e.target.dataset['id'] ?? "")
                            formData.append('order', e.target.attributes[4].nodeValue)
                            formData.append('id_siswa', e.target.attributes[5].nodeValue)
                            formData.append('id_subkategorinilai', e.target.attributes[6].nodeValue)
                            formData.append('id_kategori', e.target.attributes[7].nodeValue)
                            formData.append('bebanmengajar_id', data.kelasid)
                            formData.append('id_mapel', data.idmapel)
                            formData.append('unit', data.idunit)
                            formData.append('jenis_semester', data.jenis)
                            formData.append('jenis_penilaian', data.jenisPenilaian)
                        }

                        await store.dispatch('nilai/store', formData)
                            .then(() => {

                                store.dispatch("bebanmengajar/getSiswa", { id: data.kelasid, idmapel: data.idmapel, guruid: route.params.id })
                            })
                            .catch((error) => {

                                validation.value = error;

                                Swal.fire({
                                    title: "GAGAL!",
                                    text: validation.value.error,
                                    icon: "error",
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                            });
                    } else if (inputValue.trim() === "") {
                        // console.log('trim', e.target.attributes[4].nodeValue);
                        let formData = new FormData();
                        if (inputElement && inputElement.hasAttribute('data-id')) {
                            formData.append('nilai', e.target.value)
                            formData.append('id', e.target.dataset['id'] ?? "")
                            formData.append('order', e.target.attributes[5].nodeValue)
                            formData.append('id_siswa', e.target.attributes[6].nodeValue)
                            formData.append('id_subkategorinilai', e.target.attributes[7].nodeValue)
                            formData.append('id_kategori', e.target.attributes[8].nodeValue)
                            formData.append('bebanmengajar_id', data.kelasid)
                            formData.append('id_mapel', data.idmapel)
                            formData.append('unit', data.idunit)
                            formData.append('jenis_semester', data.jenis)
                            formData.append('jenis_penilaian', data.jenisPenilaian)
                        } else {
                            formData.append('nilai', e.target.value)
                            formData.append('id', e.target.dataset['id'] ?? "")
                            formData.append('order', e.target.attributes[4].nodeValue)
                            formData.append('id_siswa', e.target.attributes[5].nodeValue)
                            formData.append('id_subkategorinilai', e.target.attributes[6].nodeValue)
                            formData.append('id_kategori', e.target.attributes[7].nodeValue)
                            formData.append('bebanmengajar_id', data.kelasid)
                            formData.append('id_mapel', data.idmapel)
                            formData.append('unit', data.idunit)
                            formData.append('jenis_semester', data.jenis)
                            formData.append('jenis_penilaian', data.jenisPenilaian)
                        }
                        await store.dispatch('nilai/store', formData)
                            .then(() => {
                                store.dispatch("bebanmengajar/getSiswa", { id: data.kelasid, idmapel: data.idmapel, guruid: route.params.id })
                            })
                            .catch((error) => {

                                validation.value = error;

                                Swal.fire({
                                    title: "GAGAL!",
                                    text: validation.value.error,
                                    icon: "error",
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                            });
                    } else if (inputValue.length == 0) {
                        let formData = new FormData();

                        if (inputElement && inputElement.hasAttribute('data-id')) {
                            // console.log('data-id', e.target);
                            formData.append('nilai', e.target.value)
                            formData.append('id', e.target.dataset['id'] ?? "")
                            formData.append('order', e.target.attributes[5].nodeValue)
                            formData.append('id_siswa', e.target.attributes[7].nodeValue)
                            formData.append('id_subkategorinilai', e.target.attributes[8].nodeValue)
                            formData.append('id_kategori', e.target.attributes[9].nodeValue)
                            formData.append('bebanmengajar_id', data.kelasid)
                            formData.append('id_mapel', data.idmapel)
                            formData.append('unit', data.idunit)
                            formData.append('jenis_semester', data.jenis)
                            formData.append('jenis_penilaian', data.jenisPenilaian)
                        } else {
                            // console.log('data-ids', e.target);
                            formData.append('nilai', e.target.value)
                            formData.append('id', e.target.dataset['id'] ?? "")
                            formData.append('order', e.target.attributes[4].nodeValue)
                            formData.append('id_siswa', e.target.attributes[5].nodeValue)
                            formData.append('id_subkategorinilai', e.target.attributes[6].nodeValue)
                            formData.append('id_kategori', e.target.attributes[7].nodeValue)
                            formData.append('bebanmengajar_id', data.kelasid)
                            formData.append('id_mapel', data.idmapel)
                            formData.append('unit', data.idunit)
                            formData.append('jenis_semester', data.jenis)
                            formData.append('jenis_penilaian', data.jenisPenilaian)
                        }

                        await store.dispatch('nilai/store', formData)
                            .then(() => {
                                store.dispatch("bebanmengajar/getSiswa", { id: data.kelasid, idmapel: data.idmapel, guruid: route.params.id })
                            })
                            .catch((error) => {

                                validation.value = error;

                                Swal.fire({
                                    title: "GAGAL!",
                                    text: validation.value.error,
                                    icon: "error",
                                    showConfirmButton: false,
                                    timer: 2000,
                                });
                            });

                    }
                }

            } else {
                let formData = new FormData();

                if (inputElement && inputElement.hasAttribute('data-id')) {
                    // console.log('data-id', e.target);
                    formData.append('nilai', e.target.value)
                    formData.append('id', e.target.dataset['id'] ?? "")
                    formData.append('order', e.target.attributes[5].nodeValue)
                    formData.append('id_siswa', e.target.attributes[7].nodeValue)
                    formData.append('id_subkategorinilai', e.target.attributes[8].nodeValue)
                    formData.append('id_kategori', e.target.attributes[9].nodeValue)
                    formData.append('bebanmengajar_id', data.kelasid)
                    formData.append('unit', data.idunit)
                    formData.append('id_mapel', data.idmapel)
                    formData.append('jenis_semester', data.jenis)
                    formData.append('jenis_penilaian', data.jenisPenilaian)
                } else {
                    // console.log('data-ids', e.target);
                    formData.append('nilai', e.target.value)
                    formData.append('id', e.target.dataset['id'] ?? "")
                    formData.append('order', e.target.attributes[4].nodeValue)
                    formData.append('id_siswa', e.target.attributes[5].nodeValue)
                    formData.append('id_subkategorinilai', e.target.attributes[6].nodeValue)
                    formData.append('id_kategori', e.target.attributes[7].nodeValue)
                    formData.append('bebanmengajar_id', data.kelasid)
                    formData.append('unit', data.idunit)
                    formData.append('id_mapel', data.idmapel)
                    formData.append('jenis_semester', data.jenis)
                    formData.append('jenis_penilaian', data.jenisPenilaian)
                }

                await store.dispatch('nilai/store', formData)
                    .then(() => {
                        store.dispatch("bebanmengajar/getSiswa", { id: data.kelasid, idmapel: data.idmapel, guruid: route.params.id })
                    })
                    .catch((error) => {

                        validation.value = error;

                        Swal.fire({
                            title: "GAGAL!",
                            text: validation.value.error,
                            icon: "error",
                            showConfirmButton: false,
                            timer: 2000,
                        });
                    });
            }

        }

        const handlePernyataan = (event, n, index) => {
            updateDataPernyataan(event);
            focusNextInput(event, n, index);
        }

        const handleNilai = (event, n, index) => {
            updateData(event);
            focusNextInput(event, n, index);
        }

        watch(jenissemesteraktif, (newVal) => {
            if(newVal == null){
                data.jenis = null;
            }else{
                data.jenis = newVal.jenis_penilaian_aktif;
            }
           
        }, { immediate: true });

        watch(
            () => store.state.guru.details,
            (newguru) => {
                //console.log('newguru',newguru);
                if (newguru) {
                    const {
                        fullname,
                        nickname,
                        gender,
                        birthplace,
                        birthdate,
                        marital_status,
                        wni,
                        religion,
                        edulevel,
                        address,
                        id_province,
                        id_city,
                        id_district,
                        id_subdistrict,
                        cellphone,
                        email,
                        lang,
                        nuptk,
                    } = newguru;
                    guru.fullname = fullname;
                    guru.nickname = nickname;
                    guru.gender = gender;
                    guru.birthplace = birthplace;
                    guru.birthdate = birthdate;
                    guru.marital_status = marital_status;
                    guru.wni = wni;
                    guru.religion = religion;
                    guru.edulevel = edulevel;
                    guru.address = address;
                    guru.id_province = id_province;
                    guru.id_city = id_city;
                    guru.id_district = id_district;
                    guru.id_subdistrict = id_subdistrict;
                    guru.cellphone = cellphone;
                    guru.email = email;
                    guru.lang = lang;
                    guru.nuptk = nuptk;

                }
            }
        );

        return {

            validation,
            guru,
            kelas,
            data,
            nilaipernyataans,
            changeSiswa,
            siswa,
            updateData,
            updateDataPernyataan,
            kategori,
            inputRefs,
            getNilai,
            focusNextInput,
            goBack,
            jenissemesteraktif,
            handlePernyataan,
            handleNilai


        };
    },

    methods: {
        ...mapMutations(["toggleDefaultLayout"]),



    },
};
</script>

<style>
.table-container {
    overflow: auto;
    max-height: 400px;
}

table.table-bordered th {
    position: sticky;
    top: 0;
    background-color: white;
}

.form-nilai {
    max-width: 100px;
    border: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

td {
    color: black;
}
</style>