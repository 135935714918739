import Api from '../../api/Api'
const indikator = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        data:[],
        details:{}
    },

    //mutations
    mutations: {
      
        

        GET_DATA(state, data) {
            state.data = data
        },

        GET_DETAIL(state,  details) {
            state. details =  details
        },
       
      
    },

    _actions: {
      
       
     

        getData({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let idmapel = payload ? payload.id_mapel : ''
            let idunit = payload ? payload.id_unit : ''
            let idta = payload ? payload.id_ta : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/indikator/all/${idmapel}/${idunit}/${idta}`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('GET_DATA', response.data.data)
                })
        },

        store({ commit }, formData) {
           

            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/indikator', formData)

                    .then(response => {

                        //console.log(response.data.data)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        getDetail({ commit }, payload) {

         
            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/indikator/edit/${payload}`)
                .then(response => {
                    // console.log('ini', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('GET_DETAIL', response.data.data)

                })
        },

        update({ commit }, { indikatorId, payload }) {



            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

               
                Api.post(`/indikator/update/${indikatorId}`, payload)

                    .then(response => {

                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        destroy({ commit }, payload) {
            // Define variable token
            const token = localStorage.getItem('token')

            return new Promise((resolve, reject) => {
                Api.defaults.headers.common['Authorization'] = "Bearer " + token
                Api.get(`/indikator/delete/${payload}`)
                    .then(response => {
                        // Commit ke mutation SET_user
                        commit('GET_DATA', response.data.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.error(error.response.data)
                        reject(error.response.data)
                    })
            })
        },



     

        
    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
       
      

        getData(state) {
            return state.data
        },

        getDetail(state) {
            return state.details
        },

    }

}

export default indikator