<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Import NIS</h5>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="saveimport">

                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="">File Excel</label>
                        <input type="file" @change="handleFileChange" class="form-control">
                        <small class="text-danger" v-if="validation.file">{{ validation.file[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">
                        simpan
                      </button>
                      &nbsp;
                      <!-- <router-link
                          :to="{ name: 'admin-aturannilai' }"
                          class="btn btn-danger btn-sm"
                          >kembali</router-link
                        > -->
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from 'sweetalert2';

export default {
  name: "ImportNisIndex",
  components: {},
  data() {
    return {
      showuser: false,
    };
  },

  setup() {
    //user state
    const data = reactive({
      file: '',
      pages: 'importnis-view'
    });

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    function handleFileChange(e) {
      //get image
      // const files = this.data.file = e.target.files[0]
      //console.log('typefile',files)

      this.data.file = e.target.files[0]


      //check fileType
      if (!e.target.files[0].name.match(/\.xls$|\.xlsx$/i)) {
        // File bukan tipe xls atau xlsx

        // Menghapus nilai input file yang tidak sesuai tipe
        e.target.value = '';

        // Menetapkan nilai null ke dalam data file
        this.data.file = null;

        // Menampilkan sweet alert
        Swal.fire({
          title: 'OOPS!',
          text: 'Format File Tidak Didukung!',
          icon: 'error',
          showConfirmButton: false,
          timer: 2000
        });
      }


    }

    //function register, fungsi ini di jalankan ketika form di submit
    function saveimport() {

      let formData = new FormData();

      formData.append("file", this.data.file);

      formData.append("_method", "POST");

      //console.log(formData);
      store.dispatch("importnis/store",
        formData,
      )
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-importnis" });
          Swal.fire({
            icon: 'success',
            title: 'data berhasil diimport',
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          // //console.log(error);

          validation.value = error;
        });
    }

    onMounted(async () => {

      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });





    //return a state and function
    return {
      data, // <-- state user
      validation, // <-- state validation
      saveimport,

      handleFileChange
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },



};
</script>