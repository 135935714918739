import Api from '../../api/Api'
const dashboard = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        totaldatas: [],
        totaldatainternal : [],
        totaldataexternal : []
      
    },

    //mutations
    mutations: {
      
        SET_DATA(state, totaldatas) {
            state.totaldatas = totaldatas
        },

        SET_DATA_INTERNAL(state, totaldatainternal) {
            state.totaldatainternal = totaldatainternal
        },

        SET_DATA_EXTERNAL(state, totaldataexternal) {
            state.totaldataexternal = totaldataexternal
        },

    },

    _actions: {
      
        getData({ commit }) {
            // //console.log('ini parsing',payload);
         
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/dashboard/total`)
                .then(response => {
                    console.log('typay all',response.data.data)
                    commit('SET_DATA', response.data.data)
                })
        },

        getDataInternal({ commit }) {
            // //console.log('ini parsing',payload);
         
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/dashboard/datatotalinternal`)
                .then(response => {
                    console.log('internal',response.data.data)
                    commit('SET_DATA_INTERNAL', response.data.data)
                })
        },

        getDataExternal({ commit }) {
            // //console.log('ini parsing',payload);
         
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/dashboard/datatotalexternal`)
                .then(response => {
                   
                    commit('SET_DATA_EXTERNAL', response.data.data)
                })
        },

     
        
    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
       
        getData(state) {
            return state.totaldatas
        },

        getDataInternal(state) {
            return state.totaldatainternal
        },

        getDataExternal(state) {
            return state.totaldataexternal
        },

      
    }

}

export default dashboard