<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header">

                        <div class="d-flex justify-content-between">
                            <h5 class="mb-0">Data P5</h5>

                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row justify-content-between ">
                            <div class="col-md-1">
                                <div class="d-flex justify-content-between align-items-center">
                                    <span>Show: </span>
                                    <select v-model="data.perPage" class="form-control form-control-sm ml-2"
                                        @change="changePerPage">
                                        <option v-for="option in perPageOptions" :key="option" :value="option">{{ option
                                            }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-4 d-flex mb-3">
                                <input type="text" class="form-control mr-2" v-model="data.search"
                                    @keypress.enter="searchData" placeholder="pencarian" aria-label="pencarian"
                                    aria-describedby="button-addon2">
                                <button @click="searchData" class="btn btn-success mb-0 ms-2" type="button"
                                    id="button-addon2"> <i class="fa fa-search"></i> </button>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table id="datatable-search" class="table table-flush small-table" style="font-size: 12px">
                                <thead class="thead-light">
                                    <tr style="max-height: 5px">
                                        <th>Tahun Ajaran</th>
                                        <th>Kelas</th>
                                        <!-- <th>Level</th> -->
                                        <th>Wali Kelas</th>
                                        <!-- <th>Guru Pendamping</th> -->

                                        <th class="text-right">#</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="m in kelass.data" :key="m.id" style="height: 1px">

                                        <td>{{ m.ta ? m.ta.desc : '' }}</td>
                                        <td>{{ m.master_kelas ? m.master_kelas.classname : '' }}</td>
                                        <!-- <td>{{ m.master_kelas ? m.master_kelas.unit_level.unit.code : ''}}</td> -->
                                        <td>{{ m.wali_kelas ? m.wali_kelas.fullname : '' }} <br> {{ m.guru_pendamping ?
                                            m.guru_pendamping.fullname : '' }} </td>
                                        <!-- <td>{{ m.guru_pendamping ? m.guru_pendamping.fullname : '' }}</td> -->
                                        <td>{{ m.guru_pendamping ? m.guru_pendamping.total_student_count : '' }} </td>


                                        <td>
                                            <!-- Mengecek apakah m.master_kelas tidak null -->


                                            <router-link v-if="m.master_kelas && m.master_kelas.unit_level.unit.id != 1"
                                                :to="{ name: 'admin-masterp5-inputnilai', params: { id: m.idkelas } }"
                                                class="btn btn-success btn-xs m-0 me-1">
                                                <i class="fas fa-pen"></i> &nbsp;P5
                                            </router-link>

                                            <router-link v-if="user.roles[0].name === 'wakil kurikulum' || user.roles[0].name === 'admin'" :to="{ name: 'admin-masterp5', params: { id: m.idkelas } }"
                                                class="btn btn-success btn-xs m-0 me-2">Master P5 </router-link>

                                            <!-- <router-link
                                                :to="{ name: 'admin-siswakelasraportmid', params: { id: m.idkelas } }"
                                                class="btn btn-info btn-xs m-0 me-1"> <i class="fas fa-print"></i>
                                                &nbsp;P5</router-link>

                                                <router-link
                                                :to="{ name: 'admin-siswakelasraportakhir', params: { id: m.idkelas } }"
                                                class="btn btn-info btn-xs m-0 me-1"> <i class="fas fa-print"></i>
                                                &nbsp;P5</router-link> -->
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>


                    </div>

                    <div class="card-footer d-flex justify-content-between">
                        <span>
                            <p>Menampilkan {{ kelass.to }} dari {{ kelass.total }}</p>
                        </span>
                        <nav aria-label="Pagination">
                            <ul class="pagination justify-content-end">
                                <li class="page-item" :class="{ disabled: kelass.current_page === 1 }">
                                    <a class="page-link" @click="changePage(kelass.current_page - 1)" href="#"
                                        aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                    </a>
                                </li>
                                <li v-for="page in kelass.links" :key="page"
                                    :class="{ 'page-item': true, 'active': page.active === true }">
                                    <a class="page-link" @click="changePage(page.label)" href="#"
                                        v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{
                                            page.label }}</a>
                                </li>
                                <li class="page-item" :class="{ disabled: kelass.current_page === kelass.last_page }">
                                    <a class="page-link" @click="changePage(kelass.current_page + 1)" href="#"
                                        aria-label="Next">
                                        <span aria-hidden="true">&raquo;</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import Swal from 'sweetalert2';
import { useRouter } from "vue-router";

export default {
    name: "aturannilaiIndex",

    setup() {
        const store = useStore();
        const data = reactive({
            // currentPage: 1,
            perPage: 10,
            search: '',
            tasId: '',
            pages: 'p5-view'

        });

        const router = useRouter();

        onMounted(async () => {
            await store.dispatch("kelas/getDataP5");
            await store.dispatch("user/getDataPermission");
            await store.dispatch('auth/getUser');
            const permissions = localStorage.getItem('permission');

            if (!permissions.includes(data.pages)) {
                router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
            }
        });

        const kelass = computed(() => {
            return store.getters["kelas/getDataP5"];
        });

        const user = computed(() => {
      //panggil getters dengan nama "currentUser" dari module "auth"
            return store.getters['auth/currentUser']
        })

        // const kelass = computed(() => {
        //     const response = store.getters["kelas/getDataP5"];
        //     // Pastikan ada properti 'data' dan itu adalah array sebelum mencoba memfilternya
        //     const allData = response.data && Array.isArray(response.data) ? response.data : [];
        //     // console.log('all', allData);
        //     // Filter data dimana unit_level.unit.id tidak sama dengan 1
        //     const filteredData = allData.filter(item => item.master_kelas.unit_level.unit.id !== 1);
        //     return filteredData;
        // });
        const perPageOptions = [5, 10, 15, 25, 50, 100];


        const changePage = async (page) => {
            let jumlah = data.perPage;
            const pencarian = data.search;
            await store.dispatch("kelas/getDataP5", {
                page: page,
                perPage: jumlah,
                search: pencarian,
                tasId: ''

            });
        };

        const changePerPage = async () => {
            const pencarian = data.search;
            let jumlah = data.perPage;

            await store.dispatch("kelas/getDataP5", {
                page: 1,
                perPage: jumlah,
                search: pencarian,
                tasId: ''
            });
        };

        const searchData = async () => {
            const pencarian = data.search;
            let jumlah = data.perPage;
            await store.dispatch("kelas/getDataP5", {
                page: 1,
                perPage: jumlah,
                search: pencarian,
                tasId: ''

            });


            // Lakukan tindakan yang diinginkan dengan nilai searchQuery
        };

        function confirmDelete(id) {
            Swal.fire({
                title: 'Konfirmasi',
                text: 'Apakah Anda yakin ingin menghapus data?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Ya, hapus!',
                cancelButtonText: 'Batal',
            }).then((result) => {
                if (result.isConfirmed) {
                    store.dispatch('kelas/destroy', id)
                        .then(() => {

                            store.dispatch("kelas/getDataP5");
                            // 
                            // router.push({ name: "admin-aturannilai" });
                            router.push({ name: "admin-aturannilai" });
                            //alert
                            Swal.fire({
                                title: 'BERHASIL!',
                                text: "Data Berhasil Dihapus!",
                                icon: 'success',
                                showConfirmButton: false,
                                timer: 2000
                            })



                        })
                }
            });
        }


        setNavPills();
        setTooltip(store.state.bootstrap);

        return {
            kelass,
            changePage,
            changePerPage,
            perPageOptions,
            data,
            searchData,
            confirmDelete,
            user

        };
    },
};
</script>