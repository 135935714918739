<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Data Akses Akademik</h5>
              <div class="button">
                
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row justify-content-between">
              <div class="col-md-1">
                <div class="d-flex justify-content-between align-items-center">
                  <span>Show:</span>
                  <select v-model="data.perPage" class="form-control form-control-sm ml-0" @change="changePerPage">
                    <option v-for="option in perPageOptions" :key="option" :value="option">
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 d-flex mb-3">
                <input type="text" class="form-control mr-2" v-model="data.search" @keypress.enter="searchData" placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2" />
                <button @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="row my-3">
              <div class="col-md-3 my-auto mr-5">
                <div class="input-group mb-3">
                  <select class="form-control select2" v-model="data.id_unit" @change="changeUnit">
                    <option value="">-- select Unit --</option>
                    <option v-for="unit in units" :key="unit.id" :value="unit.id">
                      {{ unit.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 my-auto mr-5">
                <div class="input-group mb-3">
                  <select class="form-control select2" v-model="data.id_unitlevel" @change="changeUnitlevel">
                    <option value="">-- select Unitlevel --</option>
                    <option v-for="u in unitlevels" :key="u.id" :value="u.id">
                      {{ u.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-3 my-auto mr-5">
                <div class="input-group mb-3">
                  <select class="form-control select2" v-model="data.kelas_id" @change="changeKelas">
                    <option value="">-- select Kelas --</option>
                    <option v-for="u in kelas" :key="u.id" :value="u.id">
                      {{ u.classname }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                Aktifkan Semua ?
                        <argon-switch 
                        @change="handleSwitchChangeAll">
                        </argon-switch>
              </div>
            </div>
            <div class="table-responsive">
              <table id="datatable-search" class="table table-flush small-table" style="font-size: 12px">
                <thead class="thead-light">
                  <tr style="max-height: 5px">
                    <th>Data Lengkap</th>
                    <th>Unit</th>
                    <th class="text-center">Kelas</th>
                    <th class="text-center">Status Akademik</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="m in datamedicalinfo.data" :key="m.id" style="height: 1px">
                    <td>
                      {{ m.fullname }}
                      <!-- - {{m.idreg}}   - {{m.idstudents}}     -  - {{m.idmedical}}                       -->
                      <!-- <br />Unitlevel : {{ m.nama_unitlevel }} -->
                    </td>
                    <td>{{ m.nama_unit }}</td>
                    <td class="text-center">{{ m.classname }}</td>
                    <td class="text-center">
                      <!-- <argon-switch 
                        :id="`${m.idstudents}`" 
                        :name="`${m.idstudents}`" 
                        :data-id="`${m.idstudents}`" 
                        :checked="m.akses_akademik === null || m.akses_akademik?.akses_akademik === 'Aktif'"
                        @change="handleSwitchChange" class="argonswitch"></argon-switch> -->
                        <label class="switch">
                            <input type="checkbox"
                            :id="`${m.idstudents}`" 
                            :name="`${m.idstudents}`" 
                            :data-id="`${m.idstudents}`" 
                            :checked="m.akses_akademik === null || m.akses_akademik?.akses_akademik === 'Aktif'"
                            @change="handleSwitchChange"
                            >
                            <span class="slider"></span>
                        </label>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card-footer d-flex justify-content-between">
            <span>
              <p>Menampilkan {{ datamedicalinfo.to }} dari {{ datamedicalinfo.total }}</p>
            </span>
            <nav aria-label="Pagination">
              <ul class="pagination justify-content-end">
                <li class="page-item" :class="{ disabled: datamedicalinfo.current_page === 1 }">
                  <a class="page-link" @click="changePage(datamedicalinfo.current_page - 1)" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li v-for="page in datamedicalinfo.links" :key="page" :class="{ 'page-item': true, active: page.active === true }">
                  <a class="page-link" @click="changePage(page.label)" href="#" v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{ page.label }}</a>
                </li>
                <li class="page-item" :class="{ disabled: datamedicalinfo.current_page === datamedicalinfo.last_page }">
                  <a class="page-link" @click="changePage(datamedicalinfo.current_page + 1)" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
        .switch {
            position: relative;
            display: inline-block;
            width: 40px; /* Lebar lebih kecil */
            height: 20px; /* Tinggi lebih kecil */
        }

        /* Switch background */
        .switch input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            transition: 0.4s;
            border-radius: 20px; /* Sesuaikan border-radius agar sesuai dengan tinggi */
        }

        /* Circle inside the switch */
        .slider::before {
            position: absolute;
            content: "";
            height: 16px; /* Sesuaikan ukuran tombol bulat */
            width: 16px; /* Sesuaikan ukuran tombol bulat */
            left: 2px;
            bottom: 2px;
            background-color: white;
            transition: 0.4s;
            border-radius: 50%;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        }

        input:checked + .slider {
            background-color: rgba(94, 114, 228, 0.95); /* Background color when checked */
        }

        input:checked + .slider::before {
            transform: translateX(20px); /* Gerakan tombol bulat ke kanan */
        }

        /* Optional: Add a shadow effect */
        .switch .slider {
            box-shadow: 0 0 1px #ccc;
        }

        /* Add a hover effect */
        .switch input:hover + .slider {
            background-color: #aaa;
        }
</style>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router"; // Correct import from vue-router
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import Swal from 'sweetalert2';
export default {
  name: "AksesAkademik",
  components: {
      ArgonSwitch,
  },
  methods: {
    
  },




  setup() {
    const router = useRouter();
    const validation = ref([]);
    const store = useStore();
    const data = reactive({
      perPage: 30,
      search: "",
      unit: "",
      id_unit: "",
      unitlevel: "",
      id_unitlevel: "",
      kelas_id: "",
      pages: "aksesakademik-view",
    });
    

    onMounted(async () => {
      await store.dispatch("medicalinfo/getData",{
        page: 1,
        perPage: data.perPage,
        search: data.search,
        unit: data.id_unit,
        unitlevel: data.id_unitlevel,
        kelas: data.kelas_id,
      });
      await store.dispatch("units/getAll");
      await store.dispatch("unitlevel/getAll");
      await store.dispatch("kelas/getClassname");
      const permissions = localStorage.getItem("permission");

      if (!permissions.includes(data.pages)) {
        router.push("/error"); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    const datamedicalinfo = computed(() => {
      return store.getters["medicalinfo/getData"];
    });

    const focusNextInput = (e, m, index) => {
      if (e.key === "Enter" || e.key === "Tab") {
        e.preventDefault();
        const targetInput = e.target;
        const inputs = document.querySelectorAll(".form-control");
        const currentIndex = Array.from(inputs).indexOf(targetInput);
        const columns = 3; // Number of columns in your table

        // Calculate the next index based on the current column
        const nextIndex = currentIndex + 1 < inputs.length ? currentIndex + 1 : (index + 1) * columns;

        inputs[nextIndex].focus();
      }
    };

    

    async function handleSwitchChange(e) {
      const switchId = e.currentTarget.getAttribute('data-id'); // Menggunakan currentTarget
      const isChecked = e.target.checked !== undefined ? e.target.checked : e.currentTarget.checked;
      let formData = new FormData();
      formData.append("id", switchId);
      formData.append("isChecked", isChecked);
      formData.append("_method", "POST");
      console.log("Form data : ",formData);
      
      await store
        .dispatch("medicalinfo/updateAkses", {
          medicalId: switchId,
          payload: formData,
        })
        .then(() => {
          //redirect ke dashboard
          // store.dispatch("medicalinfo/getData");
        })
        .catch((error) => {
          validation.value = error;
        });

    }

    async function handleSwitchChangeAll(e) {
      const switchId = e.currentTarget.getAttribute('data-id'); // Menggunakan currentTarget
      const isChecked = e.target.checked !== undefined ? e.target.checked : e.currentTarget.checked;
      let formData = new FormData();
      formData.append("id", switchId);
      formData.append("isChecked", isChecked);
      formData.append("semua", true);
      formData.append("_method", "POST");
      console.log("Form data : ",formData);
      
      await store
        .dispatch("medicalinfo/updateAkses", {
          medicalId: switchId,
          payload: formData,
        })
        .then(() => {
          //redirect ke dashboard
          // store.dispatch("medicalinfo/getData");
          store.dispatch("medicalinfo/getData",{
        page: 1,
        perPage: data.perPage,
        search: data.search,
        unit: data.id_unit,
        unitlevel: data.id_unitlevel,
        kelas: data.kelas_id,
      });
              // router.push({ name: "admin-aksesakadmik" });
              //alert
              Swal.fire({
                title: 'BERHASIL!',
                text: "Data Berhasil Diupdate !",
                icon: 'success',
                showConfirmButton: false,
                timer: 2000
              })
        })
        .catch((error) => {
          validation.value = error;
        });

    }

    

    const units = computed(() => {
      return store.getters["units/getAll"];
    });

    const perPageOptions = [10, 30, 100];

    const changePage = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("medicalinfo/getData", {
        page: page,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        unit: data.id_unit,
        unitlevel: data.id_unitlevel,
        ppdb: data.ppdb,
        status: data.status,
        kelas: data.kelas_id,
      });
    };

    const changePerPage = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("medicalinfo/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        unit: data.id_unit,
        unitlevel: data.id_unitlevel,
        ppdb: data.ppdb,
        status: data.status,
        kelas: data.kelas_id,
      });
    };

    const searchData = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("medicalinfo/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        unit: data.id_unit,
        unitlevel: data.id_unitlevel,
        ppdb: data.ppdb,
        status: data.status,
        kelas: data.kelas_id,
      });
    };

    const user = computed(() => {
      //panggil getters dengan nama "currentUser" dari module "auth"
      return store.getters["auth/currentUser"];
    });

    const changeUnit = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      if (data.id_unit) {
        await store.dispatch("unitlevel/getAll", data.id_unit);
      } else {
        console.error("data.id_unit is undefined or falsy");
      }
      await store.dispatch("medicalinfo/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        unit: data.id_unit,
        unitlevel: data.id_unitlevel,
        kelas: data.kelas_id,
      });
    };

    const changeUnitlevel = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      if (data.id_unit) {
        await store.dispatch("unitlevel/getAll", data.id_unit);
      } else {
        console.error("data.id_unit is undefined or falsy");
      }
      await store.dispatch("medicalinfo/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        unit: data.id_unit,
        unitlevel: data.id_unitlevel,
        kelas: data.kelas_id,
      });

      await store.dispatch("kelas/getClassname", data.id_unitlevel);
    };

    const changeKelas = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      if (data.id_unit) {
        await store.dispatch("unitlevel/getAll", data.id_unit);
      } else {
        console.error("data.id_unit is undefined or falsy");
      }
      await store.dispatch("medicalinfo/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        unit: data.id_unit,
        unitlevel: data.id_unitlevel,
        kelas: data.kelas_id,
      });

      await store.dispatch("kelas/getClassname", data.id_unitlevel);
    };

    const unitlevels = computed(() => {
      return store.getters["unitlevel/getAll"];
    });

    const kelas = computed(() => {
      return store.getters["kelas/getClassname"];
    });

  

      
      
    setNavPills();
    setTooltip(store.state.bootstrap);

    return {
      changePage,
      changePerPage,
      datamedicalinfo,
      perPageOptions,
      data,
      searchData,
      changeUnit,
      unitlevels,
      changeUnitlevel,
      units,
      user,
      focusNextInput,
      kelas,
      changeKelas,
      handleSwitchChange,
      handleSwitchChangeAll
    };
  },
  
};
</script>
