<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">List Status Siswa</h5>
              <router-link :to="{ name: 'admin-kelas',query:{id:$route.query.id}, params: { idta: $route.params.idta } }"
                class="btn btn-danger btn-sm">kembali</router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="row justify-content-between">
              <div class="col-md-1">
                <div class="d-flex justify-content-between align-items-center">
                  <span>Show: </span>
                  <select v-model="data.perPage" class="form-control form-control-sm ml-2" @change="changePerPage">
                    <option v-for="option in perPageOptions" :key="option" :value="option">
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 d-flex mb-3">
                <input type="text" class="form-control mr-2" v-model="data.search" @keypress.enter="searchData"
                  placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2" />
                <button @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="table-responsive">
              <table id="datatable-search" class="table table-flush small-table" style="font-size: 12px">
                <thead class="thead-light">
                  <tr style="max-height: 5px">
                    <th>NISN</th>
                    <th>Fullname</th>
                    <th>Status</th>
                    <th v-if="tasaktif.status_tahun_ajaran == 'genap'">#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="m in siswas.data" :key="m.id" style="height: 1px">
                    <td>{{ m ? m.nis : "" }}</td>
                    <td>{{ m ? m.fullname : "" }} </td>
                    <td>{{ m ? m.desc : "" }} </td>
                    <td v-if="m.id_ta == tasaktif.id && tasaktif.status_tahun_ajaran == 'genap'">
                      <div class="form-group" v-if="m.id_unitlevel == 4 ||
                m.id_unitlevel == 6 ||
                m.id_unitlevel == 14 ||
                m.id_unitlevel == 23 ||
                m.id_unitlevel == 26
                ">
                        <select :name="'selectOption-' + m.id" :id="'selectOption-' + m.id"
                          v-model="data.selectOptions[m.id]" @change="changeStatus(m)">
                          <option value="">Pilih status</option>

                          <option value="lulus">Lulus</option>
                          <option value="tidak_lulus">Tidak Lulus</option>
                        </select>
                      </div>
                      <div v-else>
                        <select :name="'selectOption-' + m.id" :id="'selectOption-' + m.id"
                          v-model="data.selectOptions[m.id]" @change="changeStatus(m)" :disabled="m.status_edit === 1">
                          <option value="">Pilih status</option>

                          <option value="naik_kelas">Naik Kelas</option>
                          <option value="tidak_naik_kelas">
                            Tidak Naik Kelas
                          </option>
                        </select>
                      </div>
                    </td>

                    <td v-else></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card-footer d-flex justify-content-between">
            <span>
              <p>Menampilkan {{ siswas.to }} dari {{ siswas.total }}</p>
            </span>
            <nav aria-label="Pagination">
              <ul class="pagination justify-content-end">
                <li class="page-item" :class="{ disabled: siswas.current_page === 1 }">
                  <a class="page-link" @click="changePage(siswas.current_page - 1)" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li class="page-item" v-for="page in siswas.last_page" :key="page"
                  :class="{ active: page === siswas.current_page }">
                  <a class="page-link" @click="changePage(page)" href="#">{{
                page
              }}</a>
                </li>
                <li class="page-item" :class="{
                disabled: siswas.current_page === siswas.last_page,
              }">
                  <a class="page-link" @click="changePage(siswas.current_page + 1)" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";

export default {
  name: "kelasIndex",

  setup() {
    const store = useStore();
    const route = useRoute();
    const data = reactive({
      // currentPage: 1,
      perPage: 10,
      search: "",
      selectOptions: {},
      idunitlevel: "",
       pages: 'managementkelas-view'

    });

    const router = useRouter();

    onMounted(async () => {
      await store.dispatch("siswa/getSiswaKelas", {
        page: 1,
        perPage: data.perPage,
        search: data.search,
        kelasId: route.params.id,
      });

      await store.dispatch("tahunajaran/getActive");
      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    const siswas = computed(() => {
      return store.getters["siswa/getSiswaKelas"];
    });

    const tasaktif = computed(() => {
      return store.getters["tahunajaran/getActive"];
    });

    const perPageOptions = [5, 10, 15, 25, 50, 100];

    const changePage = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("siswa/getSiswaKelas", {
        page: page,
        perPage: jumlah,
        search: pencarian,
        kelasId: route.params.id,
      });
    };

    const changePerPage = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("siswa/getSiswaKelas", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        kelasId: route.params.id,
      });
    };

    async function changeStatus(e) {
      const id = e.id;
      const selectOption = data.selectOptions[id];
      let formData = new FormData();
      formData.append("id_kelas", route.params.id);
      formData.append("id_reg", id);
      formData.append("desc", selectOption); // Menggunakan row.selectOption

      // //console.log('ini data yang mau dikirim bos', formData);
      await store
        .dispatch("siswa/updateStatus", formData)
        //success
        .then(() => {
          Swal.fire({
            title: "BERHASIL!",
            text: "Data Berhasil Disimpan!",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });

          router.push({
            // path:'/admin'
            name: "admin-kelas-liststatussiswa",
            params: { kelasId: route.params.id },
          });

          store.dispatch("getSiswaKelas", route.params.id);
        });
    }

    async function updateData(e) {
      //  //console.log("ini form number", e.target.attributes[2].nodeValue);
      let formData = new FormData();
      formData.append("order", e.target.value);
      formData.append("id", e.target.attributes[2].nodeValue);

      await store.dispatch("siswa/updateOrder", formData);
      Swal.fire({
        title: "BERHASIL!",
        text: "Data Berhasil Diupdate!",
        icon: "success",
        showConfirmButton: false,
        timer: 2000,
      });
      router.push({
        // path:'/admin'
        name: "admin-kelas-listsiswa",
        params: { kelasId: route.params.id },
      });

      store.dispatch("getSiswaKelas", route.params.id);
    }

    const searchData = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("siswa/getSiswaKelas", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        kelasId: route.params.id,
      });

      // Lakukan tindakan yang diinginkan dengan nilai searchQuery
    };

    setNavPills();
    setTooltip(store.state.bootstrap);

    return {
      siswas,
      changePage,
      changePerPage,
      perPageOptions,
      data,
      searchData,
      updateData,
      changeStatus,
      tasaktif
    };
  },
};
</script>