<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">

            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Data kelas</h5>
              <router-link v-if="datapermissions.includes('managementkelas-create')" :to="`/kelas/tambah?id=${data.ta}`"
                class="btn btn-primary btn-sm">Tambah</router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="row justify-content-between ">
              <div class="col-md-1">
                <div class="d-flex justify-content-between align-items-center">
                  <span>Show: </span>
                  <select v-model="data.perPage" class="form-control form-control-sm ml-2" @change="changePerPage">
                    <option v-for="option in perPageOptions" :key="option" :value="option">{{ option }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 d-flex mb-3">
                <input type="text" class="form-control mr-2" v-model="data.search" @keypress.enter="searchData"
                  placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2">
                <button @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2"> <i
                    class="fa fa-search"></i> </button>
              </div>
            </div>
            <div class="row">
              <div class="col ms-auto my-auto mr-5">
                <div class="form-group">
                  <label for="">Tahun Ajaran</label>
                  <select class="form-control select2" v-model="data.ta" @change="changeTa">
                    <option value="">-- select tahun ajaran --</option>
                    <option v-for="tahunajaran in tas" :key="tahunajaran.id" :value="tahunajaran.id">
                      {{ tahunajaran.desc }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col ms-auto my-auto mr-5">

              </div>

            </div>
            <div class="table-responsive">
              <table id="datatable-search" class="table table-flush small-table" style="font-size: 12px">
                <thead class="thead-light">
                  <tr style="max-height: 5px">
                    <th>Tahun Ajaran</th>
                    <th class="text-center">Unit</th>
                    <th class="text-center">Level</th>
                    <th class="text-center">Nama Kelas</th>
                    <th class="text-center">Wali Kelas</th>
                    <th class="text-center">Guru Pendamping</th>
                    <th class="text-center">Total Siswa</th>
                    <th class="text-center"># </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="m in kelass.data" :key="m.id" style="height: 1px">
                    <td>{{ m.ta ? m.ta.desc : '' }}</td>
                    <td class="text-center">{{ m.master_kelas ? m.master_kelas.unit_level.unit.name : '' }}</td>
                    <td class="text-center">{{ m.master_kelas ? m.master_kelas.unit_level.unit.code : '' }}</td>
                    <td class="text-center">{{ m.master_kelas ? m.master_kelas.classname : '' }}</td>
                    <td class="text-center">{{ m.wali_kelas ? m.wali_kelas.fullname : '' }}</td>
                    <td class="text-center">{{ m.guru_pendamping ? m.guru_pendamping.fullname : '' }}</td>
                    <td class="text-center">{{ m.total_student_count }}</td>
                    <td class="text-center">
                      <router-link :to="{ name: 'admin-kelas-edit', query:{id:data.ta}, params: { id: m.idkelas, idta: data.ta } }"
                        class="btn btn-success btn-xs m-0 me-2"
                        v-if="m.id_ta == tasaktif.id && datapermissions.includes('managementkelas-edit')"> <i
                          class="fas fa-pen"></i></router-link>
                      <router-link :to="{ name: 'admin-kelas-listsiswa',query:{id:data.ta}, params: { id: m.idkelas, idta: data.ta } }"
                        class="btn btn-success btn-xs m-0 me-2"> <i class="fas fa-users"></i></router-link>
                      <router-link
                        :to="{ name: 'admin-kelas-liststatussiswa', query:{id:data.ta}, params: { id: m.idkelas, idta: data.ta } }"
                        class="btn btn-info btn-xs m-0 me-2" v-if="tasaktif.status_tahun_ajaran == 'genap'"> Status
                        Siswa</router-link>
                      <button class="btn btn-danger btn-xs m-0" @click="confirmDelete(m.idkelas)"
                        v-if="m.id_ta == tasaktif.id && datapermissions.includes('managementkelas-delete')"> <i
                          class="fas fa-trash"></i> </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>


          </div>

          <div class="card-footer d-flex justify-content-between">
            <span>
              <p>Menampilkan {{ kelass.to }} dari {{ kelass.total }}</p>
            </span>
            <nav aria-label="Pagination">
              <ul class="pagination justify-content-end">
                <li class="page-item" :class="{ disabled: kelass.current_page === 1 }">
                  <a class="page-link" @click="changePage(kelass.current_page - 1)" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li v-for="page in kelass.links" :key="page"
                  :class="{ 'page-item': true, 'active': page.active === true }">
                  <a class="page-link" @click="changePage(page.label)" href="#"
                    v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{ page.label }}</a>
                </li>
                <li class="page-item" :class="{ disabled: kelass.current_page === kelass.last_page }">
                  <a class="page-link" @click="changePage(kelass.current_page + 1)" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive, watch } from "vue";
import { useStore } from "vuex";
import Swal from 'sweetalert2';
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";


export default {
  name: "kelasIndex",


  setup() {
    const store = useStore();
    const route = useRoute();

    const data = reactive({
      // currentPage: 1,
      perPage: 10,
      search: '',
      ta:  route.query.id ?? '',
      pages: 'managementkelas-view'

    });

    const router = useRouter();

    onMounted(async () => {
      await store.dispatch("kelas/getData",{tasId: data.ta, page: 1,
        perPage: data.perPage,
        search: data.search});
      await store.dispatch("tahunajaran/getAll");
      await store.dispatch("tahunajaran/getActive");
      await store.dispatch("user/getDataPermission");
      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    const datapermissions = computed(() => {
      return store.getters["user/getDataPermission"];
    });



    const kelass = computed(() => {
      return store.getters["kelas/getData"];
    });

    const tas = computed(() => {
      return store.getters["tahunajaran/getAll"];
    });

    const tasaktif = computed(() => {
      return store.getters["tahunajaran/getActive"];
    });





    watch(
      () => store.state.tahunajaran.active,
      (newactive) => {
        if (newactive) {
          const {
            id,


          } = newactive;
          data.ta = route.query.id ?? id;


        }
      }
    );

    const perPageOptions = [5, 10, 15, 25, 50, 100];


    const changePage = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("kelas/getData", {
        page: page,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,

      });
    };

    const changeTa = async () => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("kelas/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,


      });
    };

    const changePerPage = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("kelas/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
      });
    };

    const searchData = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("kelas/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
      });


      // Lakukan tindakan yang diinginkan dengan nilai searchQuery
    };

    function confirmDelete(id) {
      Swal.fire({
        title: 'Konfirmasi',
        text: 'Apakah Anda yakin ingin menghapus data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Ya, hapus!',
        cancelButtonText: 'Batal',
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch('kelas/destroy', id)
            .then(() => {

              store.dispatch("kelas/getData");

              router.push({ name: "admin-kelas" });
              //alert
              Swal.fire({
                title: 'BERHASIL!',
                text: "Data Berhasil Dihapus!",
                icon: 'success',
                showConfirmButton: false,
                timer: 2000
              })



            })
        }
      });
    }


    setNavPills();
    setTooltip(store.state.bootstrap);

    return {
      kelass,
      changePage,
      changePerPage,
      perPageOptions,
      data,
      searchData,
      confirmDelete,
      tas,
      changeTa,
      tasaktif,
      datapermissions


    };
  },
};
</script>
