<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">

            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Data Master Ekskul Aktif</h5>
              <div class="button">
                <router-link v-if="datapermissions.includes('ekskulaktif-create')" to="/ekskulaktif/tambah"
                  class="btn btn-primary btn-sm">Tambah</router-link>&nbsp;
                <button @click="generateEkskul" class="btn btn-success" type="button" id="button-addon2" v-if="CekUser === 'admin'"> Generate 
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row justify-content-between ">
              <div class="col-md-1">
                <div class="d-flex justify-content-between align-items-center">
                  <span>Show: </span>
                  <select v-model="data.perPage" class="form-control form-control-sm ml-2" @change="changePerPage">
                    <option v-for="option in perPageOptions" :key="option" :value="option">{{ option }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 d-flex mb-3">
                <input type="text" class="form-control mr-2" v-model="data.search" @keypress.enter="searchData"
                  placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2">
                <button @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2"> <i
                    class="fa fa-search"></i> </button>
              </div>
            </div>
            <div class="table-responsive">
              <table id="datatable-search" class="table table-flush small-table" style="font-size: 12px">
                <thead class="thead-light">
                  <tr style="max-height: 5px">
                    <th>Tahun Ajaran</th>
                    <th>Unit</th>
                    <th>Nama Ekskul</th>
                    <th colspan="2">
                      <center>Kelas</center>
                    </th>
                    <th>Nama Koordinator</th>
                    <th>Nama Pelatih</th>
                    <th>
                      <center>Jumlah Peserta</center>
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="m in menus.data" :key="m.id" style="height: 1px">
                    <td>{{ m.tahun_ajaran }}</td>
                    <td>{{ m.unit }}</td>
                    <td>{{ m.nama_ekskul }}</td>
                    <td>
                      <!-- <b v-for="level in m.level" :key="level.id">{{ level.unit.name }}<br></b> -->
                      <!-- Wrapper untuk v-if dan v-for -->
                      <span v-if="m.classname === null">
                        <b v-for="level in m.level" :key="level.id">{{ level.unit.name }}<br></b>
                      </span>

                      <!-- Wrapper untuk v-else -->
                      <span v-else>
                        <b>{{ m.classname }}</b>
                      </span>
                    </td>
                    <td></td>
                    <td>{{ m.guru[0] ? m.guru[0].fullname:'' }}</td>
                    <td>{{ m.nama_pelatih }}</td>
                    <td align="center">{{ m.peserta.length }}</td>
                    <td>
                      <router-link v-if="datapermissions.includes('ekskulaktif-edit')"
                        :to="{ name: 'admin-ekskulaktif-peserta', params: { id: m.id } }"
                        class="btn btn-info btn-xs m-0 me-2"> <i class="fas fa-users"></i></router-link>
                      <span v-if="m.sifat == 'pilihan'">
                        <router-link v-if="datapermissions.includes('ekskulaktif-create')"
                          :to="{ name: 'admin-ekskulaktif-edit', params: { id: m.id } }"
                          class="btn btn-success btn-xs m-0 me-2"> <i class="fas fa-pen"></i></router-link>
                      </span>
                      <router-link v-if="datapermissions.includes('ekskulaktif-edit')"
                        :to="{ name: 'admin-ekskulaktif-penilaian', params: { id: m.id, idunit: m.id_unit } }"
                        class="btn btn-warning btn-xs m-0 me-2"> <i
                          class="fas fa-evaluation">Penilaian</i></router-link>
                      <button v-if="datapermissions.includes('ekskulaktif-delete')" class="btn btn-danger btn-xs m-0"
                        @click="confirmDelete(m.id)"> <i class="fas fa-trash"></i> </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>


          </div>

          <div class="card-footer d-flex justify-content-between">
            <span>
              <p>Menampilkan {{ menus.to }} dari {{ menus.total }}</p>
            </span>
            <nav aria-label="Pagination">
              <ul class="pagination justify-content-end">
                <li class="page-item" :class="{ disabled: menus.current_page === 1 }">
                  <a class="page-link" @click="changePage(menus.current_page - 1)" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li v-for="page in menus.links" :key="page"
                  :class="{ 'page-item': true, 'active': page.active === true }">
                  <a class="page-link" @click="changePage(page.label)" href="#"
                    v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{ page.label }}</a>
                </li>
                <li class="page-item" :class="{ disabled: menus.current_page === menus.last_page }">
                  <a class="page-link" @click="changePage(menus.current_page + 1)" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import Swal from 'sweetalert2';
import { useRouter } from "vue-router";

export default {
  name: "EkskulAktifIndex",


  setup() {
    const store = useStore();
    const data = reactive({
      // currentPage: 1,
      perPage: 10,
      search: '',
      loading: '',
      pages: 'ekskulaktif-view'
    });

    const router = useRouter();

    const getUser = computed(() => {
      return store.getters["auth/getUser"];
    });

    const CekUser = computed(() => {
      return store.getters["ekskulaktif/CekUser"];
    });

    onMounted(async () => {
      await store.dispatch("ekskulaktif/CekUser");
      await store.dispatch("ekskulaktif/getData");
      await store.dispatch("user/getDataPermission");
      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    const menus = computed(() => {
      return store.getters["ekskulaktif/getData"];
    });

    const datapermissions = computed(() => {
      return store.getters["user/getDataPermission"];
    });

    const perPageOptions = [5, 10, 15, 25, 50, 100];


    const changePage = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("ekskulaktif/getData", {
        page: page,
        perPage: jumlah,
        search: pencarian

      });
    };

    const changePerPage = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("ekskulaktif/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian
      });
    };

    const searchData = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("ekskulaktif/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian
      });


      // Lakukan tindakan yang diinginkan dengan nilai searchQuery
    };

    function confirmDelete(id) {
      Swal.fire({
        title: 'Konfirmasi',
        text: 'Apakah Anda yakin ingin menghapus data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Ya, hapus!',
        cancelButtonText: 'Batal',
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch('ekskulaktif/destroy', id)
            .then(() => {
              store.dispatch("ekskulaktif/getData");
              router.push({ name: "admin-ekskulaktif" });
              //alert
              Swal.fire({
                title: 'BERHASIL!',
                text: "Data Berhasil Dihapus!",
                icon: 'success',
                showConfirmButton: false,
                timer: 2000
              })
            })
        }
      });
    }
    const validation = {};
    function generateEkskul(id) {
      console.log(id);
      Swal.fire({
        title: 'Konfirmasi',
        text: 'Apakah Anda yakin ingin generate data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Ya, Generate !',
        cancelButtonText: 'Batal',
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch('ekskulaktif/generate', id)
            .then(() => {
              store.dispatch("ekskulaktif/getData");
              router.push({ name: "admin-ekskulaktif" });
              //alert
              Swal.fire({
                title: 'BERHASIL!',
                text: "Data Berhasil Digenerate!",
                icon: 'success',
                showConfirmButton: false,
                timer: 2000
              })
            })
            .catch((error) => {

              validation.value = error;
              // console.log('data',validation.value.error);

              // Show an error alert using Swal
              Swal.fire({
                title: "GAGAL!",
                text: validation.value.error,
                icon: "error",
                showConfirmButton: true,
                timer: 10000,
              });
            });
        }
      });
    }


    setNavPills();
    setTooltip(store.state.bootstrap);

    return {
      menus,
      changePage,
      changePerPage,
      perPageOptions,
      data,
      searchData,
      confirmDelete,
      datapermissions,
      generateEkskul,
      getUser,
      CekUser,
    };
  },
};
</script>
