import Api from '../../api/Api'
const permission = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        permissionall: [],
        permissions: [],
        detailpermission:{}
    },

    //mutations
    mutations: {
        GET_PERMISSION(state, permissions) {
            state.permissions = permissions // <-- assign state orders dari hasil response
        },
        GET_PERMISSION_ALL(state, permissionall) {
            state.permissionall = permissionall // <-- assign state orders dari hasil response
        },
        GET_DETAIL_PERMISSION(state,  detailpermission) {
            state. detailpermission =  detailpermission
        },
        SET_PERMISSION(state, permissions) {
            state.permissions = permissions;
        },
    },

    //actions
    actions: {
        getAllPermission({ commit }) {

            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get('/permission/getgroupby')
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('GET_PERMISSION', response.data.data)

                })

        },

        getAll({ commit }) {

            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get('/permission/getall')
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('GET_PERMISSION', response.data.data)

                })

        },


        getData({ commit }, payload) {
            let page = payload ? payload.page : '';
            let perpage = payload ? payload.perPage : '';
            let pencarian = payload ? payload.search : '';
            const token = localStorage.getItem('token');
            Api.defaults.headers.common['Authorization'] = "Bearer " + token;
            Api.get(`/permission?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}` )
              .then(response => {
                // //console.log('ini parent',response.data.data)
                commit('GET_PERMISSION', response.data.data)
              });
          },

      
        store({ commit }, permission) {

            // //console.log('ini menu', menu);

            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/permission',permission)

                    .then(response => {

                        //console.log(response.data.data)
                        commit('')
                        resolve(response)

                    }).catch(error => {

                      
                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        update({ commit }, { permissionId, payload }) {
            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post(`/permission/update/${permissionId}`, payload)

                    .then(response => {

                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        getDetailPermission({ commit }, payload) {

            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/permission/edit/${payload}`)
                .then(response => {
                    //console.log('ini', response.data.data.permission)
                    //commit ke mutation GET_ORDER
                    commit('GET_DETAIL_PERMISSION', response.data.data.permission)

                })

        },

        destroy({ commit }, payload) {
            // Define variable token
            const token = localStorage.getItem('token')

            return new Promise((resolve, reject) => {
                Api.defaults.headers.common['Authorization'] = "Bearer " + token
                Api.delete(`/permission/delete/${payload}`)
                    .then(response => {
                        // Commit ke mutation SET_MENU
                        commit('SET_PERMISSION', response.data.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.error(error.response.data)
                        reject(error.response.data)
                    })
            })
        },


    },

    //getters
    getters: {
        getAllPermission(state) {
            return state.permissions
        },

        getData(state) {
            return state.permissions
        },

        getDetailPermission(state){
            return state.detailpermision
        },

        getAll(state){
            return state.permissions
        }
    }

}

export default permission