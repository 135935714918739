<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Tambah kelas</h5>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="savekelas">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Nama Kelas <span class="text-danger">*</span></label>
                        <input type="text" placeholder="Nama Kelas" class="form-control" v-model="kelas.classname" />
                        <small class="text-danger" v-if="validation.classname">{{ validation.classname[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Unit Level <span class="text-danger">*</span></label>
                        <select class="form-control" v-model="kelas.id_unitlevel" @change="changeUnitlevel">
                          <option value="">-- select unitlevel --</option>
                          <option v-for="unitlevel in unitlevels" :key="unitlevel.id" :value="unitlevel.id">{{
                  unitlevel.name }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.id_unitlevel">{{ validation.id_unitlevel[0]
                          }}</small>
                      </div>
                    </div>
                  </div>


                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tahun Ajaran <span class="text-danger">*</span></label>
                        <select class="form-control" v-model="kelas.id_ta">
                          <option value="">-- select tahun ajaran --</option>
                          <option v-for="tahunajaran in tahunajarans" :key="tahunajaran.id" :value="tahunajaran.id">{{
                  tahunajaran.desc }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.id_ta">{{ validation.id_ta[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Wali Kelas</label>
                        <select class="form-control" v-model="kelas.wali_kelas">
                          <option value="">-- select manu --</option>
                          <option v-for="guru in gurus" :key="guru.id" :value="guru.id">{{ guru.fullname }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.wali_kelas">{{ validation.wali_kelas[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">
                          Guru Pendamping </label>
                        <select class="form-control" v-model="kelas.guru_pendamping">
                          <option value="">-- select manu --</option>
                          <option v-for="guru in gurus" :key="guru.id" :value="guru.id">{{ guru.fullname }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.guru_pendamping">{{ validation.guru_pendamping[0]
                          }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">
                        simpan
                      </button>
                      &nbsp;

                      <router-link :to="{ name: 'admin-kelas', query: { id: $route.query.id } }" class="btn btn-danger btn-sm">
  Kembali 
</router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Swal from 'sweetalert2';

export default {
  name: "kelasIndex",
  components: {},
  data() {
    return {
      showkelas: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

  
  setup() {
    //user state
    const route = useRoute();
    
    const kelas = reactive({
      classname: '',
      id_ta: '',
      wali_kelas: '',
      guru_pendamping: '',
      id_unitlevel: '',
    });

    const data = reactive({

      pages: 'managementkelas-create'

    });


    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    //function register, fungsi ini di jalankan ketika form di submit
    function savekelas() {

      //define variable


      let formData = new FormData();

      formData.append('classname', this.kelas.classname)
      formData.append('id_ta', this.kelas.id_ta)
      formData.append('id_unitlevel', this.kelas.id_unitlevel)
      formData.append('wali_kelas', this.kelas.wali_kelas)
      formData.append('guru_pendamping', this.kelas.guru_pendamping)
      formData.append("_method", "POST");
      //panggil actions "register" dari module "auth"
      store
        .dispatch("kelas/store",
          formData,
        )
        .then((response) => {
          console.log(response)
          //redirect ke dashboard
          if(response.data.success == true){
            router.push({ name: "admin-kelas",  query: { id: route.query.id }  });
          Swal.fire({
            icon: 'success',
            title: 'Tambah data berhasil',
          });
          }else{
            router.push({ name: "admin-kelas", query: { id: route.query.id } });
          Swal.fire({
            icon: 'false',
            title: response.data.message,
          });
          }
          

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          // //console.log(error);

          validation.value = error;
        });
    }


    onMounted(async () => {
      await store.dispatch('tahunajaran/getAll');
      await store.dispatch('unitlevel/getAlls');
      await store.dispatch('guru/getAll');
      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    //computed
    const tahunajarans = computed(() => {
      //panggil getter dengan nama "getParent" di module "kelas" vuex
      return store.getters["tahunajaran/getAll"];
    });

    const unitlevels = computed(() => {
      //panggil getter dengan nama "getAllPermission" di module "permission" vuex
      return store.getters["unitlevel/getAlls"];
    });

    const gurus = computed(() => {
      //panggil getter dengan nama "getAllPermission" di module "permission" vuex
      return store.getters["guru/getAll"];
    });

    //return a state and function
    return {
      kelas, // <-- state user
      validation, // <-- state validation
      savekelas,
      tahunajarans,
      unitlevels,
      gurus,
      data
    };
  },
};
</script>