<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">

            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Daftar Indikator Penilaian Sikap</h5>
              <router-link v-if="datapermissions.includes('subkategorisikap-create')" to="/subkategorisikap/tambah"
                class="btn btn-primary btn-sm">Tambah</router-link>
            </div>

            <form @submit.prevent="savesubkategorisikap">
              <div class="row">
                <div class="col-md-5">
                  <div class="form-group">
                    <label for="exampleFormControlInput1">Tahun Ajaran <span class="text-danger">*</span></label>
                    <select class="form-control" v-model="data.id_ta">
                      <option value="">-- select tahun ajaran --</option>
                      <option v-for="tahunajaran in tahunajarans" :key="tahunajaran.id" :value="tahunajaran.id">{{
                        tahunajaran.desc }} {{ tahunajaran.active == 1 ? '(active)' : '' }}</option>
                    </select>
                    <small class="text-danger" v-if="validation.id_ta">{{ validation.id_ta[0] }}</small>

                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <div class="form-group">
                    <label for="exampleFormControlInput1">Group <span class="text-danger">*</span></label>
                    <select class="form-control" v-model="data.group" @change="changeGroup">
                      <option value="">-- pilih Group -- </option>
                      <option v-for="k in groupsubkategorisikaps" :key="k.id" :value="k.id">

                        {{ k.nama_group }}
                      </option>
                    </select>
                    <small>Jika tidak ada group yang sesuai maka klik <span @click="openModalGroup"
                        class="text-primary cursor-pointer"
                        style="text-decoration: none; cursor: pointer;"><b>tambahkan</b></span></small>


                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <div class="form-group">
                    <label for="exampleFormControlInput1">Aspeks <span class="text-danger">*</span></label>
                    <select class="form-control" v-model="data.aspek">
                      <option value="">-- pilih aspek --</option>
                      <option v-for="k in kategoris" :key="k.id" :value="k.id" :type="k.type_kategori">{{ k.level }}
                        -
                        {{ k.nama_kategori }} </option>
                    </select>
                    <small class="text-danger" v-if="validation.kategorisikap_id">{{ validation.kategorisikap_id[0]
                      }}</small>

                    <small>Jika tidak ada aspek yang sesuai maka klik <span @click="openModalAspek"
                        class="text-primary cursor-pointer"
                        style="text-decoration: none; cursor: pointer;"><b>tambahkan</b></span></small>

                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <div class="form-group">
                    <label for="exampleFormControlInput1">Nama Indikator</label>
                    <input type="text" placeholder="nama indikator" class="form-control"
                      v-model="data.nama_subkategorisikap" />

                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-5">
                  <button class="btn btn-success btn-block">Tambah</button>
                </div>
              </div>
            </form>
          </div>

          <div class="card-body">
            <div class="row justify-content-between ">
              <div class="col-md-1">
                <div class="d-flex justify-content-between align-items-center">
                  <span>Show: </span>
                  <select v-model="data.perPage" class="form-control form-control-sm ml-2" @change="changePerPage">
                    <option v-for="option in perPageOptions" :key="option" :value="option">{{ option }}</option>
                  </select>
                </div>
              </div>
              <div class="col ms-auto my-auto mr-5">

                <div class="input-group mb-3">
                  <select class="form-control select2" v-model="data.ta" @change="changeTa">
                    <option value="">-- select tahun ajaran --</option>
                    <option v-for="tahunajaran in tas" :key="tahunajaran.id" :value="tahunajaran.id">
                      {{ tahunajaran.desc }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col ms-auto my-auto mr-5">
                <div class="input-group mb-3">
                  <select class="form-control select2" v-model="data.unit" @change="changeUnit">
                    <option value="">-- select Unit --</option>
                    <option v-for="unit in units" :key="unit.id" :value="unit.id">
                      {{ unit.name }}

                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 d-flex mb-3">
                <input type="text" class="form-control mr-2" v-model="data.search" @keypress.enter="searchData"
                  placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2">
                <button @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2"> <i
                    class="fa fa-search"></i> </button>
              </div>
            </div>
            <div class="table-responsive">
              <table id="datatable-search" class="table table-flush small-table" style="font-size: 12px">
                <thead class="thead-light">
                  <tr style="max-height: 5px">
                    <th>Tahun Ajaran</th>
                    <th class="text-center">Unit</th>
                    <th class="text-center">Nama Group</th>
                    <th class="text-center">Aspek Sikap</th>
                    <th class="text-center">Indikator</th>
                    <th class="text-center">Jumlah Kolom</th>
                    <!-- <th class="text-center">Parent</th> -->
                    <th class="text-center">#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="m in subkategorisikaps.data" :key="m.id" style="height: 1px">
                    <td>{{ m.desc }}</td>
                    <td class="text-center">{{ m.code }}</td>
                    <td class="text-left">
                      <i class="fa fa-pen text-primary cursor-pointer" style="text-decoration: none; cursor: pointer;"
                        @click="editGroup(m)"></i>&nbsp;
                      &nbsp;
                      {{ m.nama_group }}
                    </td>
                    <td class="text-left">
                      <i class="fa fa-pen text-primary cursor-pointer" style="text-decoration: none; cursor: pointer;"
                        @click="editAspek(m)"></i>&nbsp;
                      &nbsp;
                      {{ m.nama_kategori }}
                    </td>
                    <td class="text-left">{{ m.nama_subkategori }}</td>
                    <td class="text-center">{{ m.generate ?? 1 }}</td>
                    <!-- <td class="text-center">{{ m.parent ? m.parent.nama_subkategori : ''}}</td> -->
                    <td class="text-center">
                      <router-link v-if="datapermissions.includes('subkategorisikap-edit')"
                        :to="{ name: 'admin-subkategorisikap-edit', params: { id: m.id } }"
                        class="btn btn-success btn-xs m-0 me-2"> <i class="fas fa-pen"></i></router-link>
                      <button class="btn btn-danger btn-xs m-0" @click="confirmDelete(m.id)"> <i
                          class="fas fa-trash"></i> </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>


          </div>

          <div class="card-footer d-flex justify-content-between">
            <span>
              <p>Menampilkan {{ subkategorisikaps.to }} dari {{ subkategorisikaps.total }}</p>
            </span>
            <nav aria-label="Pagination">
              <ul class="pagination justify-content-end">
                <li class="page-item" :class="{ disabled: subkategorisikaps.current_page === 1 }">
                  <a class="page-link" @click="changePage(subkategorisikaps.current_page - 1)" href="#"
                    aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li v-for="page in subkategorisikaps.links" :key="page"
                  :class="{ 'page-item': true, 'active': page.active === true }">
                  <a class="page-link" @click="changePage(page.label)" href="#"
                    v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{ page.label }}</a>
                </li>
                <li class="page-item"
                  :class="{ disabled: subkategorisikaps.current_page === subkategorisikaps.last_page }">
                  <a class="page-link" @click="changePage(subkategorisikaps.current_page + 1)" href="#"
                    aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <!-- show modal -->

    <div class="modal" v-if="showModalGroup">
      <div class="col-lg-12">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ editModalGroup == true ? 'Edit Group' : 'Tambah Group' }}</h5>
              <button @click="closeModalGroup" type="button" class="btn-close" data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <form v-if="editModalGroup != true" @submit.prevent="savegroupkategorisikap">

              <div class="modal-body">

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">
                        Unit </label>
                      <select class="form-control" v-model="groupkategorisikap.id_unit">
                        <option value="">-- select unit --</option>
                        <option v-for="u in units" :key="u.id" :value="u.id">{{ u.code }}</option>
                      </select>
                      <small class="text-danger" v-if="validation.unit">{{ validation.unit[0] }}</small>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Jenis Sikap</label>
                      <input type="text" placeholder="name" class="form-control"
                        v-model="groupkategorisikap.nama_group" />
                      <small class="text-danger" v-if="validation.nama_kategorisikap">{{
                        validation.nama_kategorisikap[0] }}</small>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="exampleFormControlSelect2">Keterangan Nilai</label>
                      <!-- <select multiple class="form-control" id="exampleFormControlSelect2"
                        v-model="groupkategorisikap.keterangannilai_id">
                        <option v-for="u in keterangannilai" :key="u.id" :value="u.id">{{ u.kode }}</option>
                      </select> -->
                      <div style="height: 100px; overflow-y: auto;">
                        <div v-for="u in keterangannilai" :key="u.id">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox"
                              v-model="groupkategorisikap.keterangannilai_id" :value="u.id">
                            <label class="custom-control-label">{{ u.kode }}</label>
                          </div>
                        </div>
                      </div>
                      <small class="text-danger" v-if="validationketerangannilai_id">{{
                        validation.keterangannilai_id[0]
                      }}</small>
                    </div>
                  </div>
                </div>

              </div>
              <div class="modal-footer">
                <button @click="closeModalGroup" type="button" class="btn btn-secondary"
                  data-bs-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary">Save changes</button>
              </div>
            </form>
            <form v-else @submit.prevent="updategroupkategorisikap">

              <div class="modal-body">

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">
                        Unit </label>
                      <select class="form-control" v-model="groupkategorisikap.id_unit">
                        <option value="">-- select unit --</option>
                        <option v-for="u in units" :key="u.id" :value="u.id">{{ u.code }}</option>
                      </select>
                      <small class="text-danger" v-if="validation.unit">{{ validation.unit[0] }}</small>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Jenis Sikap</label>
                      <input type="text" placeholder="name" class="form-control"
                        v-model="groupkategorisikap.nama_group" />
                      <small class="text-danger" v-if="validation.nama_kategorisikap">{{
                        validation.nama_kategorisikap[0] }}</small>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="exampleFormControlSelect2">Keterangan Nilai</label>
                      <!-- <select multiple class="form-control" id="exampleFormControlSelect2"
          v-model="groupkategorisikap.keterangannilai_id">
          <option v-for="u in keterangannilai" :key="u.id" :value="u.id">{{ u.kode }}</option>
        </select> -->
                      <div style="height: 100px; overflow-y: auto;">
                        <div v-for="u in keterangannilai" :key="u.id">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox"
                              v-model="groupkategorisikap.keterangannilai_id" :value="u.id">
                            <label class="custom-control-label">{{ u.kode }}</label>
                          </div>
                        </div>
                      </div>
                      <small class="text-danger" v-if="validationketerangannilai_id">{{
                        validation.keterangannilai_id[0]
                      }}</small>
                    </div>
                  </div>
                </div>

              </div>
              <div class="modal-footer">
                <button @click="closeModalGroup" type="button" class="btn btn-secondary"
                  data-bs-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary">Update changes</button>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>

    <div class="modal" v-if="showModalAspek">
      <div class="col-lg-12">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ editModalAspek != true ? 'Tambah aspek' : 'Edit aspek' }}</h5>
              <button @click="closeModalAspek" type="button" class="btn-close" data-bs-dismiss="modal"
                aria-label="Close"></button>
            </div>
            <form v-if="editModalAspek != true" @submit.prevent="savekategorisikap">
              <div class="modal-body">

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">
                        Unit </label>
                      <select class="form-control" @change="getUnit" v-model="kategorisikap.unit">
                        <option value="">-- select unit --</option>
                        <option v-for="u in units" :key="u.id" :value="u.id">{{ u.code }}</option>
                      </select>
                      <small class="text-danger" v-if="validation.unit">{{ validation.unit[0] }}</small>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Aspek Sikap</label>
                      <input type="text" placeholder="name" class="form-control"
                        v-model="kategorisikap.nama_kategorisikap" />
                      <small class="text-danger" v-if="validation.nama_kategorisikap">{{
                        validation.nama_kategorisikap[0] }}</small>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="Type Kategori">Jenis Penilaian</label>
                      <select v-model="kategorisikap.type" class="form-control" @change="getGroup">
                        <option value="">--Pilih--</option>
                        <option value="mapel">Penilaian Per Mata Pelajaran</option>
                        <option value="non_mapel">Penilaian Non Mata Pelajaran</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="kategorisikap.type === 'non_mapel'">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">
                        Kategori Group </label>
                      <select class="form-control" v-model="kategorisikap.group_id">
                        <option value="">-- Pilih Kategori Group--</option>
                        <option v-for="u in group" :key="u.id" :value="u.id">{{ u.nama_group }}</option>
                      </select>
                      <small class="text-danger" v-if="validation.group_id">{{ validation.group_id[0] }}</small>
                    </div>
                  </div>
                </div>



                <div class="row" v-if="kategorisikap.type === 'mapel'">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="exampleFormControlSelect2">Keterangan Nilai</label>
                      <select multiple class="form-control" id="exampleFormControlSelect2"
                        v-model="kategorisikap.keterangannilai_id">
                        <option v-for="u in keterangannilai" :key="u.id" :value="u.id">{{ u.kode }}</option>
                      </select>
                      <small class="text-danger" v-if="validationketerangannilai_id">{{
                        validation.keterangannilai_id[0]
                      }}</small>
                    </div>
                  </div>
                </div>


              </div>
              <div class="modal-footer">
                <button @click="closeModalAspek" type="button" class="btn btn-secondary"
                  data-bs-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary">Save changes</button>
              </div>
            </form>
            <form v-else @submit.prevent="updatekategorisikap">
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">
                        Unit </label>
                      <select class="form-control" @change="getUnit" v-model="kategorisikap.unit">
                        <option value="">-- select unit --</option>
                        <option v-for="u in units" :key="u.id" :value="u.id">{{ u.code }}</option>
                      </select>
                      <small class="text-danger" v-if="validation.unit">{{ validation.unit[0] }}</small>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">Aspek Sikap</label>
                      <input type="text" placeholder="name" class="form-control"
                        v-model="kategorisikap.nama_kategorisikap" />
                      <small class="text-danger" v-if="validation.nama_kategorisikap">{{
                        validation.nama_kategorisikap[0] }}</small>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="Type Kategori">Jenis Penilaian</label>
                      <select v-model="kategorisikap.type" class="form-control" @change="getGroup">
                        <option value="">--Pilih--</option>
                        <option value="mapel">Penilaian Per Mata Pelajaran</option>
                        <option value="non_mapel">Penilaian Non Mata Pelajaran</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row" v-if="kategorisikap.type === 'non_mapel'">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="exampleFormControlInput1">
                        Kategori Group </label>
                      <select class="form-control" v-model="kategorisikap.group_id">
                        <option value="">-- Pilih Kategori Group--</option>
                        <option v-for="u in group" :key="u.id" :value="u.id">{{ u.nama_group }}</option>
                      </select>
                      <small class="text-danger" v-if="validation.group_id">{{ validation.group_id[0] }}</small>
                    </div>
                  </div>
                </div>



                <div class="row" v-if="kategorisikap.type === 'mapel'">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="exampleFormControlSelect2">Keterangan Nilai</label>
                      <select multiple class="form-control" id="exampleFormControlSelect2"
                        v-model="kategorisikap.keterangannilai_id">
                        <option v-for="u in keterangannilai" :key="u.id" :value="u.id">{{ u.kode }}</option>
                      </select>
                      <small class="text-danger" v-if="validationketerangannilai_id">{{
                        validation.keterangannilai_id[0]
                      }}</small>
                    </div>
                  </div>
                </div>


              </div>
              <div class="modal-footer">
                <button @click="closeModalAspek" type="button" class="btn btn-secondary"
                  data-bs-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-primary">Update changes</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- show modal -->
  </div>


</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import Swal from 'sweetalert2';
import { useRouter, useRoute } from "vue-router";


export default {
  name: "subkategorisikapIndex",
  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },


  setup() {
    const store = useStore();
    const data = reactive({
      // currentPage: 1,
      perPage: 10,
      search: '',
      ta: "",
      unit: "",
      group: "",
      aspek: "",
      nama_subkategorisikap: '',
      pages: "subkategorisikap-view"
    });

    const groupkategorisikap = reactive({
      id: '',
      nama_group: '',
      id_unit: '',
      id_ta: '',
      keterangannilai_id: [],
    });

    const kategorisikap = reactive({
      id: '',
      nama_kategorisikap: '',
      unit: '',
      id_ta: '',
      group_id: '',
      parent_id: '',
      type: '',
      keterangannilai_id: [],
    });


    const router = useRouter();
    const route = useRoute();

    const validation = ref([]);
    const showModalGroup = ref(false);
    const editModalGroup = ref(false);
    const showModalAspek = ref(false);
    const editModalAspek = ref(false);

    const openModalAspek = () => {
      showModalAspek.value = true;
    };

    const closeModalAspek = () => {
      showModalAspek.value = false;
    };


    const openModalGroup = () => {
      showModalGroup.value = true;
    };

    const closeModalGroup = () => {
      showModalGroup.value = false;
    };

    const editGroup = (m) => {

      editModalGroup.value = true;
      groupkategorisikap.id = m.idgroup;
      groupkategorisikap.nama_group = m.nama_group;
      groupkategorisikap.id_unit = m.id_unit;
      groupkategorisikap.keterangannilai_id = JSON.parse(m.keterangannilai_id);

      showModalGroup.value = true;
    };

    const editAspek = (m) => {
      editModalAspek.value = true;
      kategorisikap.id = m.idkategorisikap;
      kategorisikap.nama_kategorisikap = m.nama_kategori;
      kategorisikap.unit = m.id_unit;
      kategorisikap.parent_id = m.parent_id;
      kategorisikap.id_ta = m.id_ta;
      kategorisikap.type = m.type_kategori;
      kategorisikap.group_id = m.idgroup;
      kategorisikap.keterangannilai_id = m.keterangannilai_id;


      showModalAspek.value = true;
    }
    onMounted(async () => {
      await store.dispatch("subkategorisikap/getData");
      await store.dispatch("groupkategorisikap/get");
      await store.dispatch("groupkategorisikap/getAll");
      await store.dispatch('kategorisikap/getAll'),
        await store.dispatch("user/getDataPermission");
      await store.dispatch("tahunajaran/getAll");
      await store.dispatch("tahunajaran/getActive");
      await store.dispatch('keterangannilai/getAll')
      await store.dispatch("units/getAll");

      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    const group = computed(() => {
      return store.getters["groupkategorisikap/getAll"];
    });

    const kategoris = computed(() => {
      return store.getters["kategorisikap/getAll"];
    });

    const tahunajarans = computed(() => {
      return store.getters["tahunajaran/getAll"];
    });

    const groupsubkategorisikaps = computed(() => {
      return store.getters["groupkategorisikap/get"];
    });

    const tasaktif = computed(() => {
      return store.getters["tahunajaran/getActive"];
    });

    const subkategorisikaps = computed(() => {
      return store.getters["subkategorisikap/getData"];
    });

    const datapermissions = computed(() => {
      return store.getters["user/getDataPermission"];
    });

    const tas = computed(() => {
      return store.getters["tahunajaran/getAll"];
    });

    const units = computed(() => {
      return store.getters["units/getAll"];

    });

    const keterangannilai = computed(() => {
      return store.getters["keterangannilai/getAll"];
    });

    const perPageOptions = [5, 10, 15, 25, 50, 100];


    function savesubkategorisikap() {
      let formData = new FormData();
      formData.append('id_ta', data.id_ta)
      formData.append('nama_subkategori', data.nama_subkategorisikap)
      formData.append('kategorisikap_id', data.aspek)

      formData.append("_method", "POST");

      store
        .dispatch("subkategorisikap/store", formData)
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-subkategorisikap" });
          Swal.fire({
            icon: "success",
            title: "Tambah data berhasil",
          });


          data.id_ta = '';
          data.nama_subkategorisikap = '';
          data.aspek = '';


          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          // //console.log(error);

          validation.value = error;
        });
    }

    const changePage = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("subkategorisikap/getData", {
        page: page,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        unit: data.unit,

      });
    };

    const changePerPage = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("subkategorisikap/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        unit: data.unit,
      });
    };

    const changeUnit = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("subkategorisikap/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        unit: data.unit,
      });
    };

    const changeGroup = async () => {

      await store.dispatch("kategorisikap/getAll", data.group);
    };

    const changeTa = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("subkategorisikap/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        unit: data.unit,
      });


    };

    const searchData = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("subkategorisikap/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian
      });


      // Lakukan tindakan yang diinginkan dengan nilai searchQuery
    };

    watch(
      () => store.state.tahunajaran.active,
      (newactive) => {
        if (newactive) {
          const {
            id,


          } = newactive;
          data.ta = route.query.id ?? id;


        }
      }
    );

    function savegroupkategorisikap() {
      let formData = new FormData();
      formData.append('nama_group', this.groupkategorisikap.nama_group)
      formData.append('id_unit', this.groupkategorisikap.id_unit)
      formData.append("keterangannilai_id[]", this.groupkategorisikap.keterangannilai_id);
      // formData.append('id_ta', this.kategorisikap.id_ta)

      formData.append("_method", "POST");
      //panggil actions "register" dari module "auth"
      store
        .dispatch("groupkategorisikap/store", formData)
        .then(() => {
          //redirect ke dashboard
          showModalGroup.value = false;
          store.dispatch('groupkategorisikap/get')
          Swal.fire({
            icon: "success",
            title: "Tambah data berhasil",
          });

          groupkategorisikap.nama_group = '';
          groupkategorisikap.id_unit = '';
          groupkategorisikap.keterangannilai_id = '';


          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          // //console.log(error);

          validation.value = error;
        });
    }

    function updategroupkategorisikap() {
      //define variable


      let formData = new FormData();
      formData.append('nama_group', this.groupkategorisikap.nama_group)
      formData.append('id_unit', this.groupkategorisikap.id_unit)
      formData.append("keterangannilai_id[]", this.groupkategorisikap.keterangannilai_id);
      formData.append("_method", "POST");
      //panggil actions "register" dari module "auth"
      store
        .dispatch("groupkategorisikap/update", {
          groupkategorisikapId: groupkategorisikap.id,
          payload: formData,
        })
        .then(() => {
          editModalGroup.value = false;
          showModalGroup.value = false;
          store.dispatch('groupkategorisikap/get')
          store.dispatch('subkategorisikap/getData')
          Swal.fire({
            icon: "success",
            title: "Tambah data berhasil",
          });

          groupkategorisikap.nama_group = '';
          groupkategorisikap.id_unit = '';
          groupkategorisikap.keterangannilai_id = '';

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });
    }

    function savekategorisikap() {
      let formData = new FormData();
      formData.append('nama_kategori', this.kategorisikap.nama_kategorisikap)
      formData.append('unit_id', this.kategorisikap.unit)
      formData.append('parent_id', this.kategorisikap.parent_id)
      formData.append('id_ta', this.kategorisikap.id_ta)
      formData.append('type_kategori', this.kategorisikap.type)
      formData.append('group_id', this.kategorisikap.group_id)
      formData.append("keterangannilai_id[]", this.kategorisikap.keterangannilai_id);

      formData.append("_method", "POST");
      //panggil actions "register" dari module "auth"
      store
        .dispatch("kategorisikap/store", formData)
        .then(() => {
          //redirect ke dashboard

          showModalAspek.value = false;
          store.dispatch('groupkategorisikap/get')
          Swal.fire({
            icon: "success",
            title: "Tambah data berhasil",
          });

          kategorisikap.nama_kategorisikap = '';
          kategorisikap.unit = '';
          kategorisikap.parent_id = '';
          kategorisikap.id_ta = '';
          kategorisikap.type = '';
          kategorisikap.group_id = '';
          kategorisikap.keterangannilai_id = '';


          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          // //console.log(error);

          validation.value = error;
        });
    }

    function updatekategorisikap() {
      //define variable


      let formData = new FormData();
      formData.append('nama_kategori', this.kategorisikap.nama_kategorisikap)
      formData.append('unit_id', this.kategorisikap.unit)
      formData.append('id_ta', this.kategorisikap.id_ta)
      formData.append('parent_id', this.kategorisikap.parent_id)
      formData.append('type_kategori', this.kategorisikap.type)
      formData.append('group_id', this.kategorisikap.group_id)
      formData.append("keterangannilai_id[]", this.kategorisikap.keterangannilai_id);
      formData.append("_method", "POST");

      //panggil actions "register" dari module "auth"
      store
        .dispatch("kategorisikap/update", {
          kategorisikapId: kategorisikap.id,
          payload: formData,
        })
        .then(() => {
          //redirect ke dashboard
          showModalAspek.value = false;
          store.dispatch('groupkategorisikap/get')
          Swal.fire({
            icon: "success",
            title: "Tambah data berhasil",
          });

          kategorisikap.nama_kategorisikap = '';
          kategorisikap.unit = '';
          kategorisikap.parent_id = '';
          kategorisikap.id_ta = '';
          kategorisikap.type = '';
          kategorisikap.group_id = '';
          kategorisikap.keterangannilai_id = '';

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });
    }


    function confirmDelete(id) {
      Swal.fire({
        title: 'Konfirmasi',
        text: 'Apakah Anda yakin ingin menghapus data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Ya, hapus!',
        cancelButtonText: 'Batal',
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch('subkategorisikap/destroy', id)
            .then(() => {

              store.dispatch("subkategorisikap/getData");
              // 
              // router.push({ name: "admin-subkategorisikap" });
              router.push({ name: "admin-subkategorisikap" });
              //alert
              Swal.fire({
                title: 'BERHASIL!',
                text: "Data Berhasil Dihapus!",
                icon: 'success',
                showConfirmButton: false,
                timer: 2000
              })



            })
        }
      });
    }

    // function confirmDeleteGroup(id) {
    //   Swal.fire({
    //     title: 'Konfirmasi',
    //     text: 'Apakah Anda yakin ingin menghapus data?',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#d33',
    //     cancelButtonColor: '#3085d6',
    //     confirmButtonText: 'Ya, hapus!',
    //     cancelButtonText: 'Batal',
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       store.dispatch('groupkategorisikap/destroy', id)
    //         .then((response) => {

    //           console.log(response);
    //           // Handle response from the server
    //           if (response.success) {
    //             Swal.fire({
    //               title: 'Berhasil',
    //               text: response.data.message,
    //               icon: 'success',
    //               confirmButtonColor: '#3085d6',
    //               confirmButtonText: 'OK',
    //             });


    //             // Lakukan hal lain jika diperlukan setelah penghapusan berhasil
    //           } else {
    //             Swal.fire({
    //               title: 'Gagal',
    //               text: response.data.message,
    //               icon: 'error',
    //               confirmButtonColor: '#d33',
    //               confirmButtonText: 'OK',
    //             });


    //             // Lakukan hal lain jika diperlukan setelah penghapusan gagal
    //           }
    //         })
    //         .catch((error) => {
    //           console.error(error);
    //           // Handle error jika terjadi
    //           Swal.fire({
    //             title: 'Error',
    //             text: 'Terjadi kesalahan saat menghapus data',
    //             icon: 'error',
    //             confirmButtonColor: '#d33',
    //             confirmButtonText: 'OK',
    //           });


    //         });
    //     }
    //   });
    // }


    setNavPills();
    setTooltip(store.state.bootstrap);

    return {
      savegroupkategorisikap,
      subkategorisikaps,
      changePage,
      changePerPage,
      perPageOptions,
      data,
      searchData,
      confirmDelete,
      datapermissions,
      tas,
      units,
      changeTa,
      changeUnit,
      groupsubkategorisikaps,
      kategoris,
      tahunajarans,
      changeGroup,
      savesubkategorisikap,
      validation,
      showModalGroup,
      openModalGroup,
      closeModalGroup,
      showModalAspek,
      openModalAspek,
      closeModalAspek,
      groupkategorisikap,
      keterangannilai,
      kategorisikap,
      savekategorisikap,
      group,
      editGroup,
      updategroupkategorisikap,
      editModalGroup,
      editAspek,
      updatekategorisikap,
      editModalAspek,
      tasaktif

    };
  },
};
</script>


<style scoped>
/* Add your modal styling here */
.modal {
  /* Add styles for overlay/background */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;

}


.modal-content {
  /* Add styles for the modal content */
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

/* Gaya untuk tabel utama */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  /* Untuk memberikan sedikit ruang antara tabel dan elemen lainnya */
}

/* Gaya untuk elemen header tabel (thead) */
thead {
  background-color: #f2f2f2;
  /* Warna latar belakang untuk header */
}

/* Gaya untuk sel header tabel (th) */
th,
td {
  border: 1px solid #ddd;
  /* Garis tepi untuk sel header dan sel data */
  padding: 8px;
  /* Ruang dalam di dalam sel */
  text-align: left;
  /* Aligment teks ke kiri */
}

/* Gaya untuk baris tabel (tr) */
tr:nth-child(even) {
  background-color: #f9f9f9;
  /* Warna latar belakang untuk baris genap */
}

/* Gaya saat kursor berada di atas sel */
td:hover {
  background-color: #e0e0e0;
  /* Warna latar belakang saat kursor di atas sel */
}
</style>
