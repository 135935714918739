import Api from '../../api/Api'
const nilaipernyataan = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        datas: [],
    },

    //mutations
    mutations: {
        SET_DATA(state, datas) {
            state.datas = datas
        },
    },

    _actions: {
        store({ commit }, formData) {


            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/nilaipernyataan', formData)

                    .then(response => {


                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        getData({ commit }, payload) {
            const token = localStorage.getItem('token')
            console.log(payload);
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/nilaipernyataan/getbykelas?id=${payload}`)
                .then(response => {
                    // console.log('ini nilaiss', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('SET_DATA', response.data.data)

                })
        },


    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
        getData(state) {
            return state.datas
        },
    }

}

export default nilaipernyataan