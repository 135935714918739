<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Tambah Master Mapel Ceklis</h5>
              </div>
            </div>
  
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <form @submit.prevent="savemapelceklis">
                  
  
                    <!-- <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="">Unit</label>
                            <select
                            class="form-control select2"
                            v-model="mapelceklis.id_unit"
    
    
                            >
                              <option value="">-- select Unit--</option>
                              <option
                                v-for="u in units"
                                :key="u.id"
                                :value="u.id"
                              >
                                {{ u.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div> -->
  
                    
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="exampleFormControlInput1"
                            >Tahun Ajaran <span class="text-danger">*</span></label
                          >
                          <select class="form-control" v-model="mapelceklis.id_ta">
                          <option value="">-- select tahun ajaran --</option>
                          <option v-for="tahunajaran in tahunajarans" :key="tahunajaran.id" :value="tahunajaran.id">{{ tahunajaran.desc}}</option>
                        </select>
                          <small class="text-danger" v-if="validation.id_ta">{{ validation.id_ta[0] }}</small>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <button type="submit" class="btn btn-success btn-sm">
                          simpan
                        </button>
                        &nbsp;
                        <router-link
                          :to="{ name: 'admin-mastermapelceklis' }"
                          class="btn btn-danger btn-sm"
                          >kembali</router-link
                        >
                      </div>
                    </div>
                 
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { ref, reactive, computed, onMounted } from "vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";
  import Swal from 'sweetalert2';
  
  export default {
    name: "kelasIndex",
    components: {},
    data() {
      return {
        showkelas: false,
      };
    },
  
    mounted() {
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    },
  
    setup() {
      //user state
      const mapelceklis = reactive({
      id_unit:"",
        id_ta: "",
      
    });
  
      //validation state
      const validation = ref([]);
  
      //store vuex
      const store = useStore();
  
      //route
      const router = useRouter();
  
      //function register, fungsi ini di jalankan ketika form di submit
      function savemapelceklis() {
       
       //define variable
     
  
       let formData = new FormData();
  
      
          formData.append('id_ta', this.mapelceklis.id_ta)
          formData.append('id_unit', this.mapelceklis.id_unit)
        
       formData.append("_method", "POST");
       //panggil actions "register" dari module "auth"
       store
         .dispatch("mapelceklis/store", 
           formData,
         )
         .then((response) => {
           //redirect ke dashboard
           router.push({ name: "admin-mastermapelceklis" });
           console.log('halo',response.data.status)
           if(response.data.status == true){
                  Swal.fire({
                icon: 'success',
                title: 'Tambah data berhasil',
              });
           }else{
              Swal.fire({
            icon: 'error',
            title: 'data sudah ada',
          });
           }
           // toast.success("Tambah data berhasil")
         })
         .catch((error) => {
           //show validaation message
           // //console.log(error);
  
           validation.value = error;
         });
     }
  
  
      onMounted(async () => {
        await store.dispatch('tahunajaran/getAll'),
        await store.dispatch("units/getAll");
      });
  
      //computed
      const tahunajarans = computed(() => {
        //panggil getter dengan nama "getParent" di module "kelas" vuex
        return store.getters["tahunajaran/getAll"];
      });

      const units = computed(() => {
      //panggil getter dengan nama "getParent" di module "unitlevel" vuex
      return store.getters["units/getAll"];
    });
  
     
  
   
      //return a state and function
      return {
        mapelceklis, // <-- state user
        validation, // <-- state validation
        savemapelceklis,
        tahunajarans,
        units,
      };
    },
  };
  </script>