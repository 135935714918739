<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Tanggal Publikasi Laporan Bulanan KB-TK</h5>
                <router-link to="/tanggal-publikasi-kbtk-tambah" class="btn btn-primary btn-sm">Tambah</router-link>
              </div>
            </div>
  
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <table
                    id="datatable-search"
                    class="table table-flush small-table"
                    style="font-size: 12px"
                  >
                    <thead class="thead-light">
                        <tr>
                          <th>Tahun Ajaran</th>
                          <th>Bulan</th>
                          <th>Tanggal Titi Mangsa</th>
                          <th>Tanggal Awal Publikasi</th>
                          <th>Tanggal Akhir Publikasi</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="m in publikasi.data" :key="m.id" >
                          <td>
                            {{ m.ta ? m.ta.desc:'' }}
                          </td>
                          <td>
                            {{ m.bulans ? m.bulans.nama:'' }}
                          </td>
                          <td>
                            <!-- {{ m.tgl_titi_mangsa ? m.tgl_titi_mangsa : '' }} -->
                            <span v-if="m.tgl_titi_mangsa == null "></span>
                            <span v-else>
                              {{
                                new Date(m.tgl_titi_mangsa).toLocaleString("id-ID", {
                                  timeZone: "Asia/Jakarta",
                                  hourCycle: "h23",
                                  year: "numeric",
                                  month: "short",
                                  day: "2-digit",
                                })
                              }}
                            </span>
                          </td>
                          <td>
                            <!-- {{ m.tanggal_awal }} -->
                            {{
                              new Date(m.tanggal_awal).toLocaleString("id-ID", {
                                timeZone: "Asia/Jakarta",
                                hourCycle: "h23",
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                our12: false
                              }).replace(/\./g, ':')
                            }}

                          </td>
                          <td>
                            <!-- {{ m.tanggal_akhir }} -->
                            {{
                              new Date(m.tanggal_akhir).toLocaleString("id-ID", {
                                timeZone: "Asia/Jakarta",
                                hourCycle: "h23",
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                our12: false
                              }).replace(/\./g, ':')
                            }}
                          </td>
                          <td>
                            <router-link :to="{name: 'admin-tanggalpublikasikbtkedit', params:{id: m.id } }" class="btn btn-warning btn-xs m-0 me-2"> <i class="fas fa-edit"></i></router-link>
                          </td>
                        </tr>
                      </tbody>
                  </table>
                </div>
                <div class="col-md-12">
                  <div class="card-footer d-flex justify-content-between">
                  <span>
                    <p>Menampilkan {{publikasi.to}} dari {{publikasi.total}}</p>
                  </span>
                  <nav aria-label="Pagination">
                    <ul class="pagination justify-content-end">
                      <li class="page-item" :class="{ disabled: publikasi.current_page === 1 }">
                        <a class="page-link" @click="changePage(publikasi.current_page - 1)" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li v-for="page in publikasi.links" :key="page"   :class="{ 'page-item': true, 'active': page.active === true }"
                      >
                        <a class="page-link" @click="changePage(page.label)" href="#" v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{ page.label }}</a>
                      </li>
                      <li class="page-item" :class="{ disabled: publikasi.current_page === publikasi.last_page }">
                        <a class="page-link" @click="changePage(publikasi.current_page + 1)" href="#" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { reactive, onMounted,  computed, ref } from "vue";
  import { useStore } from "vuex";
  import { useRoute, useRouter } from "vue-router";
  import Swal from "sweetalert2";
  import moment from 'moment';
  
  export default {
    name: "KBEdit",
    components: {},
    data() {
      return {
        showsiswa: false,
        isSubmitting:false,
      };
    },
    
  
    mounted() {
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    },
  
    setup() {
      //siswa state

      const variable = reactive({
        id_unit: "",
        id_ta:"",
        id_student: "",
        bulan: "",
        tahun: "",
        elemen: "",
        narasi: "",
        foto1:"",
        foto2:"",
      });

      const formattedDate = ref('');
      const setFormattedDate = () => {
      moment.locale('id');
      const currentDate = moment();
      const formatted = currentDate.format('DD MMMM YYYY');
      formattedDate.value = formatted;
    };
     


      const siswa = computed(() => {
            return store.getters["siswa/getSiswaAktifin"];
      });



      //validation state

  
      //store vuex
      const store = useStore();

      const router = useRouter();

      const validation = ref([]);
  


    const route = useRoute();
  
      //function register, fungsi ini di jalankan ketika form di submit
    
      const publikasi = computed(() => {
            return store.getters["narasikb/publikasi_index"];
      });


      const tahunajaran = computed(() => {
            return store.getters["tahunajaran/getActive"];
      });

      const datapermissions = computed(() => {
        return store.getters["user/getDataPermission"];
      });

      const listbulan = computed(() => {
            return store.getters["global/getBulan"];
      });

      const data = reactive({
        // currentPage: 1,
        perPage:100,
        search:'',
        raport: {
          file: '',
          id_studentactive:''
        },
        pages: "tanggalpublikasikbtk-view",
        
      });

      onMounted(async function() {
          await store.dispatch("user/getDataPermission");
          await store.dispatch("global/getBulan");
          await store.dispatch("narasikb/publikasi_index"); // Gunakan this di sini
          await store.dispatch("tahunajaran/getActive");
          setFormattedDate();

          const permissions = localStorage.getItem("permission");
          console.log(permissions);
          if (!permissions.includes(data.pages)) {
            // router.push("/error"); // Ganti '/error-page' dengan rute halaman error Anda
          }
      });
  
      // watch(
      //   () => store.state.reg.pesertadetail,
      //   (newsiswa) => {
      //     //console.log('newsiswa',newsiswa);
      //     if (newsiswa) {
      //       const {
      //           fullname,
      //           nickname,
      //           gender,
      //           birthplace,
      //           birthdate,
      //           tingkatan,
      //           sekolah,
      //           id_unitlevel,
      //           id,
      //       } = newsiswa;
      //       siswa.fullname = fullname;
      //       siswa.nickname = nickname;
      //       siswa.gender = gender;
      //       siswa.tempatlahir = birthplace;
      //       siswa.tanggallahir = birthdate;
      //       siswa.tingkatan = tingkatan;
      //       siswa.sekolah = sekolah;
      //       siswa.id_unitlevel = id_unitlevel;
      //       // siswa.id = id;
      //       siswa.id = id;
            
            
            
      //     }
      //   }
      // );

      

      

      async function savePublikasi(event) {
         // Simpan referensi konteks Vue
         const self = this;

        // Mengatur properti isSubmitting menjadi true saat tombol diklik
        self.isSubmitting = true;

        // alert(self.isSubmitting);
        // Tambahkan sweet alert untuk konfirmasi
        const confirmation = await Swal.fire({
            title: 'Apakah Anda yakin ingin menyimpan data ini?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal'
        });

        // Lanjutkan menyimpan data jika pengguna mengonfirmasi
        if (confirmation.isConfirmed) {
            let formData = new FormData();
            formData.append('id_ta', this.tahunajaran.id);
            formData.append('id_laporan_bulanan_kbtk', 0);
            formData.append('tanggal_awal', event.target.tanggal_awal.value);
            formData.append('tanggal_akhir', event.target.tanggal_akhir.value);
            formData.append('bulan', event.target.bulan.value);
            formData.append("_method", "POST");
            console.log(formData);
            store
                .dispatch("narasikb/publikasi_store", formData)
                .then(() => {
                  self.isSubmitting = false;
                    Swal.fire({
                        icon: "success",
                        title: "Simpan data berhasil",
                    });
                })
                .catch((error) => {
                    validation.value = error;
                });
        }
    }

      

      //return a state and function
      return {
        router,
        route,
        siswa, // <--
        variable,
        data,
        validation,
        tahunajaran,
        publikasi,
        savePublikasi,
        datapermissions,
        listbulan,
        setFormattedDate
      };
    },
  };
  </script>