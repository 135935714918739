import Api from '../../api/Api'
const masterkelas = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        dataall: [],
        getWalAll:[],
    },

    //mutations
    mutations: {
        SET_ALL(state, dataall) {
            state.dataall = dataall
        },

        SET_WALL(state, getWalAll) {
            state.getWalAll = getWalAll
        },
    },

    _actions: {
      
        getAll({ commit },payload) {
            let q = payload ? payload : '';
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/masterkelas?q=${q}`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_ALL', response.data.data)
                })
        },

        getWalAll({ commit },payload) {
            let q = payload ? payload : '';
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/masterkelas?instructor=${q}`)
                .then(response => {
                    // console.log('ini parent',response.data.data)
                    commit('SET_WALL', response.data.data)
                })
        },

    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
       

       
        getAll(state) {
            return state.dataall
        },

        getWalAll(state) {
            return state.getWalAll
        },
    }

}

export default masterkelas