<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Tambah Daftar Nama Sekolah</h5>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="saveInfoRegistrasi">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Nama Info</label>
                        <input type="text" placeholder="name" class="form-control" v-model="daftarinforegistrasi.name" />
                        <small class="text-danger" v-if="validation.name">{{ validation.name[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">simpan</button>
                      &nbsp;
                      <router-link :to="{ name: 'admin-daftarinforegistrasi' }" class="btn btn-danger btn-sm">kembali</router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";

export default {
  name: "userIndex",
  components: {},
  data() {
    return {
      showuser: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state
    const daftarinforegistrasi = reactive({
      name: "",
    });

    const data = reactive({
      pages: "daftarinforegistrasi-create",
    });

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    onMounted(async () => {
      const permissions = localStorage.getItem("permission");

      if (!permissions.includes(data.pages)) {
        router.push("/error"); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    //function register, fungsi ini di jalankan ketika form di submit
    function saveInfoRegistrasi() {
      let formData = new FormData();
      formData.append("name", this.daftarinforegistrasi.name);

      formData.append("_method", "POST");
      //panggil actions "register" dari module "auth"
      store
        .dispatch("daftarinforegistrasi/store", formData)
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-daftarinforegistrasi" });
          Swal.fire({
            icon: "success",
            title: "Tambah data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          // //console.log(error);

          validation.value = error;
        });
    }

    //return a state and function
    return {
      daftarinforegistrasi, // <-- state user
      validation, // <-- state validation
      saveInfoRegistrasi,
      data,
    };
  },
};
</script>
