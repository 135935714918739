<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
  
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Data Laporan Bulanan KB - TK</h5>
                <router-link to="/penilaian" class="btn btn-danger btn-sm">Kembali</router-link
              >
              </div>
            </div>
            <div class="card-body">
              <div class="row justify-content-between ">
                <div class="col-md-1">
                  <div class="d-flex justify-content-between align-items-center">
                    <span>Show: </span>
                    <select v-model="data.perPage" class="form-control form-control-sm ml-2" @change="changePerPage">
                      <option v-for="option in perPageOptions" :key="option" :value="option">{{ option }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4 d-flex mb-3">
                  <input type="text" class="form-control mr-2"  v-model="data.search" @keypress.enter="searchData" placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2">
                  <button  @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2"> <i class="fa fa-search"></i> </button>
                </div>
              </div>
              <div class="row">
                <div class="row">
                  
                </div>

                <div class="col ms-auto my-auto mr-5" hidden>
                  <div class="input-group mb-3">
                    <select
                      class="form-control select2"
                      v-model="data.unit"
                      @change="changeUnit"
                    >
                      <option value="">-- select Unit --</option>
                      <option
                        v-for="unit in units"
                        :key="unit.id"
                        :value="unit.id"
                      >
                        {{ unit.name}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col ms-auto my-auto mr-5" hidden>
                  <div class="input-group mb-3">
                    <select
                      class="form-control select2"
                      v-model="data.level"
                      @change="changeUnitlevel"
                    >
                      <option value="">-- select Unitlevel --</option>
                      <option
                        v-for="unitlevels in unitlevel"
                        :key="unitlevels.id"
                        :value="unitlevels.id"
                      >
                      {{ unitlevels.code}} - {{ unitlevels.name}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col ms-auto my-auto mr-5" v-if="kelas == ''">
                  <div class="input-group mb-3">
                    <select
                      class="form-control select2"
                      
                      @change="changeKelas($event)"
                    >
                      <option value="">-- select Kelas --</option>
                      <option
                        v-for="kelas in kelasmaster ? kelasmaster.filter(item => item.classname.classname !== null) : []"
                        :key="kelas.classname ? kelas.id : ''"
                        :value="kelas.classname ? kelas.id : ''"
                        :selected="kelas.id && kelas.id == $route.query.kelass"
                        
                      >
                        {{ kelas.classname ? kelas.classname : '' }}
                      </option>
                    </select>
                  </div>
                </div>
                
                <div class="col ms-auto my-auto mr-5" v-if="kelas != '' ">
                  <div class="input-group mb-3">
                    <select
                      class="form-control select2"
                      v-model="data.kelas"
                      @change="changeKelas"
                    >
                      <option value="">-- select Kelas Bidang --</option>
                      <option v-for="k in kelas" :key="k.idkelas" :value="k.idkelas">
                      {{ k.classname }} - {{ k.nama_mapel }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="col ms-auto my-auto mr-5" >
                  <!-- <a :href="`https://api-v2.annisaa-izada.sch.id/exportsiswaaktif?unit=${data.unit}&unitlevel=${data.level}&kelas=${data.kelas}`" class="btn btn-primary btn-md"><i class="fa fa-file-excel-o" aria-hidden="true"></i>
                    export
                  </a> -->

                </div>

              </div>
              <div class="table-responsive">
                <table
                  id="datatable-search"
                  class="table table-flush small-table"
                  style="font-size: 12px"
                >
                  <thead class="thead-light">
                    <tr style="max-height: 5px">
                      <th class="text-center" rowspan="2">Nama Siswa</th>
                      <th class="text-center" rowspan="2">Kelas</th>
                      <th class="text-center" colspan="5">Jenis Laporan</th>
                      <th class="text-center" rowspan="2">Action</th>
                    </tr>
                    <tr>
                      <th>Wali Kelas</th>
                      <th>Olah Raga</th>
                      <th>Komputer</th>
                      <th>Bahasa Inggris</th>
                      <th>UMMI</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="m in siswas.data" :key="m.id" style="height: 1px">
                      <td>{{ m.fullname }}</td>
                        <td class="text-center">{{ m.classname }}</td>
                      <td>
                        <ul style="text-decoration: none;">
                          <li v-for="n in m.laporan_bulanan_tk_kb_new" :key="n.id" style="list-style: none;">
                            <span v-if="n.elemen === 'wali kelas'">
                                {{ n.bulans ? n.bulans.nama : '' }}
                            </span>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li v-for="n in m.laporan_bulanan_tk_kb_new" :key="n.id" style="list-style: none;">
                            <span v-if="n.elemen === 'olahraga' || n.elemen === 'olah raga'">
                                {{ n.bulans ? n.bulans.nama : '' }}
                            </span>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li v-for="n in m.laporan_bulanan_tk_kb_new" :key="n.id" style="list-style: none;">
                            <span v-if="n.elemen === 'komputer'">
                              {{ n.bulans ? n.bulans.nama : '' }}
                            </span>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li v-for="n in m.laporan_bulanan_tk_kb_new" :key="n.id" style="list-style: none;">
                            <span v-if="n.elemen === 'inggris' || n.elemen === 'bahasa inggris'">
                              {{ n.bulans ? n.bulans.nama : '' }}
                            </span>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li v-for="n in m.laporan_bulanan_tk_kb_new" :key="n.id" style="list-style: none;">
                            <span v-if="n.elemen === 'UMMI' || n.elemen === 'ummi'">
                              {{ n.bulans ? n.bulans.nama : '' }}
                            </span>
                          </li>
                        </ul>
                      </td>
                      <td class="text-center">
                        <router-link v-if="kelas != '' " :to="{name: 'admin-laporan-bulanan-kb-tambah', params:{id: m.id_student,bidang:kelas?kelas[0].nama_mapel:0,instructor:this.$route.params.id },query: { kelass: $route.query.kelass } }" class="btn btn-info btn-xs m-0 me-2"> <i class="fas fa-plus"></i></router-link>
                        <router-link v-if="kelas == ''" :to="{name: 'admin-laporan-bulanan-kb-tambah', params:{id: m.id_student,bidang:'wali kelas',instructor:this.$route.params.id },query: { kelass: $route.query.kelass } }" class="btn btn-info btn-xs m-0 me-2"> <i class="fas fa-plus"></i></router-link>
                        <router-link v-if="kelas != '' " :to="{name: 'admin-laporan-bulanan-kb-history', params:{id: m.id_student,bidang:kelas?kelas[0].nama_mapel:0,instructor:this.$route.params.id },query: { kelass: $route.query.kelass } }" class="btn btn-warning btn-xs m-0 me-2"> <i class="fas fa-eye"></i></router-link>
                        <router-link v-if="kelas == '' " :to="{name: 'admin-laporan-bulanan-kb-history', params:{id: m.id_student,bidang:'wali kelas',instructor:this.$route.params.id },query: { kelass: $route.query.kelass } }" class="btn btn-warning btn-xs m-0 me-2"> <i class="fas fa-eye"></i></router-link>
                        <!-- <button class="btn btn-danger btn-xs m-0" @click="confirmDelete(m.id)"> <i class="fas fa-trash"></i> </button> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            
             
            </div>
  
            <div class="card-footer d-flex justify-content-between">
              <span>
                <p>Menampilkan {{siswas.to}} dari {{siswas.total}}  </p>
              </span>
              <nav aria-label="Pagination">
                <ul class="pagination justify-content-end">
                  <li class="page-item" :class="{ disabled: siswas.current_page === 1 }">
                    <a class="page-link" @click="changePage(siswas.current_page - 1)" href="#" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li v-for="page in siswas.links" :key="page"   :class="{ 'page-item': true, 'active': page.active === true }"
                  >
                    <a class="page-link" @click="changePage(page.label)" href="#" v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{ page.label }}</a>
                  </li>
                  <li class="page-item" :class="{ disabled: siswas.current_page === siswas.last_page }">
                    <a class="page-link" @click="changePage(siswas.current_page + 1)" href="#" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>


  <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { computed, onMounted, reactive } from "vue";
  import { useStore } from "vuex";
  import Swal from 'sweetalert2';
  import {  useRoute, useRouter } from "vue-router";
  
  export default {
    name: "siswaIndex",
  
    setup() {
      const store = useStore();
      const data = reactive({
        // currentPage: 1,
        perPage:10,
        search:'',
        kelas:'',
        unitlevel:'',
        level:'',
        unit:''
        
      });
  
      const router = useRouter();

      const route = useRoute();
  
      

      const datapermissions = computed(() => {
        return store.getters["user/getDataPermission"];
      });
  
      const siswas = computed(() => {
        return store.getters["siswa/getSiswaAktif"];
      });

      const units = computed(() => {
        return store.getters["units/getAll"];
      });

      const unitlevel = computed(() => {
        return store.getters["unitlevel/getAll"];
      });

      const kelasmaster = computed(() => {
        return store.getters["masterkelas/getWalAll"];
      });


      const kelas = computed(() => {
            return store.getters["bebanmengajar/getKelasGuruDataKB"];
      });

      const cekgurubidang = computed(() => {
            return store.getters["narasikb/cekgurubidang"];
      });
      
  
      
      const perPageOptions = [5, 10, 15, 25,30, 50, 100];
  
  
      const changePage = async (page) => {
        let jumlah = data.perPage;
        const pencarian = data.search;
        await store.dispatch("siswa/getSiswaAktif", {
          page:page,
          perPage:jumlah,
          search: pencarian,
          kelas:route.query.kelass ? route.query.kelass : '',
          unit:data.unit,
          unitlevel:data.level,
          guru:route.params.id
  
        });
      };
  
      const changePerPage = async () => {
        const pencarian = data.search;
        let jumlah = data.perPage;
      
        await store.dispatch("siswa/getSiswaAktif", {
          page:1,
          perPage:jumlah,
          search: pencarian,
          kelas:route.query.kelass ? route.query.kelass : '',
          unit:data.unit,
          unitlevel:data.level,
          guru:route.params.id
        });
      };

      const changeUnit = async () => {
        const pencarian = data.search;
        let jumlah = data.perPage;
      
        await store.dispatch("siswa/getSiswaAktif", {
          page:1,
          perPage:jumlah,
          search: pencarian,
          kelas:route.query.kelass ? route.query.kelass : '',
          unit:data.unit,
          unitlevel:data.level,
          guru:route.params.id
        });

        await store.dispatch("unitlevel/getAll",  data.unit);
      };

      const changeUnitlevel = async () => {
        const pencarian = data.search;
        let jumlah = data.perPage;
      
        await store.dispatch("siswa/getSiswaAktif", {
          page:1,
          perPage:jumlah,
          search: pencarian,
          kelas:route.query.kelass ? route.query.kelass : '',
          unit:data.unit,
          unitlevel:data.level,
          guru:route.params.id
        });

        await store.dispatch("masterkelas/getAll",  data.level);
      };

      const changeKelas = async (event) => {
        const selectedValue = event.target.value;
        // alert('Kelas yang dipilih: ' + selectedValue);
        const pencarian = data.search;
        let jumlah = data.perPage;
      
        await store.dispatch("siswa/getSiswaAktif", {
          page:1,
          perPage:jumlah,
          search: pencarian,
          kelas:selectedValue,
          unit:data.unit,
          unitlevel:data.level,
          guru:route.params.id
        });

        // Update URL dengan parameter kelas
        const params = {
            kelass: selectedValue
        };
        router.push({ path: route.path, query: params });
      
      };


  
      const searchData = async () => {
        const pencarian = data.search;
        let jumlah = data.perPage;
        await store.dispatch("siswa/getSiswaAktifCari", {
          page:1,
          perPage:jumlah,
          search: pencarian,
          kelas:route.query.kelass ? route.query.kelass : '',
          unit:data.unit,
          unitlevel:data.level,
          guru:route.params.id
        });
       
  
    // Lakukan tindakan yang diinginkan dengan nilai searchQuery
      };
  
      function confirmDelete(id) {
        Swal.fire({
          title: 'Konfirmasi',
          text: 'Apakah Anda yakin ingin menghapus data?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Ya, hapus!',
          cancelButtonText: 'Batal',
        }).then((result) => {
          if (result.isConfirmed) {
            store.dispatch('siswa/destroy', id)
                  .then(() => {
  
                    store.dispatch("siswa/getSiswaAktif");
                    router.push({ name: "admin-siswa" });
                    //alert
                    Swal.fire({
                      title: 'BERHASIL!',
                      text: "Data Berhasil Dihapus!",
                      icon: 'success',
                      showConfirmButton: false,
                      timer: 2000
                    })
  
                  })
              }
        });
      }

      onMounted(async () => {
        await store.dispatch("masterkelas/getWalAll",route.params.id);
        await store.dispatch("siswa/getSiswaAktif", {
          page:1,
          perPage:30,
          search:'',
          kelas:route.query.kelass ? route.query.kelass : '',
          unit:'',
          unitlevel:'',
          guru:route.params.id,
        });
        await store.dispatch("units/getAll");
        await store.dispatch("unitlevel/getAll");
        await store.dispatch("user/getDataPermission");
        await store.dispatch("bebanmengajar/getKelasGuruDataKB", route.params.id);
        await store.dispatch("narasikb/cekgurubidang", route.params.id);
      });


      setNavPills();
      setTooltip(store.state.bootstrap);
  
      return {
        siswas,
        changePage,
        changePerPage,
        perPageOptions,
        data,
        searchData,
        confirmDelete,
        units,
        unitlevel,
        kelasmaster,
        changeUnit,
        changeUnitlevel,
        changeKelas,
        datapermissions,
        kelas,
        cekgurubidang,
      
      };
    },
  };
  </script>

  
  