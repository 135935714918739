import Api from '../../api/Api'
const orangtua = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        datas:[],
    },

    //mutations
    mutations: {
        SET_DATA(state, datas) {
            state.datas = datas
        },
    },

    _actions: {
        getData({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/orangtua?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}`)
                .then(response => {
                    // console.log('ini parent',response.data.data)
                    commit('SET_DATA', response.data.data)
                })
        },
        
    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
        
        getData(state) {
            return state.datas
        },
    }

}

export default orangtua