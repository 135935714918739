<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Tambah Aturan Nilai</h5>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="saveAturanNilai">

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Nama Aturan Nilai <span
                            class="text-danger">*</span></label>
                        <input type="text" placeholder="Nama Aturan Nilai" class="form-control"
                          v-model="aturannilai.nama_aturan_nilai" />
                        <small class="text-danger" v-if="validation.nama_aturan_nilai">{{
                  validation.nama_aturan_nilai[0] }}</small>
                      </div>
                    </div>
                  </div>




                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tahun Ajaran <span class="text-danger">*</span></label>
                        <select class="form-control" v-model="aturannilai.id_ta">
                          <option value="">-- select tahun ajaran --</option>
                          <option v-for="tahunajaran in tahunajarans" :key="tahunajaran.id" :value="tahunajaran.id">{{
                  tahunajaran.desc }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.id_ta">{{ validation.id_ta[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Nama Kepala Sekolah <span
                            class="text-danger">*</span></label>
                        <input type="text" placeholder="Nama Kepala Sekolah" class="form-control"
                          v-model="aturannilai.nama_kepala_sekolah" />
                        <small class="text-danger" v-if="validation.nama_kepala_sekolah">{{
                  validation.nama_kepala_sekolah[0] }}</small>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Wakil Kurikulum <span class="text-danger">*</span></label>
                        <input type="text" placeholder="Wakil Kurikulum" class="form-control"
                          v-model="aturannilai.wakil_kurikulum" />
                        <small class="text-danger" v-if="validation.wakil_kurikulum">{{ validation.wakil_kurikulum[0]
                          }}</small>
                      </div>
                    </div>
                  </div>


                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tanggal Awal Publikasi Raport Mid <span
                            class="text-danger">*</span></label>
                        <input type="datetime-local" class="form-control"
                          v-model="aturannilai.tanggal_awal_publikasi_raport_mid" />

                        <small class="text-danger" v-if="validation.tanggal_awal_publikasi_raport_mid">{{
                  validation.tanggal_awal_publikasi_raport_mid[0] }}</small>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tanggal Akhir Publikasi Raport Mid <span
                            class="text-danger">*</span></label>
                        <input type="datetime-local" class="form-control"
                          v-model="aturannilai.tanggal_akhir_publikasi_raport_mid" />

                        <small class="text-danger" v-if="validation.tanggal_akhir_publikasi_raport_mid">{{
                  validation.tanggal_akhir_publikasi_raport_mid[0] }}</small>
                      </div>
                    </div>
                  </div>


                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tanggal Awal Publikasi Raport Semester <span
                            class="text-danger">*</span></label>
                        <input type="datetime-local" class="form-control"
                          v-model="aturannilai.tanggal_awal_publikasi_raport_semester" />

                        <small class="text-danger" v-if="validation.tanggal_awal_publikasi_raport_semester">{{
                  validation.tanggal_awal_publikasi_raport_semester[0] }}</small>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tanggal Akhir Publikasi Raport Semester <span
                            class="text-danger">*</span></label>
                        <input type="datetime-local" class="form-control"
                          v-model="aturannilai.tanggal_akhir_publikasi_raport_semester" />

                        <small class="text-danger" v-if="validation.tanggal_akhir_publikasi_raport_semester">{{
                  validation.tanggal_akhir_publikasi_raport_semester[0] }}</small>
                      </div>
                    </div>
                  </div>



                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tanggal Awal Publikasi Surat Kelulusan <span
                            class="text-danger">*</span></label>
                        <input type="datetime-local" class="form-control"
                          v-model="aturannilai.tanggal_awal_publikasi_raport_kelulusan" />

                        <small class="text-danger" v-if="validation.tanggal_awal_publikasi_raport_kelulusan">{{
                  validation.tanggal_awal_publikasi_raport_kelulusan[0] }}</small>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tanggal Akhir Publikasi Surat Kelulusan <span
                            class="text-danger">*</span></label>
                        <input type="datetime-local" class="form-control"
                          v-model="aturannilai.tanggal_akhir_publikasi_raport_kelulusan" />

                        <small class="text-danger" v-if="validation.tanggal_akhir_publikasi_raport_kelulusan">{{
                  validation.tanggal_akhir_publikasi_raport_kelulusan[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Unit <span class="text-danger">*</span></label>
                        <select class="form-control" v-model="aturannilai.id_unit" @change="changeUnit">
                          <option value="">-- select Units --</option>
                          <option v-for="unit in units" :key="unit.id" :value="unit.id">{{ unit.name }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.id_unit">{{ validation.id_unit[0] }}</small>
                      </div>
                    </div>
                  </div>



                  <div class="row mb-3">
                    <div class="col-2" v-for="unitlevel in unitlevels" :key="unitlevel.id">

                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" v-model="aturannilai.id_unitlevels"
                          :value="unitlevel.id">
                        <label class="custom-control-label">{{ unitlevel.name }}</label>
                      </div>

                    </div>
                  </div>



                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">
                        simpan
                      </button>
                      &nbsp;
                      <router-link :to="{ name: 'admin-aturannilai' }"
                        class="btn btn-danger btn-sm">kembali</router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from 'sweetalert2';

export default {
  name: "userIndex",
  components: {},
  data() {
    return {
      showuser: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },


  setup() {
    //user state
    const aturannilai = reactive({
      nama_aturan_nilai: '',
      id_ta: '',
      nama_kepala_aturannilai: '',
      wakil_kurikulum: '',
      id_unit: '',
      tanggal_awal_publikasi_raport_mid: '',
      tanggal_akhir_publikasi_raport_mid: '',
      tanggal_awal_publikasi_raport_semester: '',
      tanggal_akhir_publikasi_raport_semester: '',
      tanggal_awal_publikasi_raport_kelulusan: '',
      tanggal_akhir_publikasi_raport_kelulusan: '',
      id_unitlevels: []
    });

    const data = reactive({

      pages: 'uploadraport-create'

    });

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();


    //function register, fungsi ini di jalankan ketika form di submit
    function saveAturanNilai() {

      let formData = new FormData();
      formData.append('nama_aturan_nilai', this.aturannilai.nama_aturan_nilai)
      formData.append('id_ta', this.aturannilai.id_ta)
      formData.append('id_unit', this.aturannilai.id_unit)
      formData.append('nama_kepala_sekolah', this.aturannilai.nama_kepala_sekolah)
      formData.append('wakil_kurikulum', this.aturannilai.wakil_kurikulum)
      formData.append('tanggal_awal_publikasi_raport_mid', this.aturannilai.tanggal_awal_publikasi_raport_mid)
      formData.append('tanggal_akhir_publikasi_raport_mid', this.aturannilai.tanggal_akhir_publikasi_raport_mid)
      formData.append('tanggal_awal_publikasi_raport_semester', this.aturannilai.tanggal_awal_publikasi_raport_semester)
      formData.append('tanggal_akhir_publikasi_raport_semester', this.aturannilai.tanggal_akhir_publikasi_raport_semester)
      formData.append('tanggal_awal_publikasi_raport_kelulusan', this.aturannilai.tanggal_awal_publikasi_raport_kelulusan)
      formData.append('tanggal_akhir_publikasi_raport_kelulusan', this.aturannilai.tanggal_akhir_publikasi_raport_kelulusan)
      Array.from(this.aturannilai.id_unitlevels).forEach(id_unitlevels => {
        formData.append("id_unitlevels[]", id_unitlevels);
      })

      formData.append("_method", "POST");
      //panggil actions "register" dari module "auth"
      store
        .dispatch("aturannilai/store",
          formData,
        )
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-aturannilai" });
          Swal.fire({
            icon: 'success',
            title: 'Tambah data berhasil',
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          // //console.log(error);

          validation.value = error;
        });
    }

    onMounted(async () => {
      await store.dispatch('tahunajaran/getAll'),
        await store.dispatch('unitlevel/getAll'),
        await store.dispatch('guru/getAll'),
        await store.dispatch('units/getAll');
      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    //computed
    const tahunajarans = computed(() => {
      return store.getters["tahunajaran/getAll"];
    });

    const unitlevels = computed(() => {
      return store.getters["unitlevel/getAll"];
    });

    const units = computed(() => {
      return store.getters["units/getAll"];
    });

    const gurus = computed(() => {
      return store.getters["guru/getAll"];
    });

    //return a state and function
    return {
      aturannilai, // <-- state user
      validation, // <-- state validation
      saveAturanNilai,
      tahunajarans,
      unitlevels,
      units,
      gurus

    };
  },
};
</script>