import Api from '../../api/Api'
const generatenis = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        dataall: []
    },

    //mutations
    mutations: {

    
        SET_ALL(state, dataall) {
            state.datall = dataall
        },
      
    },

    _actions: {
      
       
        generateAll({ commit }) {
            // //console.log('ini parsing',payload);
           
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/generatenis/all`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_ALL', response.data.data)
                })
        },
        
    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
       

       
        generateAll(state) {
            return state.dataall
        },

      
    }

}

export default generatenis