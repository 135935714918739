<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Tambah Daftar Nama Sekolah</h5>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="saveSekolah">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">NPSN</label>
                        <input type="text" placeholder="Npsn" class="form-control" v-model="sekolah.npsn" />
                        <small class="text-danger" v-if="validation.npsn">{{ validation.npsn[0] }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Nama Sekolah</label>
                        <input type="text" placeholder="link" class="form-control" v-model="sekolah.name" />
                        <small class="text-danger" v-if="validation.name">{{ validation.name[0] }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Alamat</label>
                        <input type="text" placeholder="icon" class="form-control" v-model="sekolah.address" />
                        <small class="text-danger" v-if="validation.address">{{ validation.address[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Provinsi</label>
                        <select class="form-control" v-model="sekolah.id_province" @change="changeProvince">
                          <option value="">-- select Provinsi --</option>
                          <option v-for="p in province" :key="p.id" :value="p.id">{{ p.name }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.id_province">{{ validation.id_province[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Kota</label>
                        <select class="form-control" v-model="sekolah.id_city" @change="changeCity">
                          <option value="">-- select Kota --</option>
                          <option v-for="c in city" :key="c.id" :value="c.id">{{ c.name }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.id_city">{{ validation.id_city[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Kecamatan</label>
                        <select class="form-control" v-model="sekolah.id_district" @change="changeDistrict">
                          <option value="">-- select Kecamatan --</option>
                          <option v-for="d in district" :key="d.id" :value="d.id">{{ d.name }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.id_district">{{ validation.id_district[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Kelurahan</label>
                        <select class="form-control" v-model="sekolah.id_subdistrict">
                          <option value="">-- select Kelurahan --</option>
                          <option v-for="s in subdistrict" :key="s.id" :value="s.id">{{ s.name }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.id_subdistrict">{{ validation.id_subdistrict[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Status</label>
                        <select class="form-control" v-model="sekolah.status">
                          <option value="">-- select Status --</option>
                          <option value="Negeri">Negeri</option>
                          <option value="Swasta">Swasta</option>
                        </select>
                        <small class="text-danger" v-if="validation.status">{{ validation.status[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Internal</label>
                        <select class="form-control" v-model="sekolah.internal">
                          <option value="">-- select Internal --</option>
                          <option value="yes">Iya</option>
                          <option value="no">Tidak</option>
                        </select>
                        <small class="text-danger" v-if="validation.internal">{{ validation.internal[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Email</label>
                        <input type="email" placeholder="email" class="form-control" v-model="sekolah.email" />
                        <small class="text-danger" v-if="validation.email">{{ validation.email[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Telephone</label>
                        <input type="text" placeholder="telephone" class="form-control" v-model="sekolah.phone" />
                        <small class="text-danger" v-if="validation.phone">{{ validation.phone[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Nama Kepala Sekolah</label>
                        <input type="text" placeholder="Kepala Sekolah" class="form-control" v-model="sekolah.leader" />
                        <small class="text-danger" v-if="validation.leader">{{ validation.leader[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">simpan</button>
                      &nbsp;
                      <router-link :to="{ name: 'admin-daftarnamasekolah' }" class="btn btn-danger btn-sm">kembali</router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";

export default {
  name: "userIndex",
  components: {},
  data() {
    return {
      showuser: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state
    const sekolah = reactive({
      npsn: "",
      name: "",
      status: "",
      internal: "",
      address: "",
      id_province: "",
      id_city: "",
      id_district: "",
      id_subdistrict: "",
      map_location: "",
      email: "",
      phone: "",
      leader: "",
    });

    const data = reactive({
      page: "daftarnamasekolah-create",
    });

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    //function register, fungsi ini di jalankan ketika form di submit
    function saveSekolah() {
      let formData = new FormData();
      formData.append("npsn", this.sekolah.npsn);
      formData.append("name", this.sekolah.name);
      formData.append("status", this.sekolah.status);
      formData.append("internal", this.sekolah.internal);
      formData.append("address", this.sekolah.address);
      formData.append("id_province", this.sekolah.id_province);
      formData.append("id_city", this.sekolah.id_city);
      formData.append("id_district", this.sekolah.id_district);
      formData.append("id_subdistrict", this.sekolah.id_subdistrict);
      formData.append("map_location", this.sekolah.map_location);
      formData.append("email", this.sekolah.email);
      formData.append("phone", this.sekolah.phone);
      formData.append("leader", this.sekolah.leader);

      formData.append("_method", "POST");
      //panggil actions "register" dari module "auth"
      store
        .dispatch("daftarnamasekolah/store", formData)
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-daftarnamasekolah" });
          Swal.fire({
            icon: "success",
            title: "Tambah data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          // //console.log(error);

          validation.value = error;
        });
    }

    onMounted(async () => {
      await store.dispatch("global/getProvinsi");
      await store.dispatch("global/getCity");
      await store.dispatch("global/getDistrict");
      await store.dispatch("global/getSubDistrict");
      const permissions = localStorage.getItem("permission");

      if (!permissions.includes(data.page)) {
        router.push("/error"); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    //computed
    const province = computed(() => {
      return store.getters["global/getProvinsi"];
    });

    const city = computed(() => {
      return store.getters["global/getCity"];
    });

    const district = computed(() => {
      return store.getters["global/getDistrict"];
    });

    const subdistrict = computed(() => {
      return store.getters["global/getSubDistrict"];
    });

    const changeProvince = async () => {
      await store.dispatch("global/getCity", sekolah.id_province);
    };

    const changeCity = async () => {
      await store.dispatch("global/getDistrict", sekolah.id_city);
    };

    const changeDistrict = async () => {
      await store.dispatch("global/getSubDistrict", sekolah.id_district);
    };

    //return a state and function
    return {
      sekolah, // <-- state user
      validation, // <-- state validation
      saveSekolah,
      province,
      city,
      district,
      subdistrict,
      changeProvince,
      changeCity,
      changeDistrict,
      data,
    };
  },
};
</script>
