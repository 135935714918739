<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">

            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Absen Siswa {{ data.bulan }}</h5>

              <router-link to="/perwalian" class="btn btn-primary btn-sm">Kembali</router-link>
            </div>
            <div class="row gap-0">
              <dl class="row">
                <dt class="col-sm-2">Tahun Ajaran</dt>
                <dd class="col-sm-10">{{ tas.desc }}</dd>
                <dt class="col-sm-2">Kelas</dt>
                <dd class="col-sm-10">{{ kelas.classname }}</dd>

                <dt class="col-sm-2">Walikelas</dt>
                <dd class="col-sm-10">{{ kelas.wali_kelas }}</dd>
                <!-- <dt class="col-sm-2">Bulan</dt>
                <dd class="col-sm-10">{{ bulanaktif.nama }}</dd> -->

                <dt class="col-sm-2">Filter by bulan</dt>
                <dd class="col-sm-10">
                  <select v-if="tas.status_tahun_ajaran == 'genap'" @change="changeBulan">
                    <option value=""></option>
                    <option value="1">Januari</option>
                    <option value="2">Februari</option>
                    <option value="3">Maret</option>
                    <option value="4">April</option>
                    <option value="5">Mei</option>
                    <option value="5">Juni</option>
                  </select>

                  <select v-if="tas.status_tahun_ajaran == 'ganjil'" @change="changeBulan">
                    <option value=""></option>
                    <option value="7">Juli</option>
                    <option value="8">Agustus</option>
                    <option value="9">September</option>
                    <option value="10">Oktober</option>
                    <option value="11">November</option>
                    <option value="12">Desember</option>
                  </select>
                </dd>

                <dt class="col-sm-2">Absensi Bulan</dt>
                <dd class="col-sm-10">{{ data.namabulan ? data.namabulan : bulanaktif.nama }}</dd>


                <dt class="col-sm-2">Keterangan</dt>
                <dd class="col-sm-10">S = Sakit | I = Izin | A = Tidak Hadir</dd>

              </dl>

            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card-body">
                <div class="table-responsive table-container">
                  <!-- bulan = {{ bulanaktif.nama }} -->
                  <table class="table w-100" border="1">
                    <thead class="sticky-header">
                      <tr>
                        <th style="position: sticky; left: 0; background-color:#fdfdfd;">Nama Siswa</th>
                        <th v-for="day in Array.from({ length: bulanaktif.jumlah_hari }, (_, index) => index + 1)"
                          :key="day">
                          {{ day }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="m in siswas.data" :key="m.id">
                        <td style="position: sticky; left: 0;  background-color:#fdfdfd;">{{ m.fullname }}</td>
                        <td v-for="day in Array.from({ length: bulanaktif.jumlah_hari }, (_, index) => index + 1)"
                          :key="day">

                          <select @change="handleStatusChange(m, $event, day)">
                            <option v-if="getNilai(m.id, day, bulanaktif.id)"
                              :value="getNilai(m.id, day, bulanaktif.id)" selected>
                              {{ getNilai(m.id, day, bulanaktif.id) }}

                              <!-- <span v-if="getNilai(m.id, day, bulanaktif.id) == 'I'">I</span>
                          <span v-if="getNilai(m.id, day, bulanaktif.id) == 'S'">S</span>
                          <span v-if="getNilai(m.id, day, bulanaktif.id) == 'A'">A</span> -->

                            </option>
                            <option value=""></option>
                            <option value="S">S</option>
                            <option value="I">I</option>
                            <option value="A">A</option>

                          </select>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>
<style>
.table-container {
  overflow: auto;
  max-height: 400px;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: #f5f5f5;
  /* Warna latar belakang header */
  z-index: 1;
}
</style>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import Swal from 'sweetalert2';
import { useRoute } from "vue-router";
import moment from 'moment';
export default {
  name: "kelasIndex",


  setup() {
    const store = useStore();
    const route = useRoute();
    const validation = ref([]);

    const kelas = reactive({
      classname: '',
      id_ta: '',
      wali_kelas: '',
      guru_pendamping: '',
      id_unitlevel: '',
      id_unit: '',


    });


    const formattedDate = ref('');

    const setFormattedDate = () => {
      moment.locale('id');
      const currentDate = moment();
      const formatted = currentDate.format('DD MMMM YYYY');
      formattedDate.value = formatted;
    };


    const data = reactive({
      // currentPage: 1,
      perPage: 100,
      search: '',
      raport: {
        file: '',
        id_studentactive: ''
      },
      selectedStatus: '',
      bulan: '',
      namabulan: '',
      // pages: "absenkelas-view"

    });
    // const router = useRouter();

    onMounted(async () => {
      await store.dispatch("siswa/getSiswaNilaiSikap", {
        page: 1,
        perPage: data.perPage,
        search: data.search,
        kelasId: route.params.id
      });

      await store.dispatch("kelas/getDetail", route.params.id);
      await store.dispatch("groupkategorisikap/getByUnitdanTa", route.params.id);
      await store.dispatch("nilaisikap/getData", route.params.id);
      await store.dispatch("absenkelas/getData", {
        id: route.params.id,
        bulanid: ''
      });
      await store.dispatch("keterangannilai/getAll");
      await store.dispatch("tahunajaran/getActive");
      await store.dispatch("global/getBulanAktif");
      setFormattedDate();
      // const permissions = localStorage.getItem('permission');

      // if (!permissions.includes(data.pages)) {
      //   router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      // }
    });

    const siswas = computed(() => {
      return store.getters["siswa/getSiswaNilaiSikap"];
    });

    const bulanaktif = computed(() => {
      return store.getters["global/getBulanAktif"];

    });

    const tas = computed(() => {
      return store.getters["tahunajaran/getActive"];
    });


    async function handleStatusChange(student, event, order) {
      try {
        const selectedValue = event.target.value;

        if (data.bulan === null) {
          // Set data.bulan to the current month
          const currentMonth = new Date().getMonth() + 1; // Adding 1 since months are zero-based
          data.bulan = currentMonth;
        }

        let formData = new FormData();
        formData.append('student_id', student.id)
        formData.append('id_ta', this.tas.id)
        formData.append('status', selectedValue)
        formData.append('kelas_id', route.params.id)
        formData.append('bulan_id', data.bulan)
        formData.append('tahun_ta', this.tas.start_year)
        formData.append('order', order)
        await store.dispatch('absenkelas/store', formData);
      } catch (error) {
        validation.value = error;

        Swal.fire({
          title: "GAGAL!",
          text: validation.value.error,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });

        console.error("An error occurred:", error);
      }
    }



    const absen = computed(() => {
      return store.getters["absenkelas/getData"];
    });

    const changeBulan = async (event) => {
      const selectedBulan = event.target.value;
    
      const monthNames = [
        'Januari', 'Februari', 'Maret', 'April',
        'Mei', 'Juni', 'Juli', 'Agustus',
        'September', 'Oktober', 'November', 'Desember'
      ];

      const selectedMonthName = monthNames[parseInt(selectedBulan) - 1];

      // Set the month name to the data property
      data.namabulan = selectedMonthName;
      // console.log(selectedBulan);

      data.bulan = selectedBulan
      await store.dispatch("absenkelas/getData", {
        id: route.params.id,
        bulanid: selectedBulan
      });

      const bulans = parseInt(selectedBulan);
    
      await store.dispatch("global/getBulanAktif",bulans);
    }

    const selectedStatus = ref(''); // Initialize with an empty string or any default value

    const getNilai = (idSiswa, order) => {

      const nilai = absen.value.find(item => item.student_id === idSiswa && item.order === order);

      return nilai ? nilai.status : '';
    };

    // const getNilai = (idSiswa, order) => {
    //   const nilai = absen.value.find(item => {
    //     return (
    //       item.student_id === idSiswa,
    //       item.order === order 
    //     );
    //   });

    //   return nilai ? nilai.status : '';
    // };


    watch(
      () => store.state.kelas.details,
      (newkelas) => {
        if (newkelas) {
          const {
            wali_kelas,
            master_kelas,
            id_ta,

            guru_pendamping

          } = newkelas;
          kelas.classname = master_kelas.classname;
          kelas.id_ta = id_ta;
          kelas.id_unit = master_kelas.unit_level.id_unit
          kelas.wali_kelas = wali_kelas ? wali_kelas.fullname : '';
          kelas.guru_pendamping = guru_pendamping ? guru_pendamping.fullname : '';
          kelas.id_unitlevel = master_kelas.id_unitlevel;

        }
      }
    );



    setNavPills();
    setTooltip(store.state.bootstrap);

    return {

      kelas,
      siswas,
      validation,
      absen,
      getNilai,
      data,
      bulanaktif,
      formattedDate,
      tas,
      handleStatusChange,
      selectedStatus,
      changeBulan

    };
  },
};
</script>