<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Tambah typay</h5>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="savetypay">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Name</label>
                        <input type="text" placeholder="Name" class="form-control" v-model="typay.name" />
                        <small class="text-danger" v-if="validation.name">{{
                  validation.name[0]
                }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Default</label>
                        <select class="form-control" v-model="typay.default">
                          <option value="yes">Yes</option>
                          <option value="no">No</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="">Action</label>
                        <select class="form-control" v-model="typay.action">
                          <option value="Registration">Registration</option>
                          <option value="Registration Pass Test">Registration Pass Test</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">
                        simpan
                      </button>
                      &nbsp;
                      <router-link :to="{ name: 'admin-typay' }" class="btn btn-danger btn-sm">kembali</router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";

export default {
  name: "typayIndex",
  components: {},
  data() {
    return {
      showtypay: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state
    const typay = reactive({
      name: "",
      default: "",
      action: "",
    });

    const data = reactive({

      pages: 'typay-create'

    });

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    //function register, fungsi ini di jalankan ketika form di submit
    function savetypay() {
      let formData = new FormData();
      formData.append("name", this.typay.name);
      formData.append("default", this.typay.default);
      formData.append("action", this.typay.action);

      formData.append("_method", "POST");
      //panggil actions "register" dari module "auth"
      store
        .dispatch("typay/store", formData)
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-typay" });
          Swal.fire({
            icon: "success",
            title: "Tambah data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          // //console.log(error);

          validation.value = error;
        });
    }

    onMounted(async () => {

      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    //return a state and function
    return {
      typay, // <-- state user
      validation, // <-- state validation
      savetypay,
      data
    };
  },
};
</script>