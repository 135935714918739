import Api from '../../api/Api'
const medicalinfo = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        datas: [],
    },

    //mutations
    mutations: {
        SET_DATA(state, datas) {
            state.datas = datas
        },
    },

    _actions: {

        updateWeight({ commit }, { medicalId, payload }) {
            //define callback promise
            return new Promise((resolve, reject) => {


                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post(`/medicalinfo/updateweight/${medicalId}`, payload)

                    .then(response => {

                        console.log(response)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        updateHeight({ commit }, { medicalId, payload }) {
            //define callback promise
            return new Promise((resolve, reject) => {


                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post(`/medicalinfo/updateheight/${medicalId}`, payload)

                    .then(response => {

                        console.log(response)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },
        updateLingkarKepala({ commit }, { medicalId, payload }) {
            //define callback promise
            return new Promise((resolve, reject) => {


                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post(`/medicalinfo/updatelingkarkepala/${medicalId}`, payload)

                    .then(response => {

                        console.log(response)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        updatefeedback({ commit }, { medicalId, payload }) {
            //define callback promise
            return new Promise((resolve, reject) => {


                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post(`/feedback/updatefeedback/${medicalId}`, payload)

                    .then(response => {

                        console.log(response)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        
        

        updatefeedbackBK({ commit }, { medicalId, payload }) {
            //define callback promise
            return new Promise((resolve, reject) => {


                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post(`/feedback/updatefeedbackBK/${medicalId}`, payload)

                    .then(response => {

                        console.log(response)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        getData({ commit }, payload) {


            //define variable token
            let page = payload ? payload.page : ''
            let idunit = payload ? payload.unit : ''
            let idunitlevel = payload ? payload.unitlevel : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let kelasid = payload ? payload.kelas : ''

            // console.log('kelas',kelasid)
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/medicalinfo?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&idunit=${idunit}&idunitlevel=${idunitlevel}&kelas=${kelasid}`)
                .then(response => {
                    // console.log('ini nilai', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('SET_DATA', response.data.data)

                })
        },

        getDataFeedback({ commit }, payload) {


            //define variable token
            let page = payload ? payload.page : ''
            let idunit = payload ? payload.unit : ''
            let idunitlevel = payload ? payload.unitlevel : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let kelasid = payload ? payload.kelas : ''
            let jenis_semester = payload ? payload.jenis_semester : ''

            // console.log('kelas',kelasid)
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/feedback?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&idunit=${idunit}&idunitlevel=${idunitlevel}&kelas=${kelasid}&jenis_semester=${jenis_semester}`)
                .then(response => {
                    // console.log('ini nilai', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('SET_DATA', response.data.data)

                })
        },

        getDataFeedbackBK({ commit }, payload) {


            //define variable token
            let page = payload ? payload.page : ''
            let idunit = payload ? payload.unit : ''
            let idunitlevel = payload ? payload.unitlevel : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let kelasid = payload ? payload.kelas : ''
            let jenis_semester = payload ? payload.jenis_semester : ''

            // console.log('kelas',kelasid)
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/feedback/komentarbk?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&idunit=${idunit}&idunitlevel=${idunitlevel}&kelas=${kelasid}&jenis_semester=${jenis_semester}`)
                .then(response => {
                    // console.log('ini nilai', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('SET_DATA', response.data.data)

                })
        },

        updateAkses({ commit }, { medicalId, payload }) {
            //define callback promise
            return new Promise((resolve, reject) => {


                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post(`/aksesakademik/updateAkses/${medicalId}`, payload)

                    .then(response => {

                        console.log(response)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
        getData(state) {
            return state.datas
        },
    }

}

export default medicalinfo