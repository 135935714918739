import Api from '../../api/Api'
const tinggalkelas = {

    //set namespace true
    namespaced: true,

    //state
    state: {
       
    },

    //mutations
    mutations: {
      
      
      
    },

    _actions: {
     

      
        store({ commit }, formData) {
            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/tinggalkelas', formData)

                    .then(response => {

                      
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        cancel({ commit }, formData) {
            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/tinggalkelas/cancel', formData)

                    .then(response => {

                      
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },


        
    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
     
    }

}

export default tinggalkelas