<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">

            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Data Naik Kelas</h5>

            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <h6>Siswa Aktif</h6>
                <div class="form-group">
                  <label for="">kelas</label>
                  <select class="form-control select2" v-model="data.unitlevel" @change="changeUnitlevel">
                    <option value="">-- select Unitlevel --</option>
                    <option v-for="u in unitlevels" :key="u.id" :value="u.id">
                      {{ u.name }}
                    </option>
                  </select>
                </div>

                <div class="table-responsive">
                  <table id="datatable-search" class="table table-flush small-table" style="font-size: 12px">
                    <thead class="thead-light">
                      <tr style="max-height: 5px">
                        <th>Nama Lengkap</th>
                        <th>Nama Kelas</th>
                        <th>#</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="m in pesertas.data" :key="m.id" style="height: 1px">
                        <td>{{ m.fullname }}</td>
                        <td>{{ m.classname }}</td>
                        <td>
                          <button @click="addSiswa(m.id)" class="btn btn-success btn-sm">
                            <i class="fa fa-plus"></i> Tambah
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <span>
                  <p>Menampilkan {{ pesertas.to }} dari {{ pesertas.total }}</p>
                </span>
                <nav aria-label="Pagination">
                  <ul class="pagination justify-content-end">
                    <li class="page-item" :class="{ disabled: pesertas.current_page === 1 }">
                      <a class="page-link" @click="changePage(pesertas.current_page - 1)" href="#"
                        aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li v-for="page in pesertas.links" :key="page"
                      :class="{ 'page-item': true, 'active': page.active === true }">
                      <a class="page-link" @click="changePage(page.label)" href="#"
                        v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{ page.label
                        }}</a>
                    </li>
                    <li class="page-item" :class="{ disabled: pesertas.current_page === pesertas.last_page }">
                      <a class="page-link" @click="changePage(pesertas.current_page + 1)" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div class="col-md-6">
                <h6>Kelas Baru</h6>
                <div class="form-group">
                  <label for="">kelas</label>
                  <select class="form-control select2" v-model="data.kelasnaik" @change="changeKelas">
                    <option value="">-- select Kelas --</option>
                    <option v-for="u in kelassemesterdepannaikkelas" :key="u.id" :value="u.id_classroom">
                      {{ u.classname }}
                    </option>
                  </select>
                </div>
                <div class="table-responsive">
                  <table id="datatable-search" class="table table-flush small-table" style="font-size: 12px">
                    <thead class="thead-light">
                      <tr style="max-height: 5px">
                        <th>Nama Lengkap</th>
                        <th>Nama Kelas</th>
                        <th>#</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="m in pesertasemesterdepan.data" :key="m.id" style="height: 1px">
                        <td>{{ m.fullname }}</td>
                        <td>{{ m.classname }}</td>
                        <td>
                          <button @click="cancelSiswa(m.id)" class="btn btn-danger btn-sm">
                            cancel
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import Swal from 'sweetalert2';
import { useRouter } from "vue-router";

export default {
  name: "pesertaIndex",


  setup() {
    const store = useStore();
    const data = reactive({
      // currentPage: 1,
      perPage: 10,
      search: '',
      unitlevel: '',
      kelasnaik: '',
      pages: 'naikkelas-view'

    });

    const validation = ref([]);

    const router = useRouter();

    onMounted(async () => {
      await store.dispatch("siswa/SiswaAktifSemesterActive");
      await store.dispatch("unitlevel/getNaikKelas");
      await store.dispatch("siswa/SiswaAktifSemesterDepan");
      await store.dispatch("kelas/getKelasSemesterDepanNaikKelas");
      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    const pesertas = computed(() => {
      return store.getters["siswa/SiswaAktifSemesterActive"];
    });

    const pesertasemesterdepan = computed(() => {
      return store.getters["siswa/SiswaAktifSemesterDepan"];
    });

    const unitlevels = computed(() => {
      return store.getters["unitlevel/getNaikKelas"];
    });

    const kelassemesterdepannaikkelas = computed(() => {
      return store.getters["kelas/getKelasSemesterDepanNaikKelas"];
    });

    const perPageOptions = [5, 10, 15, 25, 50, 100];


    const changeUnitlevel = async () => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("siswa/SiswaAktifSemesterActive", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        unitlevel: data.unitlevel
      });

      await store.dispatch("kelas/getKelasSemesterDepanNaikKelas", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        unitlevel: data.unitlevel
      });


    };

    const changeKelas = async () => {

      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("siswa/SiswaAktifSemesterDepan", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        kelas: data.kelasnaik
      });
    };



    const changePage = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("siswa/SiswaAktifSemesterActive", {
        page: page,
        perPage: jumlah,
        search: pencarian,
        unitlevel: data.unitlevel

      });
    };

    const changePerPage = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("siswa/SiswaAktifSemesterActive", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        unitlevel: data.unitlevel
      });
    };

    const searchData = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("siswa/SiswaAktifSemesterActive", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        kelas: data.kelasnaik
      });
      // Lakukan tindakan yang diinginkan dengan nilai searchQuery
    };

    async function addSiswa(id) {
      try {
        const pencarian = data.search;
        let jumlah = data.perPage;
        let formData = new FormData();

        formData.append('id_kelas', data.kelasnaik);
        formData.append('id_student', id);
        formData.append('id_unitlevel', data.unitlevel);

        //console.log('ini data add siswa', formData);

        // sending data to action "updateCategory" vuex
        await store.dispatch('naikkelas/store', formData);

        // If successful, show a success alert
        Swal.fire({
          title: "BERHASIL!",
          text: "Data Berhasil Ditambahkan!",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
        });

        // Redirect route "admin-categories"
        store.dispatch("siswa/SiswaAktifSemesterActive", {
          page: 1,
          perPage: jumlah,
          search: pencarian,
          unitlevel: data.unitlevel,
        });
        store.dispatch("siswa/SiswaAktifSemesterDepan", {
          page: 1,
          perPage: jumlah,
          search: pencarian,
          kelasnaik: data.kelasnaik
        });
      } catch (error) {
        // If there is an error, assign it to the "validation" state
        validation.value = error;
        //console.log('data',error.errors[0]);

        // Show an error alert using Swal
        Swal.fire({
          title: "GAGAL!",
          text: error.errors[0],
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }

    async function cancelSiswa(id) {
      const pencarian = data.search;
      let jumlah = data.perPage;
      let formData = new FormData();
      formData.append('id_kelas', this.kelasnaik)

      formData.append('id_student', id)
      formData.append('id_unitlevel', this.unitlevel)
      // alert(id);
      //console.log('ini data',formData);
      //sending data to action "updateCategory" vuex
      await store.dispatch('naikkelas/cancel', formData)

        //success
        .then(() => {
          //sweet alert
          Swal.fire({
            title: "BERHASIL!",
            text: "Data Berhasil Ditambahkan!",
            icon: "success",
            showConfirmButton: false,

            timer: 2000,
          });

          //redirect route "admin-categories"
          store.dispatch("siswa/SiswaAktifSemesterActive", {
            page: 1,
            perPage: jumlah,
            search: pencarian,
            unitlevel: data.unitlevel,
          });
          store.dispatch("siswa/SiswaAktifSemesterDepan", {
            page: 1,
            perPage: jumlah,
            search: pencarian,
            kelasnaik: data.kelasnaik
          });
        })

    }

    function confirmDelete(id) {
      Swal.fire({
        title: 'Konfirmasi',
        text: 'Apakah Anda yakin ingin menghapus data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Ya, hapus!',
        cancelButtonText: 'Batal',
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch('peserta/destroy', id)
            .then(() => {

              store.dispatch("siswa/SiswaAktifSemesterActive");
              // 
              // router.push({ name: "admin-peserta" });
              router.push({ name: "admin-peserta" });
              //alert
              Swal.fire({
                title: 'BERHASIL!',
                text: "Data Berhasil Dihapus!",
                icon: 'success',
                showConfirmButton: false,
                timer: 2000
              })



            })
        }
      });
    }


    setNavPills();
    setTooltip(store.state.bootstrap);

    return {
      pesertas,
      changePage,
      changePerPage,
      perPageOptions,
      data,
      searchData,
      confirmDelete,
      unitlevels,
      changeUnitlevel,
      addSiswa,
      cancelSiswa,
      pesertasemesterdepan,
      kelassemesterdepannaikkelas,
      changeKelas

    };
  },
};
</script>