<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header">
                        <div class="d-flex justify-content-between">
                            <h5 class="mb-0">Detail Beban Mengajar {{data.classroomactive}}</h5>
                            <button @click="goBack" class="btn btn-sm btn-primary">Kembali</button>

                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                              <p class="font-bold">
                                Kelas  : {{ kelas.classname }}
                                 <!-- Matapelajaran = {{details.matapelajaran.mapel.kode_mapel}} - {{details.matapelajaran.mapel.nama_mapel}} <br/> Pengajar = {{details.matapelajaran.instructur.fullname}} -->
                                </p>
                              <p class="font-bold">
                              Matapelajaran  : {{ data.nama_mapel }}
                               <!-- Matapelajaran = {{details.matapelajaran.mapel.kode_mapel}} - {{details.matapelajaran.mapel.nama_mapel}} <br/> Pengajar = {{details.matapelajaran.instructur.fullname}} -->
                              </p>
                            
                            </div>
                          </div>
                         <div class="row mt-5">
                            <div class="col-6">
                                <h6>List Siswa Aktif</h6>
                                <!-- <div class="row justify-content-between ">
                                    <div class="col-md-2">
                                      <div class="d-flex justify-content-between align-items-center">
                                        <span>Show: </span>
                                        <select v-model="data.perPage" class="form-control form-control-sm ml-2" @change="changePerPage">
                                          <option v-for="option in perPageOptions" :key="option" :value="option">{{ option }}</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div class="col-md-10 d-flex mb-3">
                                      <input type="text" class="form-control mr-2"  v-model="data.search" @keypress.enter="searchData" placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2">
                                      <button  @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2"> <i class="fa fa-search"></i> </button>
                                    </div>
                                  </div> -->
                                  <div class="table-responsive">
                                    <table
                                      id="datatable-search"
                                      class="table table-flush small-table"
                                      style="font-size: 12px"
                                    >
                                      <thead class="thead-light">
                                        <tr style="max-height: 5px">
                                          <th>Nis</th>
                                          <th>Fullname</th>
                                          <th>#</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        
                                        <tr v-for="m in dataaktif " :key="m.id" style="height: 1px">
                                          <td>{{ m.nis }}</td>
                                          <td>{{ m.fullname }}</td>
                                         
                                          <td>
                                           
                                            <button class="btn btn-danger btn-xs m-0" @click="destroy(m.id)"> <i class="fas fa-trash"></i> </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  <div class="card-footer d-flex justify-content-between">
                                    <span>
                                      <p>Menampilkan {{dataaktif.to}} dari {{dataaktif.total}}</p>
                                    </span>
                                    <!-- <nav aria-label="Pagination">
                                      <ul class="pagination justify-content-end">
                                        <li class="page-item" :class="{ disabled: dataaktif.current_page === 1 }">
                                          <a class="page-link" @click="changePage(dataaktif.current_page - 1)" href="#" aria-label="Previous">
                                            <span aria-hidden="true">&laquo;</span>
                                          </a>
                                        </li>
                                        <li v-for="page in dataaktif.links" :key="page"   :class="{ 'page-item': true, 'active': page.active === true }"
                                        >
                                          <a class="page-link" @click="changePage(page.label)" href="#" v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{ page.label }}</a>
                                        </li>
                                        <li class="page-item" :class="{ disabled: dataaktif.current_page === dataaktif.last_page }">
                                          <a class="page-link" @click="changePage(dataaktif.current_page + 1)" href="#" aria-label="Next">
                                            <span aria-hidden="true">&raquo;</span>
                                          </a>
                                        </li>
                                      </ul>
                                    </nav> -->
                                  </div>
                            </div>
                            <div class="col-6">
                              <h6>List Siswa NonAkif</h6>
                                <div class="table-responsive">
                                    <table
                                      id="datatable-search"
                                      class="table table-flush small-table"
                                      style="font-size: 12px"
                                    >
                                      <thead class="thead-light">
                                        
                                        <tr style="max-height: 5px">
                                          <th>Nis</th>
                                          <th>Fullname</th>
                                          <th>#</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr v-for="m in datanonaktif" :key="m.id" style="height: 1px">
                                            <td>{{m.nis}}</td>
                                            <td>{{m.fullname}}</td>
                                            
                                            <td>
                                                <button class="btn btn-danger btn-xs m-0" @click="destroy(m.id)"> <i class="fas fa-minus"></i> </button>
                                            </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                            </div> 
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
    
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import {  computed,ref, reactive, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';
// import {  useRouter } from "vue-router";

export default {
    name: "UserIndex",
    components: {},
    
    data() {
        return {
            showUser: false,
        };
    },

    mounted() {
        setNavPills();
        setTooltip(this.$store.state.bootstrap);
        // eslint-disable-next-line no-unused-vars
    },

    setup() {
        //user state
        const router = useRouter();
        const goBack = () => {
          router.go(-1);
        }
        const kelas = reactive({
            classname: '',
            id_ta: '',
            wali_kelas: '',
            guru_pendamping: '',
            id_unitlevel: '',
            idkelas:''
        });

        const data = reactive({
        // currentPage: 1,
            perPage:100,
            search:'',
            nama_mapel:'',
            classroomactive:''
            
        });

        const validation = ref([]);
        const store = useStore();
        const route = useRoute();

        onMounted(async () => {
            // await store.dispatch("kelas/getDetail", route.params.id);
            // await store.dispatch("bebanmengajar/getListData", route.params.id);
            // await store.dispatch("pengajar/getData");
            await store.dispatch("user/getDataPermission");
            await store.dispatch("bebanmengajar/getDetail", route.params.id);
            await store.dispatch("bebanmengajar/getDataSiswaAktifMapel", route.params.id);
            await store.dispatch("bebanmengajar/getDataSiswaNonAktifMapel", route.params.id);
    
        });

        const details = computed(() => {
            return store.getters["bebanmengajar/getDetail"];
        });

        const datapermissions = computed(() => {
            return store.getters["user/getDataPermission"];
        });
  
        const gurus = computed(() => {
            return store.getters["pengajar/getData"];
        });

        const list = computed(() => {
            return store.getters["bebanmengajar/getListData"];
        });

        const dataaktif = computed(() => {
          return store.getters["bebanmengajar/getDataSiswaAktifMapel"];
        });

        const datanonaktif = computed(() => {
          return store.getters["bebanmengajar/getDataSiswaNonAktifMapel"];
        });
  
        const perPageOptions = [5, 10, 15, 25, 50, 100];
    
    
        const changePage = async (page) => {
            let jumlah = data.perPage;
            const pencarian = data.search;
            await store.dispatch("bebanmengajar/getDataSiswaAktifMapel", {
            page:page,
            perPage:jumlah,
            search: pencarian
    
            });
        };
  
        const changePerPage = async () => {
            const pencarian = data.search;
            let jumlah = data.perPage;
        
            await store.dispatch("bebanmengajar/getDataSiswaAktifMapel", {
            page: 1,
            perPage:jumlah,
            search: pencarian
            });
        };
  
        const searchData = async () => {
            const pencarian = data.search;
            let jumlah = data.perPage;
            await store.dispatch("bebanmengajar/getDataSiswaAktifMapel", {
            page: 1,
            perPage:jumlah,
            search: pencarian
            });
        
    
        // Lakukan tindakan yang diinginkan dengan nilai searchQuery
        };
  
      async function destroy(id) {
          await store.dispatch('bebanmengajar/updateStatus', id)
            .then(() => {
                Swal.fire({
                    title: "BERHASIL!",
                    text: "Data Berhasil Pindahkan",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                });

                store.dispatch("bebanmengajar/getDataSiswaAktifMapel", route.params.id);
                store.dispatch("bebanmengajar/getDataSiswaNonAktifMapel", route.params.id);
                
            })
            .catch((error) => {
          // If there is an error, assign it to the "validation" state
                validation.value = error;
                // console.log('data',validation.value.error);

                // Show an error alert using Swal
                Swal.fire({
                    title: "GAGAL!",
                    text:   validation.value.error,
                    icon: "error",
                    showConfirmButton: false,
                    timer: 2000,
                });
            });
      }


        watch(
            () => store.state.kelas.details,
            (newkelas) => {
                if (newkelas) {
                    const {
                        master_kelas,
                        id_ta,
                        wali_kelas,
                        guru_pendamping

                    } = newkelas;
                    kelas.classname = master_kelas.classname;
                    kelas.id_ta = id_ta;
                    kelas.wali_kelas = wali_kelas ? wali_kelas.fullname : '';
                    kelas.guru_pendamping = guru_pendamping ? guru_pendamping.fullname : '';
                    kelas.id_unitlevel = master_kelas.id_unitlevel;
                    // console.log("Nilai kelas: ", kelas.classname);
                }
            }
        );

        watch(
            () => store.state.bebanmengajar.details,
            (newSiswa) => {
                if (newSiswa) {
                    const {
                       matapelajaran,
                       kelasaktif,
                       classroomactive_id

                    } = newSiswa;
                    data.nama_mapel = matapelajaran.mapel.nama_mapel;
                    kelas.classname = kelasaktif.master_kelas.classname;
                    data.classroomactive = classroomactive_id;
              
                }
            }
        );

        //return a state and function
        return {

            validation, 
            kelas,
            gurus,
            changePage,
            changePerPage,
            perPageOptions,
            data,
            searchData,
            datapermissions,
            list,
            details,
            dataaktif,
            datanonaktif,
            goBack,
            destroy

        };
    },
};
</script>