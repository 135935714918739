<template>
  <div>
    <div v-show="layout === 'landing'" class="landing-bg h-100 bg-gradient-primary position-fixed w-100"></div>
    <template v-if="showSidenav && $route.name !== 'login'">
     
      <sidenav />
    </template>
    <main class="main-content position-relative max-height-vh-100 h-100">
      <navbar v-if="showNavbar" />
      <router-view />
    </main>
  </div>
</template>

<script>
import Navbar from "@/examples/Navbars/Navbar.vue";
import { mapState } from "vuex";
import { computed, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "App",
  components: {
    Navbar,
  },
  computed: {
    ...mapState(["layout", "showSidenav", "showNavbar", "showConfig"]),
  },
  async mounted() {
    if (this.$route.name !== "login" && this.$route.name !== "admin-inputnilai") {
      const Sidenav = await import("./examples/Sidenav");
      this.$options.components.Sidenav = Sidenav.default;
    }
  },
  setup() {
    const store = useStore();



    //mounted 
    onMounted(async () => {
      //panggil action "getOrder" di module "order" vuex

      await store.dispatch('auth/isLoggedIn');

    });

    const isLoggedIn = computed(() => {

      //get getter "isLoggedIn" dari module "auth"
      return store.getters['auth/isLoggedIn']

    })

    const token = isLoggedIn.value;


    return {
      store,
      isLoggedIn,
      token
    };

  }
};
</script>
