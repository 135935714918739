import Api from '../../api/Api'
const importnis = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        datas: [],
      
    },

    //mutations
    mutations: {
      
        SET_DATA(state, datas) {
            state.datas = datas
        },

    },

    _actions: {
        store({ commit }, formData) {
            // //console.log('ini user', formData);

            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/importnis', formData)

                    .then(response => {

                        //console.log(response.data.data)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },
        
    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
       
    }

}

export default importnis