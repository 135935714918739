import Api from '../../api/Api'
const akhirkbtk = {

    //set namespace true
    namespaced: true,

    //state
    state: {
       
        akhirkbtk: {},
        history:{},
        detail:{},
        getExist:{},
      
    },

    //mutations
    mutations: {
       
        SET_ALL_DATA(state, akhirkbtk) {
            state.akhirkbtk = akhirkbtk
        },


        SET_HISTORY(state, history) {
            state.history = history
        },

        SET_DETAIL(state, detail) {
            state.detail = detail
        },

        SET_EXIST(state, getExist) {
            state.getExist = getExist
        },

     
      
    },

    _actions: {
      
      
        getData({ commit }) {
            // //console.log('ini parsing',payload);
         
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/settingapp`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_ALL_DATA', response.data.data)
                })
        },

        history({ commit },payload) {
            // console.log('ini history',payload);
         
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.post(`/get-penilaian-bulanan`,payload)
                .then(response => {
                    // console.log('ini history response ',response.data.data)
                    commit('SET_HISTORY', response.data.data)
                })
        },

        

        store({ commit }, payload) {
          
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')
                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`
                Api.post('/penilaian-bulanan', payload)

                    .then(response => {
                        commit('')
                        resolve(response)

                    }).catch(error => {
                        reject(error.response.data)

                    })

            })
        },

        update({ commit }, {id,payload}) {
          
            return new Promise((resolve, reject) => {
                // console.log("ini update narasi ",payload);
                const token = localStorage.getItem('token')
                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`
                Api.post(`/penilaian-bulanan/${id}`, payload)

                    .then(response => {
                        commit('')
                        resolve(response)

                    }).catch(error => {
                        reject(error.response.data)

                    })

            })
        },

        
        getExist({ commit },payload) {
            console.log('ini getExist',payload);
         
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.post(`/getExist`,payload)
                .then(response => {
                    // console.log('ini getExist response ',response.data.data)
                    commit('SET_EXIST', response.data.data)
                })
        },
        
    }, 
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
        getData(state) {
            return state.akhirkbtk
        },

        history(state) {
            return state.history
        },

        detail(state) {
            return state.detail
        },

        getExist(state) {
            return state.getExist
        },

      
    }

}

export default akhirkbtk