import Api from '../../api/Api'
const aturannilai = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        datas: [],
        details: {},
        aktif: {}
    },

    //mutations
    mutations: {

        SET_DATA(state, datas) {
            state.datas = datas
        },

        GET_DETAIL(state, details) {
            state.details = details
        },

        GET_AKTIF(state, aktif) {
            state.aktif = aktif
        },

    },

    _actions: {

        getDetail({ commit }, payload) {

            //console.log('ini payload',payload);
            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/aturannilai/edit/${payload}`)
                .then(response => {
                    //console.log('ini', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('GET_DETAIL', response.data.data)

                })
        },
        getData({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let unit = payload ? payload.unit : '';
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
              let tahunajaran = payload ? payload.tasId : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/aturannilai?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&unit=${unit}&tahunajaran=${tahunajaran}`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_DATA', response.data.data)
                })
        },

        getAktif({ commit }, payload) {

            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/aturannilai/aktif/${payload}`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('GET_AKTIF', response.data.data)
                })
        },

        destroy({ commit }, payload) {
            // Define variable token
            const token = localStorage.getItem('token')

            return new Promise((resolve, reject) => {
                Api.defaults.headers.common['Authorization'] = "Bearer " + token
                Api.get(`/aturannilai/delete/${payload}`)
                    .then(response => {
                        // Commit ke mutation SET_user
                        commit('SET_user', response.data.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.error(error.response.data)
                        reject(error.response.data)
                    })
            })
        },


        store({ commit }, formData) {
            //console.log('ini user', formData);

            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/aturannilai', formData)

                    .then(response => {

                        //console.log(response.data.data)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        update({ commit }, { sekolahId, payload }) {



            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`


                Api.post(`/aturannilai/update/${sekolahId}`, payload)

                    .then(response => {

                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },


    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {

        getData(state) {
            return state.datas
        },

        getDetai(state) {
            return state.details
        },

        getAktif(state) {
            return state.aktif
        }
    }

}

export default aturannilai