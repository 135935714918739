import Api from '../../api/Api'
const aturannilai = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        datas: [],
    },

    //mutations
    mutations: {
        SET_DATA(state, datas) {
            state.datas = datas
        },
    },

    _actions: {
      
    
        getData({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            let startdate = payload ? payload.startdate : '';
            let enddate = payload ? payload.enddate : '';
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/infreguser?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&startdate=${startdate}&enddate=${enddate}`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_DATA', response.data.data)
                })
        },


        
    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
       

       
        getData(state) {
            return state.datas
        },
    }

}

export default aturannilai