<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Tanggal Publikasi Laporan Bulanan KB-TK</h5>

              </div>
            </div>
  
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                              <div class="">
                                <b>Semester : {{ tahunajaran.desc }} {{ tahunajaran.status_tahun_ajaran }}</b>
                              </div>
                              <form @submit.prevent="savePublikasi($event)">
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label for="bulan">Bulan</label>
                                      <select class="form-select" name="bulan" v-if="getexist!=''">
                                        <option value="*">Pilih Bulan</option>
                                        <option
                                            v-for="eks in listbulan"
                                            :key="eks.id"
                                            :value="eks.id"
                                            :disabled="getexist && getexist.includes(eks.nama)"
                                            :style="{ color: getexist && getexist.includes(eks.nama) ? 'green' : 'black' }"
                                        >
                                          {{ eks.nama }} <span v-if="getexist && getexist.includes(eks.nama)" v-html="'&#x2713;'"></span>
                                        </option>
                                      </select>

                                      <select class="form-select" name="bulan" v-if="getexist==''">
                                        <option value="*">Pilih Bulan</option>
                                        <option
                                            v-for="eks in listbulan"
                                            :key="eks.id"
                                            :value="eks.id"
                                            :style="{ color: getexist && getexist.includes(eks.nama) ? 'green' : 'black' }"
                                        >
                                        {{ eks.nama }} <span v-if="getexist && getexist.includes(eks.nama)" v-html="'&#x2713;'"></span>
                                        </option>
                                      </select>

                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label for="tanggal_awal">Tanggal Titi Mangsa <small class="text-danger" v-if="validation.tgl_titi_mangsa">{{ validation.tgl_titi_mangsa[0] }}</small> </label>
                                      <input type="date" name="tgl_titi_mangsa" id="tgl_titi_mangsa" :value="publikasi ? publikasi.tgl_titi_mangsa:''" class="form-control">
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label for="tanggal_awal">Tanggal Awal Publikasi <small class="text-danger" v-if="validation.tanggal_awal">{{ validation.tanggal_awal[0] }}</small></label>
                                      <input type="datetime-local" name="tanggal_awal" id="tanggal_awal" :value="publikasi ? publikasi.tanggal_awal : ''" class="form-control">
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label for="tanggal_awal">Tanggal Akhir Publikasi<small class="text-danger" v-if="validation.tanggal_akhir">{{ validation.tanggal_akhir[0] }}</small> </label>
                                      <input type="datetime-local" name="tanggal_akhir" id="tanggal_akhir" :value="publikasi ? publikasi.tanggal_akhir:''" class="form-control">
                                    </div>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <button type="submit" class="btn btn-sm btn-success"
                                        name="simpansavePublikasi" id="simpansavePublikasi"
                                      >
                                        {{ isSubmitting ? 'Processing...' : 'Simpan' }}
                                      </button>
                                      &nbsp;
                                      <router-link to="/tanggal-publikasi-kbtk" class="btn btn-danger btn-sm">Kembali</router-link>
                                      
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { reactive, onMounted,  computed, ref } from "vue";
  import { useStore } from "vuex";
  import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2";
  
  export default {
    name: "KBEdit",
    components: {},
    data() {
      return {
        showsiswa: false,
        isSubmitting:false,
      };
    },
    
  
    mounted() {
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    },
  
    setup() {
      //siswa state

      const variable = reactive({
        id_unit: "",
        id_ta:"",
        id_student: "",
        bulan: "",
        tahun: "",
        elemen: "",
        narasi: "",
        foto1:"",
        foto2:"",
      });


     


      const siswa = computed(() => {
            return store.getters["siswa/getSiswaAktifin"];
      });



      //validation state

  
      //store vuex
      const store = useStore();

      const router = useRouter();

      const validation = ref([]);
  


    const route = useRoute();
  
      //function register, fungsi ini di jalankan ketika form di submit

      const getexist = computed(() => {
            return store.getters["narasikb/getExistPublikasi"];
      });
    
      const publikasi = computed(() => {
            return store.getters["narasikb/publikasi"];
      });


      const tahunajaran = computed(() => {
            return store.getters["tahunajaran/getActive"];
      });

      const datapermissions = computed(() => {
        return store.getters["user/getDataPermission"];
      });

      const listbulan = computed(() => {
            return store.getters["global/getBulanSemester"];
      });

      const data = reactive({
        // currentPage: 1,
        perPage:100,
        search:'',
        raport: {
          file: '',
          id_studentactive:''
        },
        pages: "tanggalpublikasikbtk-create",
        
      });

      onMounted(async function() {
          await store.dispatch("user/getDataPermission");
          await store.dispatch("narasikb/getExistPublikasi");
          await store.dispatch("global/getBulanSemester");
          await store.dispatch("narasikb/publikasi", route.params.id ); // Gunakan this di sini
          await store.dispatch("tahunajaran/getActive");

          const permissions = localStorage.getItem("permission");
          if (!permissions.includes(data.pages)) {
            router.push("/error"); // Ganti '/error-page' dengan rute halaman error Anda
          }
      });
  
      // watch(
      //   () => store.state.reg.pesertadetail,
      //   (newsiswa) => {
      //     //console.log('newsiswa',newsiswa);
      //     if (newsiswa) {
      //       const {
      //           fullname,
      //           nickname,
      //           gender,
      //           birthplace,
      //           birthdate,
      //           tingkatan,
      //           sekolah,
      //           id_unitlevel,
      //           id,
      //       } = newsiswa;
      //       siswa.fullname = fullname;
      //       siswa.nickname = nickname;
      //       siswa.gender = gender;
      //       siswa.tempatlahir = birthplace;
      //       siswa.tanggallahir = birthdate;
      //       siswa.tingkatan = tingkatan;
      //       siswa.sekolah = sekolah;
      //       siswa.id_unitlevel = id_unitlevel;
      //       // siswa.id = id;
      //       siswa.id = id;
            
            
            
      //     }
      //   }
      // );

      

      

      async function savePublikasi(event) {
         // Simpan referensi konteks Vue
         const self = this;

        // Mengatur properti isSubmitting menjadi true saat tombol diklik
        self.isSubmitting = true;

        // alert(self.isSubmitting);
        // Tambahkan sweet alert untuk konfirmasi
        const confirmation = await Swal.fire({
            title: 'Apakah Anda yakin ingin menyimpan data ini?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal'
        });

        // Lanjutkan menyimpan data jika pengguna mengonfirmasi
        if (confirmation.isConfirmed) {
            let formData = new FormData();
            formData.append('id_ta', this.tahunajaran.id);
            formData.append('id_laporan_bulanan_kbtk', 0);
            formData.append('tanggal_awal', event.target.tanggal_awal.value);
            formData.append('tanggal_akhir', event.target.tanggal_akhir.value);
            formData.append('tgl_titi_mangsa', event.target.tgl_titi_mangsa.value);
            formData.append('bulan', event.target.bulan.value);
            formData.append("_method", "POST");
            console.log(formData);
            store
                .dispatch("narasikb/publikasi_store", formData)
                .then(() => {
                  self.isSubmitting = false;
                  router.push({ name: 'admin-tanggalpublikasikbtk'});
                    Swal.fire({
                        icon: "success",
                        title: "Simpan data berhasil",
                    });
                })
                .catch((error) => {
                    validation.value = error;
                });
        }
    }

      

      //return a state and function
      return {
        router,
        route,
        getexist,
        siswa, // <--
        variable,
        data,
        validation,
        tahunajaran,
        publikasi,
        savePublikasi,
        datapermissions,
        listbulan
      };
    },
  };
  </script>