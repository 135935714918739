<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Laporan Perkembangan Bulanan</h5>

              </div>
            </div>
  
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                 
                  

                    <div class="row">
                        <div class="col-md-4 col-lg-4">
                            <div class="row">
                                <div class="col-12">
                                    <h5>{{ siswa.data ? siswa.data[0].fullname : 'loading...' }}</h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                        <!-- <table style="border: 1px;">
                                          <tr style="border:none">
                                            <td >Nama Lengkap</td>
                                            <td >:</td>
                                            <td>{{siswa.fullname}}</td>
                                        </tr>
                                        </table> -->                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <form @submit.prevent="saveLaporan">
                      <div class="row m-3 table-responsive">
                        <div class="col-md-12">
                          <div class="form-group">
                            <!-- <label for="">Perkembangan</label> -->
                            <table border="1" style="table-layout: fixed; width: 100%;border-collapse: separate;
                            border: 1px solid grey;
                            border-spacing: 0px;
                            ">
                              <thead>
                                <tr style="text-align:center">
                                  <th style="width: 10%;">Semester</th>
                                  <th style="width: 10%;">Bulan</th>
                                  <th style="width: 10%;">Jenis Laporan</th>
                                  <th style="width: 50%;">Komentar</th>
                                  <th style="width: 15%;">Foto</th>
                                  <!-- <th>Foto 2</th> -->
                                  <th style="width: 5%;"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="m in history.data" :key="m.id" style="height: 1px">
                                  <td align="center" valign="top">&nbsp;{{ m.tas.desc }} </td>
                                  <td align="center" valign="top">&nbsp;{{ m.bulans.nama }}</td>
                                  <td align="center" valign="top">&nbsp;{{ m.elemen }} </td>
                                  <td align="center" valign="top" style="word-wrap: break-word !important;padding: 7px;">
                                    <div style="word-wrap: break-word !important;text-align: justify;">
                                      {{ limitText(m.narasi, 1000) }}
                                    </div>
                                  </td>
                                  <td align="center">
                                    <a v-if="m.foto1 != null " :href="Api.defaults.image+'/public/report/' + m.foto1" target="_blank">
                                      <!-- <i class="fas fa-image"></i> -->
                                      <div style="width: 100px;max-height:2cm">
                                        <img :src="Api.defaults.image+'/public/report/' + m.foto1" alt=""  class="image img-thumbnail img-rounded" style="height:2cm;width:auto">
                                      </div>
                                    </a>
                                    <br />
                                    <a v-if="m.foto2 != null "  :href="Api.defaults.image+'/public/report/' + m.foto2" target="_blank">
                                      <!-- <i class="fas fa-image"></i> -->
                                      <div style="width: 100px;max-height:2cm">
                                        <img :src="Api.defaults.image+'/public/report/' + m.foto2" alt="" class="image img-thumbnail img-rounded" style="height:2cm;width:auto">
                                      </div>
                                    </a>                                  
                                  </td>                                  
                                  <td align="center">
                                    
                                    <router-link v-if="$route.params.bidang.toLowerCase() === m.elemen.toLowerCase()" :to="{name: 'admin-laporan-bulanan-kb-edit', params:{idsiswa:m.id_student,id: m.id,instructor:this.$route.params.instructor},query: { kelass: $route.query.kelass }}" > <i class="fas fa-edit"></i></router-link>
                                    <!-- <router-link v-if="$route.params.bidang.toLowerCase() === m.elemen.toLowerCase()" :to="{name: 'admin-laporan-bulanan-kb-print', params:{id: m.id } }" class="btn btn-success btn-xs m-0 me-2"> <i class="fas fa-print"></i></router-link> -->
                                    <button @click="hapus(m.id)" class="btn btn-xs btn-danger"><i class="fa fa-trash"></i></button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                                      <div class="col-12">
                                        
                                        <router-link
                                        :to="{ name: 'admin-laporan-bulanan-kb' , params:{id:this.$route.params.instructor },query: { kelass: $route.query.kelass } }"
                                          class="btn btn-danger btn-sm"
                                          >kembali</router-link
                                        >
                                      </div>
                                    </div>
                    </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { reactive, onMounted, computed, ref } from "vue";
  import { useStore } from "vuex";
  import { useRoute } from "vue-router";
import Swal from "sweetalert2";
import Api from "../../../api/Api";
  
  export default {
    name: "KBEdit",
    components: {},
    data() {
      return {
        showsiswa: false,
      };
    },
    methods: {
      limitText(text, limit) {
        if (text.length > limit) {
          return text.substring(0, limit) + '...';
        }
        return text;
      },
    },
  
    mounted() {
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    },
  
    setup() {
      //siswa state
      

      const variable = reactive({
        id_unit: "",
        id_ta:"",
        id_student: "",
        bulan: "",
        tahun: "",
        elemen: "",
        narasi: "",
        foto1:"",
        foto2:"",
      });


      const listbulan = computed(() => {
            return store.getters["global/getBulan"];
      });


      const tahunajaran = computed(() => {
            return store.getters["tahunajaran/getActive"];
      });

      const history = computed(() => {
        return store.getters["narasikb/history"];
      });


      //validation state

  
      //store vuex
      const store = useStore();

      

      const validation = ref([]);
  


    const route = useRoute();

    
  
      //function register, fungsi ini di jalankan ketika form di submit
    
      const siswa = computed(() => {
            return store.getters["siswa/getSiswaAktifin"];
      });

      
  
      

      const data = reactive({
        // currentPage: 1,
        perPage:100,
        search:'',
        raport: {
          file: '',
          id_studentactive:''
        },
        
      });

      async function handleFileChange(e) {
        let image = (this.variable.foto1 = e.target.files[0]);
        console.log(image)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto1 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }

      async function handleFileChange2(e) {
        let image = (this.variable.foto2 = e.target.files[0]);
        console.log(image)
        if (!image.type.match("image.*")) {
          e.target.value = "";
          this.variable.foto2 = null;
          Swal.fire({
            title: "OOPS!",
            text: "Format File Tidak Didukung!",
            icon: "error",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      }
      

     
      
      onMounted(async () => {
        // await store.dispatch("reg/getDetailSiswa", route.params.id);
        await store.dispatch("siswa/getSiswaAktifin", {
          page:1,
          perPage:10,
          search: route.params.id,
          kelas:'',
          unit:'',
          unitlevel:'',
          guru:route.params.instructor
  
        });
        await store.dispatch("global/getBulan");
        await store.dispatch("tahunajaran/getActive");
        const tahunajaran = tahunajaran;
        await store.dispatch("narasikb/history",{id_student:route.params.id,elemen:route.params.bidang});
      });


      async function hapus(e) {
        
        var c = confirm("apakah yakin akan menghapus ?");
        if(c==true){
          store
          .dispatch("narasikb/hapus", {
            id: e,
          })
          .then(() => {
            //redirect ke dashboard
            Swal.fire({
              icon: "success",
              title: "Hapus data berhasil",
            });
            setTimeout(() => {
              location.reload();
            }, 2000); // 2000ms = 2 detik
            // toast.success("Tambah data berhasil")
          })
          .catch((error) => {
            //show validaation message
            //console.log(error);
  
            validation.value = error;
          });
        }
      }


      //return a state and function
      return {
        siswa, // <--
        listbulan,
        variable,
        handleFileChange,
        handleFileChange2,
        data,
        validation,
        tahunajaran,
        history,
        Api,
        hapus
      };
    },
  };
  </script>
  <style scoped>
  table,th,td {
    border-width:1px !important;
  }
</style>