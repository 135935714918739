<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Tambah tahunajaran</h5>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="savetahunajaran">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Kode tahun ajaran</label>
                        <input type="number" placeholder="kode" class="form-control" v-model="tahunajaran.code" />
                        <small class="text-danger" v-if="validation.code">{{ validation.code[0] }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Nama tahun ajaran</label>
                        <input type="text" placeholder="name" class="form-control" v-model="tahunajaran.name" />
                        <small class="text-danger" v-if="validation.name">{{ validation.name[0] }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Deskripsi tahun ajaran</label>
                        <input type="text" placeholder="name" class="form-control" v-model="tahunajaran.desc" />
                        <small class="text-danger" v-if="validation.desc">{{ validation.desc[0] }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tahun awal</label>
                        <input type="number" name="year" min="2020" max="2100" step="1" placeholder="tahun awal"
                          class="form-control" v-model="tahunajaran.start_year" />
                        <small class="text-danger" v-if="validation.start_year">{{ validation.start_year[0] }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tahun akhir</label>
                        <input placeholder="tahun akhir" class="form-control" type="number" name="year" min="2020"
                          max="2100" step="1" v-model="tahunajaran.end_year" />
                        <small class="text-danger" v-if="validation.end_year">{{ validation.end_year[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">jenis tahun ajaran <span
                            class="text-danger">*</span></label>
                        <select class="form-control" v-model="tahunajaran.status_tahun_ajaran">
                          <option value="">-- select jenis tahun ajaran--</option>
                          <option value="ganjil">ganjil</option>
                          <option value="genap">genap</option>
                        </select>
                        <small class="text-danger" v-if="validation.status_tahun_ajaran">{{
                  validation.status_tahun_ajaran[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tanggal Awal Tahun Ajaran</label>
                        <input type="date" placeholder="name" class="form-control" v-model="tahunajaran.start_date" />
                        <small class="text-danger" v-if="validation.start_date">{{ validation.name[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tanggal Akhir Tahun Ajaran</label>
                        <input type="date" placeholder="name" class="form-control" v-model="tahunajaran.end_date" />
                        <small class="text-danger" v-if="validation.end_date">{{ validation.end_date[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">
                        simpan
                      </button>
                      &nbsp;
                      <router-link :to="{ name: 'admin-tahunajaran' }"
                        class="btn btn-danger btn-sm">kembali</router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2";

export default {
  name: "tahunajaranIndex",
  components: {},
  data() {
    return {
      showtahunajaran: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state
    const tahunajaran = reactive({
      name: '',
      code: '',
      desc: '',
      start_year: '',
      end_year: '',
      start_date: '',
      end_date: '',
      status_tahun_ajaran: ''
    });

    const data = reactive({
      pages: 'tahunajaran-create'
    })

    onMounted(async () => {

      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    //function register, fungsi ini di jalankan ketika form di submit
    function savetahunajaran() {
      let formData = new FormData();
      formData.append('name', this.tahunajaran.name)
      formData.append('code', this.tahunajaran.code)
      formData.append('desc', this.tahunajaran.desc)
      formData.append('start_year', this.tahunajaran.start_year)
      formData.append('end_year', this.tahunajaran.end_year)
      formData.append('status_tahun_ajaran', this.tahunajaran.status_tahun_ajaran)
      formData.append('start_date', this.tahunajaran.start_date)
      formData.append('end_date', this.tahunajaran.end_date)

      formData.append("_method", "POST");
      //panggil actions "register" dari module "auth"
      store
        .dispatch("tahunajaran/store", formData)
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-tahunajaran" });
          Swal.fire({
            icon: "success",
            title: "Tambah data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          // //console.log(error);

          validation.value = error;
        });
    }

    //return a state and function
    return {
      tahunajaran, // <-- state user
      validation, // <-- state validation
      savetahunajaran,
      data
    };
  },
};
</script>