import Api from '../../api/Api'
const nilaip5 = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        datas: [],
    },

    //mutations
    mutations: {
        SET_DATA(state, datas) {
            state.datas = datas
        },
    },

    _actions: {

        store({ commit }, formData) {
         

            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/nilaip5', formData)

                    .then(response => {

                        console.log(response)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },
    
        getData({ commit }, payload) {

          
            let id = payload ? payload.id : '';
            let idsiswa = payload ? payload.id_siswa : '';

            const token = localStorage.getItem('token')
          
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/nilaip5?id=${id}&idsiswa=${idsiswa}`)
                .then(response => {
                    // console.log('ini nilai', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('SET_DATA', response.data.data)

                })
        },
        
    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
        getData(state) {
            return state.datas
        },
    }

}

export default nilaip5