<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Edit Relasi Keluarga</h5>

            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="updateRelasiKeluarga">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Deskripsi</label>
                        <input type="text" placeholder="Deskripsi" class="form-control" v-model="relasi.desc" />
                        <small class="text-danger" v-if="validation.desc">{{ validation.desc[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Type</label>
                        <select class="form-control" v-model="relasi.type">
                          <option value="Father">Father</option>
                          <option value="Mother">Mother</option>
                          <option value="Other">Other</option>
                        </select>

                        <small class="text-danger" v-if="validation.type">{{ validation.type[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Sort</label>
                        <input type="text" placeholder="sort" class="form-control" v-model="relasi.sort" />
                        <small class="text-danger" v-if="validation.sort">{{ validation.sort[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">
                        simpan
                      </button>
                      &nbsp;
                      <router-link :to="{ name: 'admin-relasikeluarga' }"
                        class="btn btn-danger btn-sm">kembali</router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";

export default {
  name: "UserIndex",
  components: {},
  data() {
    return {
      showUser: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state
    const relasi = reactive({
      desc: '',
      type: '',
      sort: '',

    });

    const data = reactive({
      pages: 'relasikeluarga-edit'
    });

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    const route = useRoute();

    //function register, fungsi ini di jalankan ketika form di submit
    function updateRelasiKeluarga() {
      //define variable

      let formData = new FormData();


      formData.append('desc', this.relasi.desc)
      formData.append('type', this.relasi.type)
      formData.append('sort', this.relasi.sort)

      formData.append("_method", "POST");

      //panggil actions "register" dari module "auth"
      store
        .dispatch("relasikeluarga/update", {
          relasikeluargaId: route.params.id,
          payload: formData,
        })
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-relasikeluarga" });
          Swal.fire({
            icon: "success",
            title: "Update data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });
    }



    onMounted(async () => {
      await store.dispatch("relasikeluarga/getDetail", route.params.id);
      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    watch(
      () => store.state.relasikeluarga.details,
      (newRelasiKeluarga) => {
        ////console.log('newuser',newrelasikeluarga);
        if (newRelasiKeluarga) {
          const {
            desc,
            sort,
            type

          } = newRelasiKeluarga;

          relasi.desc = desc;
          relasi.type = type;
          relasi.sort = sort;

        }
      }
    );


    //return a state and function
    return {
      relasi, // <-- state user
      validation, // <-- state validation
      updateRelasiKeluarga

    };
  },
};
</script>