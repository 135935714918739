<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Edit Profile</h5>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="updateProfile">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Nama Lengkap</label>
                        <input type="text" placeholder="Nama Lengkap" class="form-control" v-model="profile.nama_lengkap" />
                        <small class="text-danger" v-if="validation.nama_lengkap">{{ validation.nama_lengkap[0] }}</small>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="col-12">
                      <img :src="profile.photo" alt="" />
                      <div class="form-group">
                        <label for="">Photo Profile</label>
                        <input type="file" @change="handleFilePhotoChange" class="form-control" />
                      </div>
                    </div>
                  </div> -->

                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-md">simpan</button>
                      &nbsp;
                      <!-- <nuxt-link :to="{ name: 'admin-role' }" class="btn btn-danger btn-md">kembali</nuxt-link> -->
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";

export default {
  name: "MenuIndex",
  components: {},
  data() {
    return {
      showMenu: false,
      // pages: "profile-edit",
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);

    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state
    const profile = reactive({
      nama_lengkap: "",
      photo: "",
    });

   


    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    const route = useRoute();

    function handleFilePhotoChange(e) {
      //get image
      const image = (profile.photo = e.target.files[0]);

      //check fileType
      if (!image.type.match("image.*")) {
        //if fileType not allowed, then clear value and set null
        e.target.value = "";

        //set state "category.image" to null
        profile.photo = null;

        //show sweet alert

        Swal.fire({
          title: "OOPS!",
          text: "Format File Tidak Didukung!",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }

    //function register, fungsi ini di jalankan ketika form di submit
    function updateProfile() {
      //define variable

      let formData = new FormData();

      formData.append("nama_lengkap", profile.nama_lengkap);
      formData.append("photo", profile.photo);
      formData.append("_method", "POST");

      //panggil actions "register" dari module "auth"
      store
        .dispatch("user/updateProfile", {
          menuId: route.params.id,
          payload: formData,
        })
        .then(() => {
          //redirect ke dashboard
          router.push({ name: 'admin-change-profile', params: { id: route.params.id } });
          Swal.fire({
            icon: "success",
            title: "Update data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });
    }

    onMounted(async () => {
      await store.dispatch("user/getDetailuser", route.params.id);
   
    });

    watch(
      () => store.state.user.detailuser,
      (newUser) => {
        // //console.log('newuser',newUser);
        if (newUser) {
          const { pegawai } = newUser;

          profile.nama_lengkap = pegawai ? pegawai.nama_lengkap : "";
          profile.photo = pegawai ? pegawai.photo : "";
        }
      }
    );

    //computed

    //return a state and function
    return {
      profile, // <-- state user
      validation, // <-- state validation
      updateProfile,
      handleFilePhotoChange,
    };
  },
};
</script>
