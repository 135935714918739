import Api from '../../api/Api'
const groupkategorisikap = {

    //set namespace true
    namespaced: true,

    //state
    state: {
        datas: [],
        datall: [],
        get: [],
        details: {},
        bykelas: [],
        byunitta: []
    },

    //mutations
    mutations: {

        SET_DATA(state, datas) {
            state.datas = datas
        },

        SET_ALL(state, dataall) {
            state.dataall = dataall
        },

        GET_DETAIL(state, details) {
            state.details = details
        },

        GET_BYKELAS(state, bykelas) {
            state.bykelas = bykelas
        },

        GET_BYUNITTA(state, byunitta) {
            state.byunitta = byunitta
        },

        GET_DATA(state, get) {
            state.get = get
        },


    },

    _actions: {

        getDetail({ commit }, payload) {

            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/groupkategorisikap/edit/${payload}`)
                .then(response => {
                    //console.log('ini', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('GET_DETAIL', response.data.data)

                })
        },

        getGroup({ commit }, payload) {


            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/groupkategorisikap/bebanmengajar?id=${payload}`)
                .then(response => {
                    console.log('ini kategorieess', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('GET_BYKELAS', response.data.data)

                })
        },



        getData({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let unit = payload ? payload.unit : '';
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
             let tahunajaran = payload ? payload.tasId : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/groupkategorisikap?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&unit=${unit}&tahunajaran=${tahunajaran}`)
                .then(response => {
                    // console.log(response)
                    commit('SET_DATA', response.data.data)
                })
        },

        get({ commit }) {

            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/groupkategorisikap/get`)
                .then(response => {
                    // console.log(response)
                    commit('GET_DATA', response.data.data)
                })
        },

        getByUnitdanTa({ commit }, payload) {


            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/groupkategorisikap/getbyunitdanta?id=${payload}`)
                .then(response => {

                    commit('GET_BYUNITTA', response.data.data)
                })
        },

        getAll({ commit }, payload) {
            let units = payload ? payload.unit : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/groupkategorisikap/all?idunit=${units}`)
                .then(response => {

                    commit('SET_ALL', response.data.data)
                })
        },


        store({ commit }, formData) {
            //console.log('ini user', formData);

            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                Api.post('/groupkategorisikap', formData)

                    .then(response => {

                        //console.log(response.data.data)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        update({ commit }, { groupkategorisikapId, payload }) {

            console.log('sdsd', groupkategorisikapId)

            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`


                Api.post(`/groupkategorisikap/update/${groupkategorisikapId}`, payload)

                    .then(response => {

                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        destroy({ commit }, payload) {
            // Define variable token
            const token = localStorage.getItem('token')

            return new Promise((resolve, reject) => {
                Api.defaults.headers.common['Authorization'] = "Bearer " + token
                Api.get(`/groupkategorisikap/delete/${payload}`)
                    .then(response => {
                        // Commit ke mutation SET_user
                        commit('SET_user', response.data.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.error(error.response.data)
                        reject(error.response.data)
                    })
            })
        },


    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {



        getData(state) {
            return state.datas
        },


        get(state) {
            return state.get
        },

        getDetail(state) {
            return state.details
        },

        getAll(state) {
            return state.dataall
        },

        getGroup(state) {
            return state.bykelas
        },

        getByUnitdanTa(state) {
            return state.byunitta
        }
    }

}

export default groupkategorisikap