import Api from '../../api/Api'
const settingapp = {

    //set namespace true
    namespaced: true,

    //state
    state: {
       
        settingapps: {},
      
    },

    //mutations
    mutations: {
       
        SET_ALL_DATA(state, settingapps) {
            state.settingapps = settingapps
        },

     
      
    },

    _actions: {
      
      
        getData({ commit }) {
            // //console.log('ini parsing',payload);
         
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/settingapp`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_ALL_DATA', response.data.data)
                })
        },

        store({ commit }, payload) {
          
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`
                Api.post('/settingapp/update', payload)

                    .then(response => {
                        commit('')
                        resolve(response)

                    }).catch(error => {
                        reject(error.response.data)

                    })

            })
        },
        
    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
        getData(state) {
            return state.settingapps
        },

      
    }

}

export default settingapp