import Api from '../../api/Api'
const pindahsekolah = {

    //set namespace true
    namespaced: true,

    //state
    state: {
       dataall:[],
    },

    //mutations
    mutations: {
        SET_ALL(state, dataall) {
            state.dataall = dataall
        },
    },

    _actions: {
        getAll({ commit }) {
            // //console.log('ini parsing',payload);
         
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/ppdb`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_ALL', response.data.data)
                })
        },

        getTkAll({ commit }) {
            // //console.log('ini parsing',payload);
         
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/ppdb/tk`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_ALL', response.data.data)
                })
        },

        getSdAll({ commit }) {
            // //console.log('ini parsing',payload);
         
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/ppdb/sd`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_ALL', response.data.data)
                })
        },

        getSmpAll({ commit }) {
            // //console.log('ini parsing',payload);
         
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/ppdb/smp`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_ALL', response.data.data)
                })
        },

        getSmaAll({ commit }) {
            // //console.log('ini parsing',payload);
         
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/ppdb/sma`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_ALL', response.data.data)
                })
        },
    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
        getAll(state){
            return state.dataall
        },

        getTkAll(state){
            return state.dataall
        },

        getSdAll(state){
            return state.dataall
        },

        getSmpAll(state){
            return state.dataall
        },

        getSmaAll(state){
            return state.dataall
        }
    }

}

export default pindahsekolah