import Api from '../../api/Api'
const pindahsekolah = {

    //set namespace true
    namespaced: true,

    //state
    state: {
       
    },

    //mutations
    mutations: {
      
      
      
    },

    _actions: {
     

      
        destroy({ commit }, payload) {
            // Define variable token
            const token = localStorage.getItem('token')

            return new Promise((resolve, reject) => {
                Api.defaults.headers.common['Authorization'] = "Bearer " + token
                Api.delete(`/pindahsekolah/${payload}`)
                    .then(response => {
                        // Commit ke mutation SET_user
                        commit('')
                        resolve(response)
                    })
                    .catch(error => {
                        console.error(error.response.data)
                        reject(error.response.data)
                    })
            })
        },


        
    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
     
    }

}

export default pindahsekolah