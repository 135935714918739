import Api from '../../api/Api'
const jadwalpelaksanaan = {

    //set namespace true
    namespaced: true,

    //state
    state: {
       
        jadwalpelaksanaans: [],
        jadwaldetail:{}
    },

    //mutations
    mutations: {
       
        SET_ALL_DATA(state, jadwalpelaksanaans) {
            state.jadwalpelaksanaans = jadwalpelaksanaans
        },

        SET_DETAIL_DATA(state, payload) {
    
            //set value state "role"
            state.jadwaldetail = payload
        },
      
    },

    _actions: {

        getData({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let taId = payload ? payload.tasId : '';
            let status = payload ? payload.status : '';
            let unit = payload ? payload.unit : '';
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/ppdb/data?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}&tahunajaran=${taId}&unit=${unit}&status=${status}`)
                .then(response => {
                    // console.log('ini jadwal pelaksanaan',response.data.data)
                    commit('SET_ALL_DATA', response.data.data)
                })
        },

        store({ commit }, payload) {
          
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

              
                Api.post('/ppdb/data', payload)

                    .then(response => {

                        // //console.log(response.data.data)
                        // //console.log(response.data.data)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        getDetail({ commit }, payload) {

            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/ppdb/edit/${payload}`)
                .then(response => {

                     //console.log('ini', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('SET_DETAIL_DATA', response.data.data)

                })

        },


        update({ commit }, { jadwalId, payload }) {
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

               
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

             
                Api.post(`/ppdb/update/${jadwalId}`, payload)

                    .then(response => {

                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        destroy({ commit }, payload) {
            // Define variable token
            const token = localStorage.getItem('token')

            return new Promise((resolve, reject) => {
                Api.defaults.headers.common['Authorization'] = "Bearer " + token
                Api.get(`/ppdb/delete/${payload}`)
                    .then(response => {
                        // Commit ke mutation SET_MENU
                        commit('SET_ALL_DATA', response.data.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.error(error.response.data)
                        reject(error.response.data)
                    })
            })
        },


        
    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
        getData(state) {
            return state.jadwalpelaksanaans
        },

      

        getDetail(state){
            return state.jadwaldetail
        }
    }

}

export default jadwalpelaksanaan