<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">

            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Input Nilai Sikap </h5>
              <router-link to="/perwalian" class="btn btn-primary btn-sm">Kembali</router-link>
            </div>
            <div class="row">
              <dl class="row">
                <dt class="col-sm-2">Kelas</dt>
                <dd class="col-sm-10">{{ kelas.classname }}</dd>

                <dt class="col-sm-2">Walikelas</dt>
                <dd class="col-sm-10">{{ kelas.wali_kelas }}</dd>
              </dl>

            </div>
          </div>
          <div class="card-body">
            <!-- <div class="row justify-content-between ">
              <div class="col-md-1">
                <div class="d-flex justify-content-between align-items-center">
                  <span>Show: </span>
                  <select v-model="data.perPage" class="form-control form-control-sm ml-2" @change="changePerPage">
                    <option v-for="option in perPageOptions" :key="option" :value="option">{{ option }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 d-flex mb-3">
                <input type="text" class="form-control mr-2" v-model="data.search" @keypress.enter="searchData"
                  placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2">
                <button @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2"> <i
                    class="fa fa-search"></i> </button>
              </div>
            </div> -->


            <div class="accordion" id="accordionExample">
              <div class="accordion-item" v-for="m in siswas.data" :key="m.id">
                <p class="accordion-header" id="headingOne">
                  <button class="accordion-button d-flex justify-content-between " type="button"
                    data-bs-toggle="collapse" :data-bs-target="`#collapse${m.id}`" aria-expanded="true"
                    aria-controls="collapseOne">
                    <i class="fas fa-angle-double-right"></i> &nbsp; {{ m.fullname }}
                  </button>
                </p>
                <div :id="`collapse${m.id}`" class="accordion-collapse collapse" aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample">
                  <div class="accordion-body">

                    <div class="mt-5">
                      <div v-for="group in kategori" :key="group.id" class="mt-5">
                        <!-- <h5>{{ group.nama_group }}</h5> -->
                        <blockquote class="blockquote">
                          <p class="mb-0 ps-2 text-default">
                            {{ group.nama_group }}
                          </p>
                        </blockquote>,
                        <table>
                          <thead class="thead-light">
                            <tr>
                              <th>Aspek Sikap</th>
                              <th>Indikator Sikap</th>
                              <th class="text-center">Predikat</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="kategoriItem in group.kategori" :key="kategoriItem.id">
                              <td v-align="center">{{ kategoriItem.nama_kategori }}</td>
                              <td>
                          <tbody v-for="subkategoriItem in kategoriItem.subkategori" :key="subkategoriItem.id">
                            <tr>
                              <td style="border:0px solid" height="36px">{{ subkategoriItem.nama_subkategori }}</td>

                            </tr>
                          </tbody>
                          </td>
                          <td>
                            <tbody v-for="subkategoriItem in kategoriItem.subkategori" :key="subkategoriItem.id">
                              <tr>
                                <td style="border:0px solid">

                                  <select class="form-control form-select-sm"
                                    @change="handleSelectChange(m.id, kategoriItem.id, subkategoriItem.id, $event)">
                                    <option v-if="getNilai(m.id, kategoriItem.id, subkategoriItem.id)"
                                      :value="getNilai(m.id, kategoriItem.id, subkategoriItem.id)">
                                      {{ getNilai(m.id, kategoriItem.id, subkategoriItem.id) }}
                                    </option>
                                    <option v-else value="">Pilih</option>
                                    <option v-for="k in filteredKetNilai(group)" :value="k.kode" :key="k.id">{{ k.kode
                                      }}
                                    </option>
                                  </select>
                                </td>

                              </tr>
                            </tbody>
                          </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive, ref, refs, watch } from "vue";
import { useStore } from "vuex";
import Swal from 'sweetalert2';
import { useRoute, useRouter } from "vue-router";

export default {
  name: "kelasIndex",


  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const validation = ref([]);
    const kelas = reactive({
      classname: '',
      id_ta: '',
      wali_kelas: '',
      guru_pendamping: '',
      id_unitlevel: '',
      id_unit: ''
    });

    const data = reactive({
      // currentPage: 1,
      perPage: 100,
      search: '',
      raport: {
        file: '',
        id_studentactive: ''
      },
      pages: 'perwalian-view'

    });

    onMounted(async () => {
      await store.dispatch("siswa/getSiswaNilaiSikap", {
        page: 1,
        perPage: data.perPage,
        search: data.search,
        kelasId: route.params.id
      });
      await store.dispatch("kelas/getDetail", route.params.id);
      await store.dispatch("groupkategorisikap/getByUnitdanTa", route.params.id);
      await store.dispatch("nilaisikap/getData", route.params.id);
      await store.dispatch("keterangannilai/getAll");
      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    const siswas = computed(() => {
      return store.getters["siswa/getSiswaNilaiSikap"];

    });

    const kategori = computed(() => {
      return store.getters["groupkategorisikap/getByUnitdanTa"];
    });

    const ketnilai = computed(() => {
      return store.getters["keterangannilai/getAll"] || [];
    });

    const filteredKetNilai = (group) => {
      const keterangannilai_id = group.keterangannilai_id || [];
      return ketnilai.value.filter((k) => keterangannilai_id.includes(k.id));
    };


    const nilaisikap = computed(() => {
      return store.getters["nilaisikap/getData"];
    });

    async function handleSelectChange(idsiswa, kategoriItemId, subkategoriItemId, event) {
      try {
        // Aksi yang ingin Anda lakukan ketika nilai elemen select berubah
        const selectedValue = event.target.value;

        // console.log("Selected value changed to:", selectedValue);

        let formData = new FormData();
        formData.append('nilai', selectedValue)
        formData.append('id_siswa', idsiswa)
        formData.append('id_subkategori', subkategoriItemId)
        formData.append('id_kategori', kategoriItemId)
        formData.append('kelas_id', route.params.id)
        formData.append('id_ta', kelas.id_ta)



        await store.dispatch('nilaisikap/store', formData);

        // Tambahan logika atau pemanggilan fungsi sesuai kebutuhan
      } catch (error) {
        validation.value = error;

        Swal.fire({
          title: "GAGAL!",
          text: validation.value.error,
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });

        console.error("An error occurred:", error);
      }
    }




    const getNilai = (idSiswa, idKategori, idSubkategori) => {
      const nilai = nilaisikap.value.find(item => {
        return (
          item.id_siswa === idSiswa &&
          item.id_kategori === idKategori &&
          item.id_subkategori === idSubkategori
        );
      });

      return nilai ? nilai.nilai : '';
    };





    watch(
      () => store.state.kelas.details,
      (newkelas) => {
        if (newkelas) {
          const {
            wali_kelas,
            master_kelas,
            id_ta,

            guru_pendamping

          } = newkelas;
          kelas.classname = master_kelas.classname;
          kelas.id_ta = id_ta;
          kelas.id_unit = master_kelas.unit_level.id_unit
          kelas.wali_kelas = wali_kelas ? wali_kelas.fullname : '';
          kelas.guru_pendamping = guru_pendamping ? guru_pendamping.fullname : '';
          kelas.id_unitlevel = master_kelas.id_unitlevel;

        }
      }
    );

    const perPageOptions = [5, 10, 15, 25, 50, 100, 250, 500, 1000];

    async function handleFileChange(e) {

      // //console.log('ini adalah', e.target.attributes[1].nodeValue);
      let file = data.raport.file = e.target.files[0];
      if (!file.type.match('pdf.*')) {

        //if fileType not allowed, then clear value and set null
        e.target.value = ''

        //set state "category.image" to null
        data.raport.file = null

        //show sweet alert
        Swal.fire({
          title: 'OOPS!',
          text: "Format File Tidak Didukung!",
          icon: 'error',
          showConfirmButton: false,
          timer: 2000
        })
      }

      let formData = new FormData();
      // formData.append('kelasId', this.$route.params.id)
      formData.append('file', data.raport.file)
      formData.append('id_studentactives', e.target.attributes[2].nodeValue)

      await store.dispatch('siswa/saveRaport', formData)
        //success
        .then(() => {
          Swal.fire({
            title: 'BERHASIL!',
            text: "Data Berhasil Disimpan!",
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          })

          this.$router.push({
            // path:'/admin'
            name: 'admin-listsiswaraport',
            params: { id: route.params.id },
          })

          store.dispatch("siswa/getSiswaNilaiSikap", route.params.id);
          refs.fileupload = null;


        }).catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });


    }


    const changePage = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("siswa/getSiswaNilaiSikap", {
        page: page,
        perPage: jumlah,
        search: pencarian,
        kelasId: route.params.id

      });
    };

    const changePerPage = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("siswa/getSiswaNilaiSikap", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        kelasId: route.params.id
      });
    };



    const searchData = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("siswa/getSiswaNilaiSikap", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        kelasId: route.params.id
      });


      // Lakukan tindakan yang diinginkan dengan nilai searchQuery
    };




    setNavPills();
    setTooltip(store.state.bootstrap);

    return {
      kategori,
      kelas,
      siswas,
      validation,
      changePage,
      changePerPage,
      perPageOptions,
      nilaisikap,
      getNilai,
      data,
      searchData,
      handleFileChange,
      ketnilai,
      filteredKetNilai,
      handleSelectChange

    };
  },
};
</script>

<!-- 
<style scoped>
/* Add your modal styling here */
.modal {
  /* Add styles for overlay/background */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;

}


.modal-content {
  /* Add styles for the modal content */
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

/* Gaya untuk tabel utama */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  /* Untuk memberikan sedikit ruang antara tabel dan elemen lainnya */
}

/* Gaya untuk elemen header tabel (thead) */
thead {
  background-color: #f2f2f2;
  /* Warna latar belakang untuk header */
}

/* Gaya untuk sel header tabel (th) */
th,
td {
  border: 1px solid #ddd;
  /* Garis tepi untuk sel header dan sel data */
  padding: 8px;
  /* Ruang dalam di dalam sel */
  text-align: left;
  /* Aligment teks ke kiri */
}

/* Gaya untuk baris tabel (tr) */
tr:nth-child(even) {
  background-color: #f9f9f9;
  /* Warna latar belakang untuk baris genap */
}

/* Gaya saat kursor berada di atas sel */
td:hover {
  background-color: #e0e0e0;
  /* Warna latar belakang saat kursor di atas sel */
}
</style>
   -->


<style>
.table-container {
  overflow: auto;
  max-height: 400px;
}

table.table-bordered th {
  position: sticky;
  top: 0;
  background-color: white;
}

.form-nilai {
  max-width: 100px;
  border: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

td {
  color: black;
}
</style>


<style scoped>
/* Add your modal styling here */
.modal {
  /* Add styles for overlay/background */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;

}


.modal-content {
  /* Add styles for the modal content */
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

/* Gaya untuk tabel utama */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  /* Untuk memberikan sedikit ruang antara tabel dan elemen lainnya */
}

/* Gaya untuk elemen header tabel (thead) */
thead {
  background-color: #f2f2f2;
  /* Warna latar belakang untuk header */
}

/* Gaya untuk sel header tabel (th) */
th,
td {
  border: 1px solid #ddd;
  /* Garis tepi untuk sel header dan sel data */
  padding: 8px;
  /* Ruang dalam di dalam sel */
  text-align: left;
  /* Aligment teks ke kiri */
}

td.custom-id {
  border: none;
  /* Garis tepi untuk sel header dan sel data */
  padding: 8px;
  /* Ruang dalam di dalam sel */
  text-align: left;
  /* Aligment teks ke kiri */
}



/* Gaya untuk baris tabel (tr) */
tr:nth-child(even) {
  background-color: #f9f9f9;
  /* Warna latar belakang untuk baris genap */
}

/* Gaya saat kursor berada di atas sel */
td:hover {
  background-color: #e0e0e0;
  /* Warna latar belakang saat kursor di atas sel */
}
</style>