<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Edit Menu</h5>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="updateJadwal">
                  <div class="row">
                    <div class="col-4">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tahun Ajaran <span class="text-danger">*</span></label>
                        <select class="form-control" v-model="jadwalpelaksanaan.id_ta">
                          <option value="">-- select tahun ajaran --</option>
                          <option v-for="tahunajaran in tahunajarans" :key="tahunajaran.id" :value="tahunajaran.id">{{
                  tahunajaran.desc }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.id_ta">{{ validation.id_ta[0] }}</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Units <span class="text-danger">*</span></label>
                        <select class="form-control" v-model="jadwalpelaksanaan.id_unit" @change="changeUnit">
                          <option value="">-- select unit --</option>
                          <option v-for="unit in listunits" :key="unit.id" :value="unit.id">{{ unit.name }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.id_ta">{{ validation.id_ta[0] }}</small>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="form-group">
                        <label for="exampleFormControlSelect2">Unitlevel </label>
                        <select multiple class="form-control" id="exampleFormControlSelect2"
                          v-model="jadwalpelaksanaan.unitlevel">
                          <option v-for="u in unitlevels" :key="u.id" :value="u.id">{{ u.name }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.unitlevel">{{ validation.unitlevel[0] }}</small>
                      </div>

                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-12">
                      <label for="exampleFormControlInput1">Description <span class="text-danger">*</span></label>
                      <input type="text" placeholder="Desc" class="form-control" v-model="jadwalpelaksanaan.desc" />
                      <small class="text-danger" v-if="validation.desc">{{ validation.desc[0] }}</small>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-4">
                      <label for="exampleFormControlInput1">Period <span class="text-danger">*</span></label>
                      <input type="text" placeholder="Period" class="form-control" v-model="jadwalpelaksanaan.period" />
                      <small class="text-danger" v-if="validation.period">{{ validation.period[0] }}</small>
                    </div>

                    <div class="col-4">
                      <label for="exampleFormControlInput1">Start Date <span class="text-danger">*</span></label>
                      <input type="date" placeholder="Period" class="form-control" v-model="jadwalpelaksanaan.start_reg"
                        disabled />
                      <small class="text-danger" v-if="validation.start_reg">{{ validation.start_reg[0] }}</small>
                    </div>
                    <div class="col-4">
                      <label for="exampleFormControlInput1">End Date <span class="text-danger">*</span></label>
                      <input type="date" placeholder="Period" class="form-control"
                        v-model="jadwalpelaksanaan.end_reg" />
                      <small class="text-danger" v-if="validation.end_reg">{{ validation.end_reg[0] }}</small>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-6">
                      <label for="exampleFormControlInput1">Tanggal Kelulusan <span class="text-danger">*</span></label>
                      <input type="date" placeholder="Period" class="form-control"
                        v-model="jadwalpelaksanaan.passdate" />
                      <small class="text-danger" v-if="validation.passdate">{{ validation.passdate[0] }}</small>
                    </div>
                    <div class="col-6">
                      <label for="exampleFormControlInput1">Tanggal Mulai Aktif <span
                          class="text-danger">*</span></label>
                      <input type="date" placeholder="Period" class="form-control"
                        v-model="jadwalpelaksanaan.startdate" />
                      <small class="text-danger" v-if="validation.startdate">{{ validation.startdate[0] }}</small>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-6">
                      <label for="exampleFormControlInput1">Active <span class="text-danger">*</span></label>
                      <select class="form-control" v-model="jadwalpelaksanaan.active">
                        <option value="">-- select active --</option>
                        <option value="Active">Active</option>
                        <option value="Registered">Register</option>
                      </select>
                      <small class="text-danger" v-if="validation.active">{{ validation.active[0] }}
                      </small>
                      <small class="text-danger text-sm">active: Pendaftar akan otomatis aktif (tanpa membayar biaya
                        formulir / psikotes)</small>
                    </div>
                    <div class="col-6">
                      <label for="exampleFormControlInput1">Type <span class="text-danger">*</span></label>
                      <select class="form-control" v-model="jadwalpelaksanaan.type">
                        <option value="">-- select type --</option>
                        <option value="Baru">Baru</option>
                        <option value="Pindahan">Pindahan</option>
                      </select>

                      <small class="text-danger" v-if="validation.type">{{ validation.type[0] }}</small>
                    </div>
                    <!-- <div class="col-4">
                          <label for="exampleFormControlInput1"
                          >Exclude Last Unitlevel <span class="text-danger">*</span></label
                        >
                        <select class="form-control" v-model="jadwalpelaksanaan.exclude_last_unitlevel">
                          <option value="">-- select Exclude Last Unitlevel --</option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                        <small class="text-danger" v-if="validation.exclude_last_unitlevel">{{ validation.exclude_last_unitlevel[0] }}</small>
                      </div> -->
                  </div>
                  <div class="row mb-4">
                    <div class="col-4">
                      <label for="exampleFormControlInput1">Source <span class="text-danger">*</span></label>
                      <select class="form-control" v-model="jadwalpelaksanaan.source">
                        <option value="">-- select source --</option>
                        <option value="External">External</option>
                        <option value="Internal">Internal</option>
                      </select>
                      <small class="text-danger" v-if="validation.source">{{ validation.source[0] }}</small>
                    </div>
                    <div class="col-4">
                      <label for="exampleFormControlInput1">Selection <span class="text-danger">*</span></label>
                      <select class="form-control" v-model="jadwalpelaksanaan.selection">
                        <option value="">-- select selection --</option>
                        <option value="Test">Test</option>
                        <option value="Non Test">Non Test</option>
                      </select>
                      <small class="text-danger" v-if="validation.selection">{{ validation.selection[0] }}</small>
                    </div>
                    <div class="col-4">
                      <label for="exampleFormControlInput1">Status <span class="text-danger">*</span></label>
                      <select class="form-control" v-model="jadwalpelaksanaan.status">
                        <option value="">-- select status--</option>
                        <option value="Open">Open</option>
                        <option value="Close">Close</option>
                      </select>
                      <small class="text-danger" v-if="validation.selection">{{ validation.selection[0] }}</small>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-4">
                      <label for="exampleFormControlInput1">Kepala Sekolah <span class="text-danger">*</span></label>
                      <input type="text" placeholder="Kepala Sekolah" class="form-control"
                        v-model="jadwalpelaksanaan.leader" />
                      <small class="text-danger" v-if="validation.leader">{{ validation.leader[0] }}</small>
                    </div>
                    <div class="col-4">
                      <label for="exampleFormControlInput1">Ketua Panitia <span class="text-danger">*</span></label>
                      <input type="text" placeholder="Kepala Sekolah" class="form-control"
                        v-model="jadwalpelaksanaan.co_leader" />
                      <small class="text-danger" v-if="validation.co_leader">{{ validation.co_leader[0] }}</small>
                    </div>
                    <div class="col-4">
                      <label for="exampleFormControlInput1">No SK<span class="text-danger">*</span></label>
                      <input type="text" placeholder="Kepala Sekolah" class="form-control"
                        v-model="jadwalpelaksanaan.nosk" />
                      <small class="text-danger" v-if="validation.nosk">{{ validation.nosk[0] }}</small>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label>Catatan Administrasi dan Jadwal</label>
                        <editor v-model="jadwalpelaksanaan.schedule_requirements_notes"
                          api-key="bl6dp6crnjuncjrkki7d63ymkgn05323b4kaf2n6x81sn8xj" :init="{
                  height: 500,
                  menubar: true,
                  plugins: [
                    'advlist', 'autolink', 'link', 'image', 'lists', 'charmap', 'preview', 'anchor', 'pagebreak',
                    'searchreplace', 'wordcount', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media',
                    'table', 'emoticons', 'template', 'help'
                  ],
                  toolbar: 'undo redo | styles | bold italic | alignleft aligncenter alignright alignjustify | ' +
                    'bullist numlist outdent indent | link image | print preview media fullscreen | ' +
                    'forecolor backcolor emoticons | help',
                  menu: {
                    favs: { title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons' }
                  },
                  menubar: 'favs file edit view insert format tools table help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px }'
                }" />
                        <!-- <textarea v-model="jadwalpelaksanaan.schedule_requirements_notes" class="form0-control" > -->

                        <div v-if="validation.description" class="mt-2">
                          <b-alert show variant="danger">{{ validation.description[0] }}</b-alert>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-md">
                        simpan
                      </button>
                      &nbsp;
                      <router-link :to="{ name: 'admin-jadwalpelaksanaan' }"
                        class="btn btn-danger btn-sm">kembali</router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, computed, onMounted, watch } from "vue";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";
import Editor from "@tinymce/tinymce-vue";

export default {
  name: "MenuIndex",
  components: {
    Editor,
  },
  data() {
    return {
      showMenu: false,
      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {

      }
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state
    const jadwalpelaksanaan = reactive({
      id_ta: '',
      id_unit: '',
      unitlevel: [],
      desc: '',
      period: '',
      active: '',
      passdate: '',
      startdate: '',
      type: '',
      source: '',
      start_reg: '',
      end_reg: '',
      selection: '',
      status: '',
      leader: '',
      co_leader: '',
      nosk: '',
      notes: '',
      exclude_last_unitlevel: '',
      schedule_requirements_notes: ''
    });


    const data = reactive({
      pages: 'jadwalpelaksanaan-edit'
    })

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    const route = useRoute();

    //function register, fungsi ini di jalankan ketika form di submit
    function updateJadwal() {
      //define variable
      let id_ta = jadwalpelaksanaan.id_ta;

      let id_unit = jadwalpelaksanaan.id_unit;
      let desc = jadwalpelaksanaan.desc;
      let period = jadwalpelaksanaan.period;
      let active = jadwalpelaksanaan.active;
      let start_reg = jadwalpelaksanaan.start_reg;
      let end_reg = jadwalpelaksanaan.end_reg;
      let passdate = jadwalpelaksanaan.passdate;
      let startdate = jadwalpelaksanaan.startdate;
      let type = jadwalpelaksanaan.type;
      let source = jadwalpelaksanaan.source;
      let selection = jadwalpelaksanaan.selection;
      let status = jadwalpelaksanaan.status;
      let leader = jadwalpelaksanaan.leader;
      let co_leader = jadwalpelaksanaan.co_leader;
      let nosk = jadwalpelaksanaan.nosk;
      let notes = jadwalpelaksanaan.notes;
      let exclude_last_unitlevel = jadwalpelaksanaan.exclude_last_unitlevel;
      let schedule_requirements_notes = jadwalpelaksanaan.schedule_requirements_notes

      let formData = new FormData();

      formData.append('id_ta', id_ta)
      formData.append('id_unit', id_unit)
      formData.append("id_unitlevel[]", jadwalpelaksanaan.unitlevel);
      formData.append('desc', desc)
      formData.append('period', period)
      formData.append('start_reg', start_reg)
      formData.append('end_reg', end_reg)
      formData.append('active', active)
      formData.append('type', type)
      formData.append('source', source)
      formData.append("passdate", passdate);
      formData.append("startdate", startdate);
      formData.append('selection', selection)
      formData.append('status', status)
      formData.append('leader', leader)
      formData.append('co_leader', co_leader)
      formData.append('nosk', nosk)
      formData.append('notes', notes)
      formData.append('exclude_last_unitlevel', exclude_last_unitlevel)
      formData.append('schedule_requirements_notes', schedule_requirements_notes)
      formData.append("_method", "POST");

      console.log('update', formData);

      //panggil actions "register" dari module "auth"
      store
        .dispatch("jadwalpelaksanaan/update", {
          jadwalId: route.params.id,
          payload: formData,
        })
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-jadwalpelaksanaan" });
          Swal.fire({
            icon: "success",
            title: "Update data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });
    }

    onMounted(async () => {
      await store.dispatch("units/getAll");
      await store.dispatch("tahunajaran/getAll");
      await store.dispatch("unitlevel/getAlls");

      await store.dispatch("jadwalpelaksanaan/getDetail", route.params.id);
      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    watch(
      () => store.state.jadwalpelaksanaan.jadwaldetail,
      (newJadwal) => {
        if (newJadwal) {
          const {
            id_ta,
            id_unitlevel,
            id_unit,
            desc,
            period,
            active,
            type,
            source,
            start_reg,
            end_reg,
            selection,
            status,
            leader,
            co_leader,
            passdate,
            startdate,
            nosk,
            notes,
            exclude_last_unitlevel,
            schedule_requirements_notes
          } = newJadwal;

          // console.log('Received newJadwal:', newJadwal);
          jadwalpelaksanaan.id_ta = id_ta;
          jadwalpelaksanaan.id_unit = id_unit;
          jadwalpelaksanaan.unitlevel = JSON.parse(id_unitlevel);
          jadwalpelaksanaan.desc = desc;
          jadwalpelaksanaan.period = period;
          jadwalpelaksanaan.active = active;
          jadwalpelaksanaan.type = type;
          jadwalpelaksanaan.source = source;
          jadwalpelaksanaan.passdate = passdate;
          jadwalpelaksanaan.startdate = startdate;
          jadwalpelaksanaan.start_reg = start_reg;
          jadwalpelaksanaan.end_reg = end_reg;
          jadwalpelaksanaan.selection = selection;
          jadwalpelaksanaan.status = status;
          jadwalpelaksanaan.leader = leader;
          jadwalpelaksanaan.co_leader = co_leader;
          jadwalpelaksanaan.nosk = nosk;
          jadwalpelaksanaan.notes = notes;
          jadwalpelaksanaan.exclude_last_unitlevel = exclude_last_unitlevel;
          jadwalpelaksanaan.schedule_requirements_notes = schedule_requirements_notes;

        }
      }
    );

    //computed
    const tahunajarans = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["tahunajaran/getAll"];
    });

    const listunits = computed(() => {
      //panggil getter dengan nama "getAllPermission" di module "permission" vuex
      return store.getters["units/getAll"];
    });

    const unitlevels = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["unitlevel/getAlls"];
    });

    const changeUnit = async () => {
      await store.dispatch("unitlevel/getAlls",
        jadwalpelaksanaan.id_unit
      );
    };
    //return a state and function
    return {
      jadwalpelaksanaan, // <-- state user
      validation, // <-- state validation
      updateJadwal,
      tahunajarans, // <-- hook toast
      listunits,
      unitlevels,
      changeUnit,
      data
    };
  },
};
</script>