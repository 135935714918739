<template>
    <div class="py-4 container-fluid">
      <div class="mt-4 row">
        <div class="col-12">
          <div class="card">
            <!-- Card header -->
            <div class="card-header">
              <div class="d-flex justify-content-between">
                <h5 class="mb-0">Laporan Perkembangan Bulanan KB-TK</h5>

              </div>
            </div>
  
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                 
                  

                    <div class="row">
                        <div class="col-md-4 col-lg-4">
                            <div class="row">
                                <div class="col-12">
                                    <h5>Identitas Siswa </h5>
                                    
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                        <table class="table">
                                          <thead>
                                            <tr>
                                                <td >Nama Lengkap</td>
                                                <td>:</td>
                                                <td>{{ siswa.data ? siswa.data[0].fullname : 'loading' }}</td>
                                            </tr>
                                            <tr>
                                                <td >Jenis Laporan</td>
                                                <td >:</td>
                                                <td v-if="this.$route.params.bidang == 'wali kelas'">{{  this.$route.params.bidang }}</td>
                                                <td v-if="this.$route.params.bidang != 'wali kelas'">
                                                  Guru Bidang - {{  this.$route.params.bidang }}
                                                </td>
                                            </tr>
                                          </thead>
                                        </table>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                    <form @submit.prevent="saveLaporan">
                      <div class="row m-3">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label for="">Penilaian Perkembangan</label>
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>Semester</th>
                                  <th>
                                    {{ tahunajaran.desc }}
                                    <input type="hidden" name="id_ta" v-model="tahunajaran.id">
                                  </th>
                                </tr>
                                <tr>
                                  <th valign="top">Bulan </th>
                                  <th>
                                    <select class="form-select" v-model="variable.bulan" v-if="getexist!=''">
                                        <option value="*">Pilih Bulan</option>
                                        <option
                                            v-for="eks in listbulan"
                                            :key="eks.id"
                                            :value="eks.id"
                                            :disabled="getexist && getexist.includes(eks.nama)"
                                            :style="{ color: getexist && getexist.includes(eks.nama) ? 'green' : 'black' }"
                                        >
                                            {{ eks.nama }} <span v-if="getexist && getexist.includes(eks.nama)" v-html="'&#x2713;'"></span>
                                        </option>

                                    </select>

                                    <select class="form-select" v-model="variable.bulan" v-if="getexist==''">
                                        <option value="*">Pilih Bulan</option>
                                        <option
                                            v-for="eks in listbulan"
                                            :key="eks.id"
                                            :value="eks.id"
                                            :style="{ color: getexist && getexist.includes(eks.nama) ? 'green' : 'black' }"
                                        >
                                            {{ eks.nama }} <span v-if="getexist && getexist.includes(eks.nama)" v-html="'&#x2713;'"></span>
                                        </option>

                                    </select>

                                    <small class="text-danger" v-if="validation.bulan">{{ validation.bulan[0] }}</small>
                                  </th>
                                </tr>
                                <tr hidden>
                                  <th valign="top">Tahun</th>
                                  <th>
                                    <input type="number" v-model="variable.tahun" class="form-control" size="2">
                                    <small class="text-danger" v-if="validation.tahun">{{ validation.tahun[0] }}</small>
                                  </th>
                                </tr>
                                <tr>
                                  <th valign="top">Komentar</th>
                                  <th>
                                                <textarea 
                                                  v-model="variable.narasi"
                                                  name="narasi" 
                                                  cols="60" 
                                                  rows="5" 
                                                  style="width: 100%;"
                                                  maxlength="1000"
                                              ></textarea>
                                              <br>
                                              <small class="text-danger" v-if="validation.narasi">{{ validation.narasi[0] }}</small>
                                  </th>
                                </tr>
                                <tr>
                                  <th valign="top">Gambar 1</th>
                                  <th>
                                    <input type="file" accept=".jpg,.jpeg,.png" class="form-control"  @change="handleFileChange"  placeholder="upload raport"/>
                                    <small class="text-danger" v-if="validation.foto1">{{ validation.foto1[0] }}</small>
                                  </th>
                                </tr>
                                <tr>
                                  <th valign="top">Gambar 2</th>
                                  <th>
                                    <input type="file" accept=".jpg,.jpeg,.png" ref="foto2"  class="form-control"  :data-id="2"  @change="handleFileChange2"  placeholder="upload raport"/>
                                    <small class="text-danger" v-if="validation.foto2">{{ validation.foto2[0] }}</small>
                                  </th>
                                </tr>
                                <tr>
                                  <th></th>
                                  <th>
                                    <div class="row">
                                      <div class="col-12">
                                        <button type="submit" class="btn btn-success btn-sm">
                                          simpan
                                        </button>
                                        &nbsp;
                                        <router-link
                                          :to="{ name: 'admin-laporan-bulanan-kb' , params:{id:this.$route.params.instructor },query: { kelass: $route.query.kelass } }"
                                          class="btn btn-danger btn-sm"
                                          >kembali</router-link
                                        >
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </div>
                      </div>
                      
                    </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  import setNavPills from "@/assets/js/nav-pills.js";
  import setTooltip from "@/assets/js/tooltip.js";
  import { reactive, onMounted,  computed, ref } from "vue";
  import { useStore } from "vuex";
  import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2";
  
  export default {
    name: "KBEdit",
    components: {},
    data() {
      return {
        showsiswa: false,
      };
    },
  
    mounted() {
      setNavPills();
      setTooltip(this.$store.state.bootstrap);
      // eslint-disable-next-line no-unused-vars
    },
  
    setup() {
      //siswa state

      const variable = reactive({
        id_unit: "",
        id_ta:"",
        id_student: "",
        bulan: "",
        tahun: "",
        elemen: "",
        narasi: "",
        foto1:"",
        foto2:"",
      });


      const listbulan = computed(() => {
            return store.getters["global/getBulanSemester"];
      });


      const tahunajaran = computed(() => {
            return store.getters["tahunajaran/getActive"];
      });

      const getexist = computed(() => {
            return store.getters["narasikb/getExist"];
      });

      const siswa = computed(() => {
            return store.getters["siswa/getSiswaAktifin"];
      });



      //validation state

  
      //store vuex
      const store = useStore();

      const router = useRouter();

      const validation = ref([]);
  


    const route = useRoute();
  
      //function register, fungsi ini di jalankan ketika form di submit
    
      

      onMounted(async () => {
        // console.log("onlun ",route.params);
        // await store.dispatch("reg/getDetailSiswa", route.params.id);
        await store.dispatch("global/getBulanSemester");
        await store.dispatch("narasikb/getExist", {id:route.params.id, bidang:route.params.bidang });
        
        
        await store.dispatch("siswa/getSiswaAktifin", {
          page:1,
          perPage:10,
          search: route.params.id,
          kelas:'',
          unit:'',
          unitlevel:'',
          guru:route.params.instructor
  
        });

        
        await store.dispatch("tahunajaran/getActive");
        console.log(" siswass ", siswa);
      });
  
      // watch(
      //   () => store.state.reg.pesertadetail,
      //   (newsiswa) => {
      //     //console.log('newsiswa',newsiswa);
      //     if (newsiswa) {
      //       const {
      //           fullname,
      //           nickname,
      //           gender,
      //           birthplace,
      //           birthdate,
      //           tingkatan,
      //           sekolah,
      //           id_unitlevel,
      //           id,
      //       } = newsiswa;
      //       siswa.fullname = fullname;
      //       siswa.nickname = nickname;
      //       siswa.gender = gender;
      //       siswa.tempatlahir = birthplace;
      //       siswa.tanggallahir = birthdate;
      //       siswa.tingkatan = tingkatan;
      //       siswa.sekolah = sekolah;
      //       siswa.id_unitlevel = id_unitlevel;
      //       // siswa.id = id;
      //       siswa.id = id;
            
            
            
      //     }
      //   }
      // );

      const data = reactive({
        // currentPage: 1,
        perPage:100,
        search:'',
        raport: {
          file: '',
          id_studentactive:''
        },
        
      });

      async function handleFileChange(e) {
        let image = (this.variable.foto1 = e.target.files[0]);
        console.log(image);

        // Pengecekan tipe file
        if (!image.type.match("image.*")) {
            e.target.value = "";
            this.variable.foto1 = null;
            Swal.fire({
                title: "OOPS!",
                text: "Format File Tidak Didukung!",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
            });
            return;
        }

        // Pengecekan ukuran file maksimal 1MB
        const maxSize = 1 * 1024 * 1024; // 1MB dalam byte
        if (image.size > maxSize) {
            e.target.value = "";
            this.variable.foto1 = null;
            Swal.fire({
                title: "OOPS!",
                text: "Ukuran file tidak boleh lebih dari 1MB!",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
            });
            return;
        }

        // Jika semua pengecekan lolos, file dapat diproses lebih lanjut
      }

      async function handleFileChange2(e) {
        let image = (this.variable.foto2 = e.target.files[0]);
        console.log(image);

        // Pengecekan tipe file
        if (!image.type.match("image.*")) {
            e.target.value = "";
            this.variable.foto2 = null;
            Swal.fire({
                title: "OOPS!",
                text: "Format File Tidak Didukung!",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
            });
            return;
        }

        // Pengecekan ukuran file maksimal 1MB
        const maxSize = 1 * 1024 * 1024; // 1MB dalam byte
        if (image.size > maxSize) {
            e.target.value = "";
            this.variable.foto2 = null;
            Swal.fire({
                title: "OOPS!",
                text: "Ukuran file tidak boleh lebih dari 1MB!",
                icon: "error",
                showConfirmButton: false,
                timer: 2000,
            });
            return;
        }

      }
      

      function saveLaporan() {
        //define variable
        // console.log(" siswa ", siswa.value ? siswa.value.data[0] : null);        
        let formData = new FormData();
        formData.append('id_unit', siswa.value ? siswa.value.data[0].id_unit : null);
        formData.append('id_ta', this.tahunajaran.id);
        formData.append('id_student', siswa.value ? siswa.value.data[0].id_student : null);
        formData.append('bulan', this.variable.bulan);
        formData.append('tahun', this.variable.tahun);
        formData.append('elemen', route.params.bidang);
        formData.append('narasi', this.variable.narasi);
        formData.append('foto1', this.variable.foto1);
        formData.append('foto2', this.variable.foto2);
        formData.append("_method", "POST");
        console.log(formData);
        //panggil actions "register" dari module "auth"
        store
          .dispatch("narasikb/store", formData)
          .then(() => {
            router.push({ name: 'admin-laporan-bulanan-kb', params: { id:route.params.instructor },query: { kelass: route.query.kelass }  });
            Swal.fire({
              icon: "success",
              title: "Simpan data berhasil",
            });
            // toast.success("Tambah data berhasil")
          })
          .catch((error) => {
            validation.value = error;
          });
      }
      

      //return a state and function
      return {
        siswa, // <--
        listbulan,
        variable,
        handleFileChange,
        handleFileChange2,
        data,
        saveLaporan,
        validation,
        tahunajaran,
        getexist
      };
    },
  };
  </script>