<template>
    <div class="py-4 container-fluid">
        <div class="mt-4 row">
            <div class="col-12">
                <div class="card">
                    <!-- Card header -->
                    <div class="card-header">
                        <div class="d-flex justify-content-between">
                            <h5 class="mb-0">Master P5</h5>
                            <!-- {{ $route.params }} -->
                            <router-link :to="{ name: 'admin-masterp5', params: { id: $route.query.idkelas } }"
                                class="btn btn-danger btn-sm">kembali</router-link>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <form @submit.prevent="saveTarget">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="">Dimensi</label>
                                                <select class="form-control select2" v-model="target.id_dimensip5"
                                                    @change="changeDimensi">
                                                    <option value="">-- select Dimensi--</option>
                                                    <option v-for="d in getdimensi" :key="d.id" :value="d.id">
                                                        {{ d.nama_dimensi }}
                                                    </option>
                                                </select>
                                                <small>Jika tidak ada dimensi yang sesuai maka klik <span
                                                        @click="openTambahDimensi" class="text-primary cursor-pointer"
                                                        style="text-decoration: none; cursor: pointer;"><b>tambahkan</b></span></small>
                                                <small class="text-danger" v-if="validation.masterdimensip5_id">{{
                                                    validation.masterdimensip5_id[0] }}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="">Element</label>
                                                <select class="form-control select2" v-model="target.id_elementp5">
                                                    <option value="">-- select Element--</option>
                                                    <option v-for="e in getelement" :key="e.id" :value="e.id">
                                                        {{ e.nama_element }}
                                                    </option>
                                                </select>
                                                <small>Jika tidak ada element yang sesuai maka klik <span
                                                        @click="openTambahElement" class="text-primary cursor-pointer"
                                                        style="text-decoration: none; cursor: pointer;"><b>tambahkan</b></span></small>
                                                <small class="text-danger" v-if="validation.id_elementp5">{{
                                                    validation.id_elementp5[0] }}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="">Nama Target</label>
                                                <input type="text" class="form-control" v-model="target.nama_target">

                                                <small class="text-danger" v-if="validation.nama_target">{{
                                                    validation.nama_target[0] }}</small>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-">
                                            <button type="submit" class="btn btn-primary">Simpan</button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>

                        <div class="row mt-5">
                            <table>
                                <template v-for="(d, index) in getdimensi" :key="d.id">
                                    <thead>
                                        <tr v-if="index == 0">
                                            <th>Dimensi</th>
                                            <th>Element</th>
                                            <th>Target</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <i @click="openEditDimensi(d)" class="fa fa-pen text-primary"
                                                    style="text-decoration: none; cursor: pointer;"></i> <i
                                                    @click="confirmDeleteDimensi(d.id)" class="fa fa-trash text-danger"
                                                    style="text-decoration: none; cursor: pointer;"></i>
                                                {{ d.nama_dimensi }}
                                            </td>
                                            <td>
                                                <ul>
                                                    <li v-for="i in d.element" :key="i.id">
                                                        <i class="fa fa-pen text-primary" @click="openEditElement(i)"
                                                            style="text-decoration: none; cursor: pointer;"></i> <i
                                                            @click="confirmDeleteElement(i.id)"
                                                            class="fa fa-trash text-danger"
                                                            style="text-decoration: none; cursor: pointer;"></i> &nbsp;
                                                        {{
                                                            i.nama_element }}
                                                    </li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul>
                                                    <template v-for="i in d.element" :key="i.id">
                                                        <li v-for="t in i.target" :key="t.id">
                                                            <i class="fa fa-pen text-primary" @click="openEditTarget(t)"
                                                                style="text-decoration: none; cursor: pointer;"></i> <i
                                                                @click="confirmDeleteTarget(t.id)"
                                                                class="fa fa-trash text-danger"
                                                                style="text-decoration: none; cursor: pointer;"></i>
                                                            &nbsp;
                                                            {{ t.nama_target }}
                                                        </li>
                                                    </template>
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- modal -->
        <div class="modal" v-if="showTambahDimensi">
            <div class="col-lg-12">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Tambah Dimensi</h5>
                            <button @click="closeTambahDimensi" type="button" class="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <form @submit.prevent="saveDimensi">
                            <div class="modal-body">

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="">Nama Dimensi</label>
                                            <input class="form-control" placeholder="Nama Dimensi"
                                                v-model="dimensi.nama_dimensi">

                                            <small class="text-danger" v-if="validation.nama_dimensi">{{
                                                validation.nama_dimensi[0] }}</small>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="modal-footer">
                                <button @click="closeTambahDimensi" type="button" class="btn btn-secondary"
                                    data-bs-dismiss="modal">Close</button>
                                <button type="submit" class="btn btn-primary">Save changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" v-if="showEditDimensi">
            <div class="col-lg-12">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Edit Dimensi</h5>
                            <button @click="closeEditDimensi" type="button" class="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <form @submit.prevent="updateDimensi">
                            <div class="modal-body">

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="">Nama Dimensi</label>
                                            <input class="form-control" placeholder="Nama Dimensi"
                                                v-model="dimensi.nama_dimensi">

                                            <small class="text-danger" v-if="validation.nama_dimensi">{{
                                                validation.nama_dimensi[0] }}</small>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="modal-footer">
                                <button @click="closeEditDimensi" type="button" class="btn btn-secondary"
                                    data-bs-dismiss="modal">Close</button>
                                <button type="submit" class="btn btn-primary">Update changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" v-if="showTambahElement">
            <div class="col-lg-12">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Tambah Element</h5>
                            <button @click="closeTambahElement" type="button" class="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <form @submit.prevent="saveElement">
                            <div class="modal-body">

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="">Nama Element</label>
                                            <input class="form-control" placeholder="Nama Element"
                                                v-model="element.nama_element">

                                            <small class="text-danger" v-if="validation.nama_element">{{
                                                validation.nama_element[0] }}</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="">Dimensi</label>
                                            <select class="form-control select2" v-model="element.id_dimensip5">
                                                <option value="">-- select Dimensi--</option>
                                                <option v-for="d in getdimensi" :key="d.id" :value="d.id">
                                                    {{ d.nama_dimensi }}
                                                </option>
                                            </select>

                                            <small class="text-danger" v-if="validation.id_dimensip5">{{
                                                validation.id_dimensip5[0] }}</small>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="modal-footer">
                                <button @click="closeTambahElement" type="button" class="btn btn-secondary"
                                    data-bs-dismiss="modal">Close</button>
                                <button type="submit" class="btn btn-primary">Save changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" v-if="showEditElement">
            <div class="col-lg-12">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Edit Element</h5>
                            <button @click="closeEditElement" type="button" class="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <form @submit.prevent="updateElement">
                            <div class="modal-body">

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="">Nama Element</label>
                                            <input class="form-control" placeholder="Nama Element"
                                                v-model="element.nama_element">

                                            <small class="text-danger" v-if="validation.nama_element">{{
                                                validation.nama_element[0] }}</small>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="">Dimensi</label>
                                            <select class="form-control select2" v-model="element.id_dimensip5">
                                                <option value="">-- select Dimensi--</option>
                                                <option v-for="d in getdimensi" :key="d.id" :value="d.id">
                                                    {{ d.nama_dimensi }}
                                                </option>
                                            </select>

                                            <small class="text-danger" v-if="validation.id_dimensip5">{{
                                                validation.id_dimensip5[0] }}</small>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="modal-footer">
                                <button @click="closeEditElement" type="button" class="btn btn-secondary"
                                    data-bs-dismiss="modal">Close</button>
                                <button type="submit" class="btn btn-primary">Update changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal" v-if="showEditTarget">
            <div class="col-lg-12">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Edit Target</h5>
                            <button @click="closeEditTarget" type="button" class="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                        <form @submit.prevent="updateTarget">
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="">Dimensi</label>
                                            <select class="form-control select2" v-model="target.id_dimensip5"
                                                @change="changeDimensi">
                                                <option value="">-- select Dimensi--</option>
                                                <option v-for="d in getdimensi" :key="d.id" :value="d.id">
                                                    {{ d.nama_dimensi }}
                                                </option>
                                            </select>

                                            <small class="text-danger" v-if="validation.masterdimensip5_id">{{
                                                validation.masterdimensip5_id[0] }}</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="">Element</label>
                                            <select class="form-control select2" v-model="target.id_elementp5">
                                                <option value="">-- select Element--</option>
                                                <option v-for="e in getelement" :key="e.id" :value="e.id">
                                                    {{ e.nama_element }}
                                                </option>
                                            </select>

                                            <small class="text-danger" v-if="validation.id_elementp5">{{
                                                validation.id_elementp5[0] }}</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="">Nama Target</label>
                                            <input type="text" class="form-control" v-model="target.nama_target">

                                            <small class="text-danger" v-if="validation.nama_target">{{
                                                validation.nama_target[0] }}</small>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div class="modal-footer">
                                <button @click="closeEditTarget" type="button" class="btn btn-secondary"
                                    data-bs-dismiss="modal">Close</button>
                                <button type="submit" class="btn btn-primary">Update changes</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- modal -->
    </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Swal from 'sweetalert2';

export default {
    name: "kelasIndex",
    components: {},
    data() {
        return {
            showkelas: false,
        };
    },

    mounted() {
        setNavPills();
        setTooltip(this.$store.state.bootstrap);
        // eslint-disable-next-line no-unused-vars
    },

    setup() {
        //user state

        const route = useRoute();
        // const router = useRouter();

        const data = reactive({
            // currentPage: 1,
            perPage: 10,
            search: ''
        });


        const target = reactive({

            id_ta: route.params.idta,
            id_unit: route.params.idunit,
            id_unitlevel: route.params.idunitlevel,
            id_dimensip5: '',
            id_elementp5: '',
            nama_target: ''
        });

        const dimensi = reactive({
            id: '',
            nama_dimensi: '',
            id_ta: route.params.idta,
            id_unit: route.params.idunit,
            id_masterp5: route.params.id,
            id_unitlevel: route.params.idunitlevel
        });

        const element = reactive({
            id: '',
            nama_element: '',
            id_ta: route.params.idta,
            id_unit: route.params.idunit,
            id_dimensip5: "",
            id_unitlevel: route.params.idunitlevel
        });

        const showTambahDimensi = ref(false);
        const showEditDimensi = ref(false);
        const showTambahElement = ref(false);
        const showEditElement = ref(false);
        const showEditTarget = ref(false);

        const openTambahDimensi = () => {
            showTambahDimensi.value = true;
        };

        const closeTambahDimensi = () => {
            showTambahDimensi.value = false;
        };

        const openEditDimensi = (d) => {
            dimensi.id = d.id;
            dimensi.nama_dimensi = d.nama_dimensi;
            showEditDimensi.value = true;
        };

        const closeEditDimensi = () => {
            showEditDimensi.value = false;
        };

        const openTambahElement = () => {
            showTambahElement.value = true;
        };

        const closeTambahElement = () => {
            showTambahElement.value = false;
        };

        const openEditElement = (i) => {
            // console.log(e.nama_element);
            element.id_dimensip5 = i.id_dimensip5;
            element.nama_element = i.nama_element;
            element.id = i.id;

            showEditElement.value = true;
        };

        const closeEditElement = () => {
            showEditElement.value = false;
        };

        const openEditTarget = (t) => {
            // console.log(e.nama_element);
            target.id_dimensip5 = t.id_dimensip5;
            target.id_elementp5 = t.id_elementp5;
            target.nama_target = t.nama_target;
            target.id = t.id;

            showEditTarget.value = true;
        };

        const closeEditTarget = () => {
            showEditTarget.value = false;
        };



        //validation state
        const validation = ref([]);

        //store vuex
        const store = useStore();

        onMounted(async () => {
            await store.dispatch('dimensip5/getData', { id_masterp5: dimensi.id_masterp5, id_unit: dimensi.id_unit, id_ta: dimensi.id_ta, id_unitlevel: dimensi.id_unitlevel })
            await store.dispatch('elementp5/getData', { id_masterp5: element.id_masterp5, id_unit: element.id_unit, id_ta: element.id_ta, id_unitlevel: element.id_unitlevel })
        });

        const getdimensi = computed(() => {
            //panggil getter dengan nama "getParent" di module "kelas" vuex
            return store.getters["dimensip5/getData"];
        });

        const changeDimensi = async () => {

            let jumlah = data.perPage;
            const pencarian = data.search;
            await store.dispatch("elementp5/getData", {
                page: 1,
                perPage: jumlah,
                search: pencarian,
                id_unit: target.id_unit,
                id_ta: target.id_ta,
                id_unitlevel: target.id_unitlevel,
                iddimensi: target.id_dimensip5

            });
        };


        const getelement = computed(() => {
            //panggil getter dengan nama "getParent" di module "kelas" vuex
            return store.getters["elementp5/getData"];
        });

        // function save edit hapus
        function saveDimensi() {
            let formData = new FormData();

            formData.append('id_ta', dimensi.id_ta)
            formData.append('id_unit', dimensi.id_unit)
            formData.append('id_masterp5', dimensi.id_masterp5)
            formData.append('id_unitlevel', dimensi.id_unitlevel)
            formData.append('nama_dimensi', dimensi.nama_dimensi)

            formData.append("_method", "POST");
            //panggil actions "register" dari module "auth"
            store
                .dispatch("dimensip5/store",
                    formData,
                )
                .then(() => {
                    dimensi.nama_dimensi = '';
                    Swal.fire({
                        icon: 'success',
                        title: 'Tambah data berhasil',
                    });

                    showTambahDimensi.value = false;
                    store.dispatch('dimensip5/getData', { id_masterp5: dimensi.id_masterp5, id_unit: dimensi.id_unit, id_ta: dimensi.id_ta, id_unitlevel: dimensi.id_unitlevel })

                    // toast.success("Tambah data berhasil")
                })
                .catch((error) => {
                    //show validaation message
                    // //console.log(error);

                    validation.value = error;
                });
        }

        function updateDimensi() {
            let formData = new FormData();
            formData.append('id_ta', dimensi.id_ta)
            formData.append('id_unit', dimensi.id_unit)
            formData.append('id_masterp5', dimensi.id_masterp5)
            formData.append('id_unitlevel', dimensi.id_unitlevel)
            formData.append('nama_dimensi', dimensi.nama_dimensi)
            // console.log(formData);
            formData.append("_method", "POST");

            //panggil actions "register" dari module "auth"
            store
                .dispatch("dimensip5/update", {
                    dimensiId: dimensi.id,
                    payload: formData,
                })
                .then(() => {
                    showEditDimensi.value = false;
                    dimensi.nama_dimensi = '';
                    Swal.fire({
                        icon: "success",
                        title: "Update data berhasil",
                    });

                    store.dispatch('dimensip5/getData', { id_masterp5: dimensi.id_masterp5, id_unit: dimensi.id_unit, id_ta: dimensi.id_ta, id_unitlevel: dimensi.id_unitlevel })

                    // toast.success("Tambah data berhasil")
                })
                .catch((error) => {
                    //show validaation message
                    //console.log(error);

                    validation.value = error;
                });
        }

        function confirmDeleteDimensi(id) {
            Swal.fire({
                title: 'Konfirmasi',
                text: 'Apakah Anda yakin ingin menghapus data?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Ya, hapus!',
                cancelButtonText: 'Batal',
            }).then((result) => {
                if (result.isConfirmed) {
                    store.dispatch('dimensip5/destroy', id)
                        .then((response) => {
                            console.log(response)
                            // Handle response from the server
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil',
                                    text: response.data.message,
                                    icon: 'success',
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'OK',
                                });

                                showEditDimensi.value = false;
                                //redirect ke dashboard

                                dimensi.nama_dimensi = '';
                                store.dispatch('dimensip5/getData', { id_masterp5: dimensi.id_masterp5, id_unit: dimensi.id_unit, id_ta: dimensi.id_ta, id_unitlevel: dimensi.id_unitlevel })
                                // Lakukan hal lain jika diperlukan setelah penghapusan berhasil
                            } else {
                                Swal.fire({
                                    title: 'Gagal',
                                    text: response.data.message,
                                    icon: 'error',
                                    confirmButtonColor: '#d33',
                                    confirmButtonText: 'OK',
                                });

                                showEditDimensi.value = false;
                                //redirect ke dashboard
                                store.dispatch('dimensip5/getData', { id_masterp5: dimensi.id_masterp5, id_unit: dimensi.id_unit, id_ta: dimensi.id_ta, id_unitlevel: dimensi.id_unitlevel })



                                dimensi.nama_dimensi = '';
                                // Lakukan hal lain jika diperlukan setelah penghapusan gagal
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            // Handle error jika terjadi
                            Swal.fire({
                                title: 'Error',
                                text: 'Terjadi kesalahan saat menghapus data',
                                icon: 'error',
                                confirmButtonColor: '#d33',
                                confirmButtonText: 'OK',
                            });

                            showEditDimensi.value = false;
                            //redirect ke dashboard
                            store.dispatch('dimensip5/getData', { id_masterp5: dimensi.id_masterp5, id_unit: dimensi.id_unit, id_ta: dimensi.id_ta, id_unitlevel: dimensi.id_unitlevel })
                            dimensi.nama_dimensi = '';
                        });
                }
            });
        }



        function saveElement() {
            let formData = new FormData();
            formData.append('id_ta', element.id_ta)
            formData.append('id_unit', element.id_unit)
            formData.append('id_dimensip5', element.id_dimensip5)
            formData.append('id_unitlevel', element.id_unitlevel)
            formData.append('nama_element', element.nama_element)

            formData.append("_method", "POST");
            //panggil actions "register" dari module "auth"
            store
                .dispatch("elementp5/store",
                    formData,
                )
                .then(() => {
                    element.nama_element = '';
                    Swal.fire({
                        icon: 'success',
                        title: 'Tambah data berhasil',
                    });

                    showTambahElement.value = false;
                    store.dispatch('dimensip5/getData', { id_masterp5: dimensi.id_masterp5, id_unit: dimensi.id_unit, id_ta: dimensi.id_ta, id_unitlevel: dimensi.id_unitlevel })

                    // toast.success("Tambah data berhasil")
                })
                .catch((error) => {
                    //show validaation message
                    // //console.log(error);

                    validation.value = error;
                });
        }

        function updateElement() {
            let formData = new FormData();
            formData.append('id_ta', element.id_ta)
            formData.append('id_unit', element.id_unit)
            formData.append('id_dimensip5', element.id_dimensip5)
            formData.append('id_unitlevel', element.id_unitlevel)
            formData.append('nama_element', element.nama_element)
            // console.log(formData);
            formData.append("_method", "POST");

            //panggil actions "register" dari module "auth"
            store
                .dispatch("elementp5/update", {
                    elementId: element.id,
                    payload: formData,
                })
                .then(() => {
                    showEditElement.value = false;
                    element.nama_element = '';
                    Swal.fire({
                        icon: "success",
                        title: "Update data berhasil",
                    });

                    store.dispatch('dimensip5/getData', { id_masterp5: dimensi.id_masterp5, id_unit: dimensi.id_unit, id_ta: dimensi.id_ta, id_unitlevel: dimensi.id_unitlevel })

                    store.dispatch('elementp5/getData', { id_masterp5: element.id_masterp5, id_unit: element.id_unit, id_ta: element.id_ta, id_unitlevel: element.id_unitlevel })

                    // toast.success("Tambah data berhasil")
                })
                .catch((error) => {
                    //show validaation message
                    //console.log(error);

                    validation.value = error;
                });
        }

        function confirmDeleteElement(id) {
            Swal.fire({
                title: 'Konfirmasi',
                text: 'Apakah Anda yakin ingin menghapus data?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Ya, hapus!',
                cancelButtonText: 'Batal',
            }).then((result) => {
                if (result.isConfirmed) {
                    store.dispatch('elementp5/destroy', id)
                        .then((response) => {
                            console.log(response)
                            // Handle response from the server
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil',
                                    text: response.data.message,
                                    icon: 'success',
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'OK',
                                });

                                showEditElement.value = false;
                                //redirect ke dashboard

                                element.nama_element = '';
                                store.dispatch('elementp5/getData', { id_masterp5: dimensi.id_masterp5, id_unit: dimensi.id_unit, id_ta: dimensi.id_ta, id_unitlevel: dimensi.id_unitlevel })
                                // Lakukan hal lain jika diperlukan setelah penghapusan berhasil
                            } else {
                                Swal.fire({
                                    title: 'Gagal',
                                    text: response.data.message,
                                    icon: 'error',
                                    confirmButtonColor: '#d33',
                                    confirmButtonText: 'OK',
                                });

                                showEditElement.value = false;
                                //redirect ke dashboard
                                store.dispatch('dimensip5/getData', { id_masterp5: dimensi.id_masterp5, id_unit: dimensi.id_unit, id_ta: dimensi.id_ta, id_unitlevel: dimensi.id_unitlevel })
                                element.nama_element = '';
                                // Lakukan hal lain jika diperlukan setelah penghapusan gagal
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            // Handle error jika terjadi
                            Swal.fire({
                                title: 'Error',
                                text: 'Terjadi kesalahan saat menghapus data',
                                icon: 'error',
                                confirmButtonColor: '#d33',
                                confirmButtonText: 'OK',
                            });

                            showEditElement.value = false;
                            //redirect ke dashboard
                            store.dispatch('dimensip5/getData', { id_masterp5: dimensi.id_masterp5, id_unit: dimensi.id_unit, id_ta: dimensi.id_ta, id_unitlevel: dimensi.id_unitlevel })
                            element.nama_dimensi = '';
                        });
                }
            });
        }

        function saveTarget() {
            let formData = new FormData();

            formData.append('id_ta', target.id_ta)
            formData.append('id_unit', target.id_unit)
            formData.append('id_masterp5', target.id_masterp5)
            formData.append('id_unitlevel', target.id_unitlevel)
            formData.append('id_dimensip5', target.id_dimensip5)
            formData.append('id_elementp5', target.id_elementp5)
            formData.append('nama_target', target.nama_target)

            formData.append("_method", "POST");
            //panggil actions "register" dari module "auth"
            store
                .dispatch("targetp5/store",
                    formData,
                )
                .then(() => {
                    dimensi.nama_dimensi = '';
                    Swal.fire({
                        icon: 'success',
                        title: 'Tambah data berhasil',
                    });

                    showTambahDimensi.value = false;
                    store.dispatch('dimensip5/getData', { id_masterp5: dimensi.id_masterp5, id_unit: dimensi.id_unit, id_ta: dimensi.id_ta, id_unitlevel: dimensi.id_unitlevel })

                    // toast.success("Tambah data berhasil")
                })
                .catch((error) => {
                    //show validaation message
                    // //console.log(error);

                    validation.value = error;
                });
        }

        function confirmDeleteTarget(id) {
            Swal.fire({
                title: 'Konfirmasi',
                text: 'Apakah Anda yakin ingin menghapus data?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Ya, hapus!',
                cancelButtonText: 'Batal',
            }).then((result) => {
                if (result.isConfirmed) {
                    store.dispatch('targetp5/destroy', id)
                        .then((response) => {
                            console.log(response)
                            // Handle response from the server
                            if (response.success) {
                                Swal.fire({
                                    title: 'Berhasil',
                                    text: response.data.message,
                                    icon: 'success',
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'OK',
                                });

                                showEditDimensi.value = false;
                                //redirect ke dashboard

                                dimensi.nama_dimensi = '';
                                store.dispatch('dimensip5/getData', { id_masterp5: dimensi.id_masterp5, id_unit: dimensi.id_unit, id_ta: dimensi.id_ta, id_unitlevel: dimensi.id_unitlevel })
                                // Lakukan hal lain jika diperlukan setelah penghapusan berhasil
                            } else {
                                Swal.fire({
                                    title: 'Gagal',
                                    text: response.data.message,
                                    icon: 'error',
                                    confirmButtonColor: '#d33',
                                    confirmButtonText: 'OK',
                                });

                                showEditDimensi.value = false;
                                //redirect ke dashboard
                                store.dispatch('dimensip5/getData', { id_masterp5: dimensi.id_masterp5, id_unit: dimensi.id_unit, id_ta: dimensi.id_ta, id_unitlevel: dimensi.id_unitlevel })



                                dimensi.nama_dimensi = '';
                                // Lakukan hal lain jika diperlukan setelah penghapusan gagal
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            // Handle error jika terjadi
                            Swal.fire({
                                title: 'Error',
                                text: 'Terjadi kesalahan saat menghapus data',
                                icon: 'error',
                                confirmButtonColor: '#d33',
                                confirmButtonText: 'OK',
                            });

                            showEditDimensi.value = false;
                            //redirect ke dashboard
                            store.dispatch('dimensip5/getData', { id_masterp5: dimensi.id_masterp5, id_unit: dimensi.id_unit, id_ta: dimensi.id_ta, id_unitlevel: dimensi.id_unitlevel })
                            dimensi.nama_dimensi = '';
                        });
                }
            });
        }

        function updateTarget() {
            let formData = new FormData();
            formData.append('id_ta', target.id_ta)
            formData.append('id_unit', target.id_unit)
            formData.append('id_dimensip5', target.id_dimensip5)
            formData.append('id_unitlevel', target.id_unitlevel)
            formData.append('id_elementp5', target.id_elementp5)
            formData.append('nama_target', target.nama_target)
            console.log(formData);
            formData.append("_method", "POST");

            //panggil actions "register" dari module "auth"
            store
                .dispatch("targetp5/update", {
                    targetId: target.id,
                    payload: formData,
                })
                .then(() => {
                    showEditTarget.value = false;
                    element.nama_target = '';
                    Swal.fire({
                        icon: "success",
                        title: "Update data berhasil",
                    });

                    store.dispatch('dimensip5/getData', { id_masterp5: dimensi.id_masterp5, id_unit: dimensi.id_unit, id_ta: dimensi.id_ta, id_unitlevel: dimensi.id_unitlevel })


                    // toast.success("Tambah data berhasil")
                })
                .catch((error) => {
                    //show validaation message
                    //console.log(error);

                    validation.value = error;
                });
        }

        return {
            data,
            dimensi,
            getdimensi,
            target,
            validation,
            showTambahDimensi,
            openTambahDimensi,
            closeTambahDimensi,
            saveDimensi,
            Swal,
            openEditDimensi,
            closeEditDimensi,
            showEditDimensi,
            updateDimensi,
            confirmDeleteDimensi,
            element,
            getelement,
            showTambahElement,
            openTambahElement,
            closeTambahElement,
            saveElement,
            openEditElement,
            showEditElement,
            closeEditElement,
            updateElement,
            confirmDeleteElement,
            saveTarget,
            changeDimensi,
            openEditTarget,
            confirmDeleteTarget,
            showEditTarget,
            closeEditTarget,
            updateTarget
        }

    }
}



</script>



<style scoped>
/* Add your modal styling here */
.modal {
    /* Add styles for overlay/background */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;

}


.modal-content {
    /* Add styles for the modal content */
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
}

/* Gaya untuk tabel utama */
table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    /* Untuk memberikan sedikit ruang antara tabel dan elemen lainnya */
}

/* Gaya untuk elemen header tabel (thead) */
thead {
    background-color: #f2f2f2;
    /* Warna latar belakang untuk header */
}

/* Gaya untuk sel header tabel (th) */
th,
td {
    border: 1px solid #ddd;
    /* Garis tepi untuk sel header dan sel data */
    padding: 8px;
    /* Ruang dalam di dalam sel */
    text-align: left;
    /* Aligment teks ke kiri */
}

/* Gaya untuk baris tabel (tr) */
tr:nth-child(even) {
    background-color: #f9f9f9;
    /* Warna latar belakang untuk baris genap */
}

/* Gaya saat kursor berada di atas sel */
td:hover {
    background-color: #e0e0e0;
    /* Warna latar belakang saat kursor di atas sel */
}
</style>