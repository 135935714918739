<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">PPDB</h5>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="storeRule">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label>Panduan Pendaftaran </label>
                        <!-- <textarea  v-model="ppdb.panduan" class="form-control"></textarea> -->
                        <ckeditor :editor="editor"  v-model="ppdb.ppdb_rule" :config="editorConfig"></ckeditor>
                        <div v-if="validation.ppdb_rule" class="mt-2">
                          <b-alert show variant="danger">{{
                            validation.ppdb_rule[0]
                          }}</b-alert>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                     
                      <img
                        :src="`https://api.annisaa-izada.sch.id/uploads/settingapps/${ppdb.ppdb_rule_image}`"
                        width="300px"
                        alt="gambar role pendaftaran"
                      />
                      <div class="form-group">
                        <label for="">Upload Photo Role </label>
                        <input
                          type="file"
                          ref="fileupload"
                          class="form-control"
                          @change="handleFilePhotoChange"
                          placeholder="upload gambar rule ppdb"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-md">
                        simpan
                      </button>
                      &nbsp;
                      <router-link
                      :to="{ name: 'admin-jadwalpelaksanaan' }"
                      class="btn btn-danger btn-sm"
                      >kembali</router-link
                    >
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
    <script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Swal from "sweetalert2";

export default {
  name: "JadwalpelaksanaanIndex",
  components: {},
  data() {
    return {
      showMenu: false,
      editor: ClassicEditor,
        editorData: '<p>Content of the editor.</p>',
        editorConfig: {
            // The configuration of the editor.
        }
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state
    const ppdb = reactive({
      id_ta: "",
      ppdb_rule_image: "",
      ppdb_rule: "",
      file: "",
    });

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    function handleFilePhotoChange(e) {
  const file = (this.ppdb.file = e.target.files[0]);
  const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

  if (allowedTypes.includes(file.type)) {
    // Code for handling allowed file types

  } else {
    // If fileType not allowed, then clear value and set null
    e.target.value = "";
    this.user.photo = null;

    // Show SweetAlert error message
    Swal.fire({
      title: "OOPS!",
      text: "Format File Tidak Didukung!",
      icon: "error",
      showConfirmButton: false,
      timer: 2000,
    });
  }
}

    //function register, fungsi ini di jalankan ketika form di submit
    function storeRule() {
      //define variable
      let panduan = ppdb.ppdb_rule;
      let file = ppdb.ppdb_rule_image;

      let formData = new FormData();

      formData.append("file_upload", file);
      formData.append("ppdb_rule", panduan);
      formData.append("_method", "POST");

      //panggil actions "register" dari module "auth"
      store
        .dispatch("settingapp/store", formData)
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-jadwalpelaksanaan" });
          Swal.fire({
            icon: "success",
            title: "Update data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });
    }

    onMounted(async () => {
      await store.dispatch("settingapp/getData");
    });

    watch(
      () => store.state.settingapp.settingapps,
      (newPpdb) => {
        if (newPpdb) {
          const { ppdb_rule, ppdb_rule_image } = newPpdb;
          ppdb.ppdb_rule = ppdb_rule;
         // //console.log(ppdb_rule)
          ppdb.ppdb_rule_image = ppdb_rule_image;
        }
      }
    );

    //computed
    const setting = computed(() => {
      //panggil getter dengan nama "getParent" di module "menu" vuex
      return store.getters["settingapp/getData"];
    });

    //return a state and function
    return {
      ppdb, // <-- state user
      validation, // <-- state validation
      storeRule,
      handleFilePhotoChange,
      setting,
    };
  },
};
</script>