<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Edit Aspek Sikap</h5>

            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="updatekategorisikap">
                  <!-- <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1"
                          >Tahun Ajaran <span class="text-danger">*</span></label
                        >
                        <select class="form-control" v-model="kategorisikap.id_ta">
                        <option value="">-- select tahun ajaran --</option>
                        <option v-for="tahunajaran in tahunajarans" :key="tahunajaran.id" :value="tahunajaran.id">{{ tahunajaran.desc}}</option>
                      </select>
                        <small class="text-danger" v-if="validation.id_ta">{{ validation.id_ta[0] }}</small>
                      </div>
                    </div>
                  </div> -->
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">
                          Unit </label>
                        <select class="form-control" v-model="kategorisikap.unit">
                          <option value="">-- select unit --</option>
                          <option v-for="u in units" :key="u.id" :value="u.id">{{ u.code }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.unit">{{ validation.unit[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Nama Aspek</label>
                        <input type="text" placeholder="name" class="form-control"
                          v-model="kategorisikap.nama_kategorisikap" />
                        <small class="text-danger" v-if="validation.nama_kategorisikap">{{
                          validation.nama_kategorisikap[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <!--  
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">
                        Kategori Parent <small class="text-danger">Kosongkan jika tidak ada parent</small></label
                        >
                        <select class="form-control" v-model="kategorisikap.parent_id">
                        <option value="">-- select kategori sikap --</option>
                        <option v-for="u in parent" :key="u.id" :value="u.id">{{ u.nama_kategori}}</option>
                      </select>
                        <small class="text-danger" v-if="validation.parent_id">{{ validation.parent_id[0] }}</small>
                      </div>
                    </div>
                  </div>
                -->
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="Type Kategori">Jenis Penilaian</label>
                        <select v-model="kategorisikap.type" class="form-control">
                          <option value="">Pilih type</option>
                          <option value="mapel">Penilaian Per Mata Pelajaran</option>
                          <option value="non_mapel">Penilaian Non Mata Pelajaran</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row" v-if="kategorisikap.type === 'non_mapel'">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">
                          Group </label>
                        <select class="form-control" v-model="kategorisikap.group_id">
                          <option value="">-- select group --</option>
                          <option v-for="u in group" :key="u.id" :value="u.id">{{ u.nama_group }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.group_id">{{ validation.group_id[0] }}</small>
                      </div>
                    </div>
                  </div>



                  <div class="row" v-if="kategorisikap.type === 'mapel'">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlSelect2">Keterangan Nilai</label>
                        <select multiple class="form-control" id="exampleFormControlSelect2"
                          v-model="kategorisikap.keterangannilai_id">
                          <option v-for="u in keterangannilai" :key="u.id" :value="u.id">{{ u.kode }}</option>
                        </select>
                        <small class="text-danger" v-if="validationketerangannilai_id">{{
                          validation.keterangannilai_id[0]
                        }}</small>
                      </div>
                    </div>
                  </div>



                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">
                        simpan
                      </button>
                      &nbsp;
                      <router-link :to="{ name: 'admin-kategorisikap' }"
                        class="btn btn-danger btn-sm">kembali</router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";

export default {
  name: "kategorisikapIndex",
  components: {},
  data() {
    return {
      showkategorisikap: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state
    const kategorisikap = reactive({
      nama_kategorisikap: '',
      unit: '',
      id_ta: '',
      parent_id: '',
      group_id: '',
      type: '',
      keterangannilai_id: [],
    });

    const data = reactive({
      pages: 'kategorisikap-edit'
    })

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    const route = useRoute();

    onMounted(async () => {
      await store.dispatch('tahunajaran/getAll'),
        await store.dispatch('units/getAll');
      await store.dispatch('kategorisikap/getAll');
      await store.dispatch('groupkategorisikap/getAll');
      await store.dispatch('keterangannilai/getAll');
      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    //computed
    const tahunajarans = computed(() => {
      return store.getters["tahunajaran/getAll"];
    });

    const units = computed(() => {
      return store.getters["units/getAll"];
    });

    const parent = computed(() => {
      return store.getters["kategorisikap/getAll"];
    });

    const group = computed(() => {
      return store.getters["groupkategorisikap/getAll"];
    });

    const keterangannilai = computed(() => {
      return store.getters["keterangannilai/getAll"];
    });


    //function register, fungsi ini di jalankan ketika form di submit
    function updatekategorisikap() {
      //define variable


      let formData = new FormData();
      formData.append('nama_kategori', this.kategorisikap.nama_kategorisikap)
      formData.append('unit_id', this.kategorisikap.unit)
      formData.append('id_ta', this.kategorisikap.id_ta)
      formData.append('parent_id', this.kategorisikap.parent_id)
      formData.append('type_kategori', this.kategorisikap.type)
      formData.append('group_id', this.kategorisikap.group_id)
      formData.append("keterangannilai_id[]", this.kategorisikap.keterangannilai_id);
      formData.append("_method", "POST");

      //panggil actions "register" dari module "auth"
      store
        .dispatch("kategorisikap/update", {
          kategorisikapId: route.params.id,
          payload: formData,
        })
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-kategorisikap" });
          Swal.fire({
            icon: "success",
            title: "Update data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });
    }

    onMounted(async () => {
      await store.dispatch("kategorisikap/getDetail", route.params.id);
    });

    watch(
      () => store.state.kategorisikap.details,
      (newkategorisikap) => {
        if (newkategorisikap) {
          const {
            nama_kategori,
            unit_id,
            id_ta,
            parent_id,
            group_id,
            keterangannilai_id,
            type_kategori
          } = newkategorisikap;
          kategorisikap.nama_kategorisikap = nama_kategori;
          kategorisikap.unit = unit_id;
          kategorisikap.id_ta = id_ta;
          kategorisikap.parent_id = parent_id;
          kategorisikap.group_id = group_id;
          kategorisikap.keterangannilai_id = JSON.parse(keterangannilai_id);
          kategorisikap.type = type_kategori


        }
      }
    );

    //computed


    //return a state and function
    return {
      kategorisikap, // <-- state user
      validation, // <-- state validation
      updatekategorisikap,
      tahunajarans,
      units,
      parent,
      group,
      keterangannilai
    };
  },
};
</script>