<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">

            <div class="d-flex justify-content-between">
              <h5 class="mb-0">List Siswa Raport Per Kelas</h5>
              <router-link to="/kelas" class="btn btn-primary btn-sm">Kembali</router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="row justify-content-between ">
              <div class="col-md-1">
                <div class="d-flex justify-content-between align-items-center">
                  <span>Show: </span>
                  <select v-model="data.perPage" class="form-control form-control-sm ml-2" @change="changePerPage">
                    <option v-for="option in perPageOptions" :key="option" :value="option">{{ option }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 d-flex mb-3">
                <input type="text" class="form-control mr-2" v-model="data.search" @keypress.enter="searchData"
                  placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2">
                <button @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2"> <i
                    class="fa fa-search"></i> </button>
              </div>
            </div>
            <div class="table-responsive">
              <table id="datatable-search" class="table table-flush small-table" style="font-size: 12px">
                <thead class="thead-light">
                  <tr style="max-height: 5px">
                    <th>NISN</th>
                    <th>Fullname</th>
                    <th>Nama File</th>
                    <th>#</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="m in siswas.data" :key="m.id" style="height: 1px">
                    <td>{{ m ? m.nis : '' }}</td>
                    <td>{{ m ? m.fullname : '' }}</td>
                    <td>{{ m.name_original }}</td>
                    <td>
                      <input type="file" ref="fileupload" class="form-control" :data-id="m.id"
                        @change="handleFileChange" placeholder="upload raport" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>


          </div>

          <div class="card-footer d-flex justify-content-between">
            <span>
              <p>Menampilkan {{ siswas.to }} dari {{ siswas.total }}</p>
            </span>
            <nav aria-label="Pagination">
              <ul class="pagination justify-content-end">
                <li class="page-item" :class="{ disabled: siswas.current_page === 1 }">
                  <a class="page-link" @click="changePage(siswas.current_page - 1)" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li class="page-item" v-for="page in siswas.last_page" :key="page"
                  :class="{ active: page === siswas.current_page }">
                  <a class="page-link" @click="changePage(page)" href="#">{{ page }}</a>
                </li>
                <li class="page-item" :class="{ disabled: siswas.current_page === siswas.last_page }">
                  <a class="page-link" @click="changePage(siswas.current_page + 1)" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive, ref, refs } from "vue";
import { useStore } from "vuex";
import Swal from 'sweetalert2';
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";

export default {
  name: "kelasIndex",


  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const validation = ref([]);
    const data = reactive({
      // currentPage: 1,
      perPage: 100,
      search: '',
      raport: {
        file: '',
        id_studentactive: ''
      },
      pages: 'uploadkelulusan-view'
    });



    onMounted(async () => {
      await store.dispatch("siswa/getSiswaLulusData", {
        page: 1,
        perPage: data.perPage,
        search: data.search,
        kelasId: route.params.id
      });
      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    const siswas = computed(() => {
      return store.getters["siswa/getSiswaLulusData"];
    });

    const perPageOptions = [5, 10, 15, 25, 50, 100, 250, 500, 1000];

    async function handleFileChange(e) {

      // //console.log('ini adalah', e.target.attributes[1].nodeValue);
      let file = data.raport.file = e.target.files[0];
      if (!file.type.match('pdf.*')) {

        //if fileType not allowed, then clear value and set null
        e.target.value = ''

        //set state "category.image" to null
        data.raport.file = null

        //show sweet alert
        Swal.fire({
          title: 'OOPS!',
          text: "Format File Tidak Didukung!",
          icon: 'error',
          showConfirmButton: false,
          timer: 2000
        })
      }

      let formData = new FormData();
      // formData.append('kelasId', this.$route.params.id)
      formData.append('file', data.raport.file)
      formData.append('id_studentactives', e.target.attributes[2].nodeValue)

      await store.dispatch('siswa/saveSkl', formData)
        //success
        .then(() => {
          Swal.fire({
            title: 'BERHASIL!',
            text: "Data Berhasil Disimpan!",
            icon: 'success',
            showConfirmButton: false,
            timer: 2000
          })



          store.dispatch("siswa/getSiswaLulus", route.params.id);


          router.push({
            name: 'admin-listsiswalulus',
            params: { id: route.params.id },
          });
          refs.fileupload = null;


        }).catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });


    }


    const changePage = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("siswa/getSiswaLulus", {
        page: page,
        perPage: jumlah,
        search: pencarian,
        kelasId: route.params.id

      });
    };

    const changePerPage = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("siswa/getSiswaLulus", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        kelasId: route.params.id
      });
    };



    const searchData = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("siswa/getSiswaLulus", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        kelasId: route.params.id
      });


      // Lakukan tindakan yang diinginkan dengan nilai searchQuery
    };




    setNavPills();
    setTooltip(store.state.bootstrap);

    return {
      siswas,
      validation,
      changePage,
      changePerPage,
      perPageOptions,
      data,
      searchData,
      handleFileChange

    };
  },
};
</script>