<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Data Pendaftar Lulus</h5>
              <div class="button"></div>
            </div>
          </div>
          <div class="card-body">
            <div class="row justify-content-between">
              <div class="col-md-1">
                <div class="d-flex justify-content-between align-items-center">
                  <span>Show: </span>
                  <select v-model="data.perPage" class="form-control form-control-sm ml-2" @change="changePerPage">
                    <option v-for="option in perPageOptions" :key="option" :value="option">
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 d-flex mb-3">
                <input type="text" class="form-control mr-2" v-model="data.search" @keypress.enter="searchData"
                  placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2" />
                <button @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="row my-3">
              <div class="col ms-auto my-auto mr-5">
                <div class="input-group mb-3">
                  <select class="form-control select2" v-model="data.ta" @change="changeTa">
                    <option value="">-- select tahun ajaran --</option>
                    <option v-for="tahunajaran in tas" :key="tahunajaran.id" :value="tahunajaran.id">
                      {{ tahunajaran.desc }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col ms-auto my-auto mr-5">
                <div class="input-group mb-3">
                  <select class="form-control select2" v-model="data.unit" @change="changeUnit">
                    <option value="">-- select Unit --</option>
                    <option v-for="unit in units" :key="unit.id" :value="unit.id">
                      {{ unit.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col ms-auto my-auto mr-5">
                <div class="input-group mb-3">
                  <select class="form-control select2" v-model="data.ppdb" @change="changePpdb">
                    <option value="">-- select PPDB--</option>
                    <option v-for="daftar in datapendaftar" :key="daftar.id" :value="daftar.id">
                      {{ daftar.name }}-{{ daftar.period }}-{{
                    daftar.source
                  }}-{{ daftar.type }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col ms-auto my-auto mr-5">

                <div class="input-group mb-3">
                  <select class="form-control select2" v-model="data.status" @change="changeStatus">
                    <option value="">-- select status --</option>
                    <option v-for="status in data.options" :key="status.value" :value="status.value">
                      {{ status.text }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col ms-auto my-auto mr-5">

                <div class="input-group mb-3">
                  <select class="form-control select2" v-model="data.paynote" @change="changePaynote">
                    <option value="">-- select paynote --</option>
                    <option v-for="p in data.listPaynote" :key="p.value" :value="p.value">
                      {{ p.text }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col ms-auto my-auto mr-5">
                <a :href="`${this.$baseURL}/export?tahunajaran=${data.ta}&unit=${data.unit}&ppdb=${data.ppdb}&status=${data.status}`"
                  class="btn btn-primary btn-md"><i class="fa fa-file-excel-o" aria-hidden="true"></i>
                  export
                </a>
                <!-- <a :href="`http://localhost:8000/exportsibling?tahunajaran=${data.ta}&unit=${data.unit}&ppdb=${data.ppdb}&status=${data.status}`"
                  class="btn btn-success btn-md"><i class="fa fa-file-excel-o" aria-hidden="true"></i>
                  Sibling
                </a> -->
              </div>
            </div>
            <div class="table-responsive">
              <table id="datatable-search" class="table table-flush small-table" style="font-size: 12px">
                <thead class="thead-light">
                  <tr style="max-height: 5px">
                    <th>Data Lengkap</th>
                    <th class="text-center">Tagihan</th>
                    <th class="text-center">Kelengkapan Berkas</th>
                    <th class="text-center">Promo Label</th>
                    <th class="text-center">Sibling</th>
                    <th class="text-center">#</th>
                  </tr>
                </thead>
                <tbody>

                  <tr v-for="m in pendaftarlulus.data" :key="m.id" style="height: 1px">
                    <td>
                      <b>{{ m.fullname }}</b>
                      <br/>{{ m.asal_sekolah ?? '-' }}
                      <br />{{ m.noreg }} <br />
                      Tanggal Daftar
                      {{
                    new Date(m.created_at).toLocaleString("id-ID", {
                      timeZone: "Europe/Moscow",
                      hourCycle: "h23",
                      year: "numeric",
                      month: "short",
                      day: "2-digit",
                    })
                  }}
                      <br />No Hp {{ m.cellphone ?? "-" }} <br />
                      <span v-if="m.status == 'Registered'">Terdaftar</span>
                      <span v-else-if="m.status == 'Active'">Aktif</span>
                    </td>
                    <td>
                      <b-badge v-if="m.pendaftaran != null" class="badge badge-sm badge-success">pendaftaran</b-badge>
                      <b-badge v-else class="badge badge-sm badge-danger">pendaftaran</b-badge>
                      <b-badge v-if="m.psikotest != null" class="badge badge-sm badge-success">psikotest</b-badge>
                      <b-badge v-else class="badge badge-sm badge-danger">psikotest</b-badge>
                      <b-badge v-if="m.paynote == 'Fullpayment'"
                        class="badge badge-sm badge-success">Fullpayment</b-badge>
                      <b-badge v-if="m.paynote == 'Installment'" class="badge badge-sm badge-info">Installment</b-badge>
                      <b-badge v-if="m.paynote == 'Unpaid'" class="badge badge-sm badge-danger">Unpaid</b-badge>

                    </td>
                    <td class="text-center">
                      <b-badge class="badge badge-sm badge-success"
                        v-if="m.wni && m.nik && m.nikk && m.religion && m.childno && m.lang != null">Biodata</b-badge>
                      <b-badge class="badge badge-sm badge-danger" v-else>Biodata </b-badge>
                      <b-badge class="badge badge-sm badge-success"
                        v-if="m.orang_tua.fullname && m.orang_tua.id_relation && m.orang_tua.id_work && m.orang_tua.birthplace && m.orang_tua.birthdate && m.orang_tua.wni && m.orang_tua.religion && m.orang_tua.edulevel && m.orang_tua.income && m.orang_tua.address && m.orang_tua.id_province && m.orang_tua.id_district && m.orang_tua.id_subdistrict && m.orang_tua.postalcode && m.orang_tua.cellphone && m.orang_tua.email != null">Family</b-badge>
                      <b-badge class="badge badge-sm badge-danger" v-else>Family </b-badge>

                      <!-- living -->

                      <b-badge class="badge badge-sm badge-success"
                        v-if="m.tempat_tinggal.address && m.tempat_tinggal.id_province && m.tempat_tinggal.id_district && m.tempat_tinggal.id_subdistrict && m.tempat_tinggal.rt_number && m.tempat_tinggal.rw_number && m.tempat_tinggal.postalcode && m.tempat_tinggal.id_modatranspoertation && m.tempat_tinggal.cellphone != null">Living</b-badge>
                      <b-badge class="badge badge-sm badge-danger" v-else>Living </b-badge>



                      <b-badge class="badge badge-sm badge-success" v-if="m.document_photo.length == 1">Photo</b-badge>
                      <b-badge class="badge badge-sm badge-danger" v-if="m.document_photo.length == 0">Photo</b-badge>
                      <b-badge class="badge badge-sm badge-success" v-if="m.document_k_k.length == 1">KK</b-badge>
                      <b-badge class="badge badge-sm badge-danger" v-if="m.document_k_k.length == 0">KK</b-badge>
                      <b-badge class="badge badge-sm badge-success" v-if="m.document_akta.length == 1">Akta</b-badge>
                      <b-badge class="badge badge-sm badge-danger" v-if="m.document_akta.length == 0">Akta</b-badge>
                    </td>
                    <td class="text-center">
                      {{ m.promo_label ?? '-' }}
                    </td>
                    <td class="text-center">
                      {{ m.saudara1 }} {{ m.kelas_saudara1 ? `(${m.kelas_saudara1})` : '' }} {{m.saudara2 ? '|' : ''}} {{ m.saudara2 }}{{ m.kelas_saudara2 ? `(${m.kelas_saudara2})` : '' }} {{m.saudara3 ? '|' : ''}}  {{ m.saudara3 }}{{ m.kelas_saudara3 ? `(${m.kelas_saudara3})` : '' }} {{m.saudara4 ? '|' : ''}}  {{ m.saudara4 }}{{ m.kelas_saudara4 ? `(${m.kelas_saudara4})` : '' }}
                    </td>
                    <td class="text-center">
                      <button v-if="datapermissions.includes('pendaftarlulus-edit')" class="btn btn-primary btn-xs m-0"
                        @click="showModal(m)">
                        update
                      </button>
                      &nbsp;

                      <router-link v-if="datapermissions.includes('pendaftarlulus-edit')"
                        :to="{ name: 'admin-pendaftarlulus-show', params: { id: m.id } }"
                        class="btn btn-success btn-xs m-0 me-2"> <i class="fas fa-eye"></i></router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card-footer d-flex justify-content-between">
            <span>
              <p>
                Menampilkan {{ pendaftarlulus.to }} dari
                {{ pendaftarlulus.total }}
              </p>
            </span>
            <nav aria-label="Pagination">
              <ul class="pagination justify-content-end">
                <li class="page-item" :class="{ disabled: pendaftarlulus.current_page === 1 }">
                  <a class="page-link" @click="changePage(pendaftarlulus.current_page - 1)" href="#"
                    aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li class="page-item" v-for="page in pendaftarlulus.last_page" :key="page"
                  :class="{ active: page === pendaftarlulus.current_page }">
                  <a class="page-link" @click="changePage(page)" href="#">{{
                    page
                  }}</a>
                </li>
                <li class="page-item" :class="{
                    disabled:
                      pendaftarlulus.current_page ===
                      pendaftarlulus.last_page,
                  }">
                  <a class="page-link" @click="changePage(pendaftarlulus.current_page + 1)" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";

export default {
  name: "PendaftarlulusIndex",

  setup() {
    const store = useStore();
    const data = reactive({
      // currentPage: 1,
      perPage: 10,
      search: "",
      ta: "",
      ppdb: "",
      pages: 'pendaftarlulus-view',
      unit: "",
      seleksi: [],
      statuskirim: false,
      paymentstatus: '',
      label: '',
      paynote: "",
      listPaynote: [
        { value: 'Unpaid', text: "Belum Bayar" },
        { value: 'Fullpayment', text: "Sudah Bayar" },
        { value: 'Installment', text: "Cicilan" },
      ],
      pesan: "",
      status: "",
      options: [
        { value: 'Passed', text: "Lulus" },
        { value: 'Conditional Active Student', text: "Lulus Bersyarat" },
        { value: 'Backup Active Student', text: "Lulus Cadangan" },
        { value: 'Fail', text: "Tidak Lulus" },
      ],
    });

    const router = useRouter();

    onMounted(async () => {
      await store.dispatch("reg/getDataLulus");
      await store.dispatch("tahunajaran/getAll");
      await store.dispatch("units/getAll");
      await store.dispatch("reg/getPpdb");
      await store.dispatch("user/getDataPermission");
      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    const pendaftarlulus = computed(() => {
      return store.getters["reg/getDataLulus"];
    });

    const tas = computed(() => {
      return store.getters["tahunajaran/getAll"];
    });

    const datapermissions = computed(() => {
      return store.getters["user/getDataPermission"];
    });
    const units = computed(() => {
      if (!data.ta) {
        // data.unit = "";
        return "";

      } else {
        return store.getters["units/getAll"];
      }

    });

    const datapendaftar = computed(() => {
      if (!data.unit) {
        // data.unit = "";
        return "";

      } else {
        return store.getters["reg/getPpdb"];
      }

    });

    const perPageOptions = [5, 10, 15, 25, 50, 100];

    const changePage = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("reg/getDataLulus", {
        page: page,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        unit: data.unit,
        ppdb: data.ppdb,
        status: data.status,
        paynote: data.paynote
      });
    };

    const changePerPage = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("reg/getDataLulus", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        unit: data.unit,
        ppdb: data.ppdb,
        status: data.status,
        paynote: data.paynote
      });
    };

    const searchData = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("reg/getDataLulus", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        unit: data.unit,
        ppdb: data.ppdb,
        status: data.status,
        paynote: data.paynote
      });
    };

    const changeTa = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("reg/getDataLulus", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        unit: data.unit,
        ppdb: data.ppdb,
        status: data.status,
        paynote: data.paynote
      });

      await store.dispatch("reg/getPpdb", {
        tasId: data.ta,
        unit: data.unit,
      });
    };

    const changeUnit = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("reg/getDataLulus", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        status: data.status,
        unit: data.unit,
        ppdb: data.ppdb,
        paynote: data.paynote
      });

      await store.dispatch("reg/getPpdb", {
        tasId: data.ta,
        unit: data.unit,
      });
    };

    const changePpdb = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("reg/getDataLulus", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        status: data.status,
        unit: data.unit,
        ppdb: data.ppdb,
        paynote: data.paynote
      });
    };

    const changeStatus = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("reg/getDataLulus", {
        page: page,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        unit: data.unit,
        ppdb: data.ppdb,
        status: data.status,
        paynote: data.paynote
      });
    };

    const changePaynote = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("reg/getDataLulus", {
        page: page,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        unit: data.unit,
        ppdb: data.ppdb,
        status: data.status,
        paynote: data.paynote
      });
    };


    const modalData = ref(null);
    const isModalVisible = ref(false);

    const showModal = (m) => {
      modalData.value = m;
      isModalVisible.value = true;


      // const data = {
      //   statuskirim: false,
      // };

      Swal.fire({
        // title: "Update Data",
        // title: "Update Data",
        html: `
    <div class="form-group">
      <h5>${m.fullname} <br/> Kelas ${m.unitlevel_name}</h5>

      <div class="form-group">
        <label for="">Uang Pangkal</label><br/>
        <select
          class="form-control select2"
          id="statuspaynote"
          v-model="data.statuspayment"
        >
          <option value="">-- select --</option>
          <option value='Unpaid'>Unpaid</option>
          <option value='Installment'>Cicilan</option>
          <option value='Fullpayment'>Lunas</option>
        </select>
      
      </div>

      <div class="form-group">
        <label for="">Promo Label</label><br/>
        <input type="checkbox" name="label[]" id="datalabel" v-model="label" value="Early Bird">&nbsp; Early Bird <br/>
                              <input type="checkbox" name="label[]" v-model="label" value="Periodik 1">&nbsp; Periodik 1 <br/>
                              <input type="checkbox" name="label[]" v-model="label" value="Periodik 2">&nbsp; Periodik 2 <br/>
                              <input type="checkbox" name="label[]" v-model="label" value="Periodik 3">&nbsp; Periodik 3 <br/>
                              <input type="checkbox" name="label[]" v-model="label" value="Sibling">&nbsp; Sibling<br/>
                              <input type="checkbox" name="label[]" v-model="label"  value="Keeping the star">&nbsp; Keeping the star <br/>
                              <input type="checkbox" name="label[]" v-model="label"  value="Beasiswa Prestasi SMA">&nbsp; Beasiswa Prestasi SMA<br/>
                              <input type="checkbox" name="label[]" v-model="label"  value="Alumni">&nbsp; Alumni
      </div>


   
    </div>
  `,

        preConfirm: () => {
          // Retrieve the selected values and update datas.statuses and datas.statuskirims
          data.statuspayment = document.getElementById("statuspaynote").value;
          const selectedCheckboxes = document.querySelectorAll('input[name="label[]"]:checked');

          // Menginisialisasi array kosong untuk menyimpan nilai checkbox yang dipilih
          data.label = [];

          // Iterasi melalui setiap checkbox yang dipilih dan menambahkannya ke dalam array
          selectedCheckboxes.forEach((checkbox) => {
            data.label.push(checkbox.value);
          });
        },
        confirmButtonColor: "#3085d6", // Warna tombol "Update" (biru)
        cancelButtonColor: "#d33", // Warna tombol "Batal" (merah)
        confirmButtonText: "Update!",
        cancelButtonText: "Batal",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const formData = new FormData();
          formData.append("ids", m.id);
          formData.append('paynotestatus', data.statuspayment)


          Array.from(data.label).forEach(label => {
            formData.append("promo_label[]", label);
          })


          formData.append("_method", "POST");

          store.dispatch("reg/updatepaynote", formData).then(() => {
            store.dispatch("reg/getDataLulus");
            //
            // router.push({ name: "admin-menu" });
            router.push({ name: "admin-pendaftarlulus" });

            Swal.fire({
              title: "BERHASIL!",
              text: "Data Berhasil Diupdate!",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
            });
          });
        }
      });
    };

    function confirmDelete(id) {
      Swal.fire({
        title: "Konfirmasi",
        text: "Apakah Anda yakin ingin menghapus data?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch("reg/destroyPendaftarBaru", id).then(() => {
            store.dispatch("reg/getDataLulus");
            //
            // router.push({ name: "admin-menu" });
            router.push({ name: "admin-pendaftarlulus" });
            //alert
            Swal.fire({
              title: "BERHASIL!",
              text: "Data Berhasil Dihapus!",
              icon: "success",
              showConfirmButton: false,
              timer: 2000,
            });
          });
        }
      });
    }

    setNavPills();
    setTooltip(store.state.bootstrap);

    return {
      
      pendaftarlulus,
      changePage,
      changePerPage,
      perPageOptions,
      data,
      searchData,
      confirmDelete,
      changeTa,
      changeUnit,
      changePpdb,
      changePaynote,
      changeStatus,
      tas,
      units,
      datapendaftar,
      showModal,
      datapermissions

    };
  },
};
</script>

<style>
.form-group {
  text-align: left;
}
</style>