<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">

            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Data Siswa Baru dan pindahan</h5>

            </div>
          </div>
          <div class="card-body">
            <div class="row justify-content-between ">

              <div class="col-md-1">
                <div class="d-flex justify-content-between align-items-center">
                  <span>Show: </span>
                  <select v-model="data.perPage" class="form-control form-control-sm ml-2" @change="changePerPage">
                    <option v-for="option in perPageOptions" :key="option" :value="option">{{ option }}</option>
                  </select>
                </div>
              </div>
              <div class="col-3 ms-auto my-auto mr-5">
                <div class="input-group mb-3">
                  <select class="form-control select2" v-model="data.level" @change="changeUnitlevel">
                    <option value="">-- select Unitlevel --</option>
                    <option v-for="unitlevels in unitlevel" :key="unitlevels.id" :value="unitlevels.id">
                      {{ unitlevels.code }} - {{ unitlevels.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 d-flex mb-3">
                <input type="text" class="form-control mr-2" v-model="data.search" @keypress.enter="searchData"
                  placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2">
                <button @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2"> <i
                    class="fa fa-search"></i> </button>
              </div>
            </div>
            <div class="row">







            </div>
            <div class="table-responsive">
              <table id="datatable-search" class="table table-flush small-table" style="font-size: 12px">
                <thead class="thead-light">
                  <tr style="max-height: 5px">
                    <th>Data Siswa</th>
                    <th>Unitlevel</th>


                  </tr>
                </thead>
                <tbody>
                  <tr v-for="m in siswas" :key="m.id" style="height: 1px">
                    <td>{{ m.fullname }}</td>
                    <td>{{ m.name }}</td>

                  </tr>
                </tbody>
              </table>
            </div>


          </div>

          <div class="card-footer d-flex justify-content-between">
            <span>
              <p>Menampilkan {{ siswas.to }} dari {{ siswas.total }}</p>
            </span>
            <nav aria-label="Pagination">
              <ul class="pagination justify-content-end">
                <li class="page-item" :class="{ disabled: siswas.current_page === 1 }">
                  <a class="page-link" @click="changePage(siswas.current_page - 1)" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li v-for="page in siswas.links" :key="page"
                  :class="{ 'page-item': true, 'active': page.active === true }">
                  <a class="page-link" @click="changePage(page.label)" href="#"
                    v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{ page.label }}</a>
                </li>
                <li class="page-item" :class="{ disabled: siswas.current_page === siswas.last_page }">
                  <a class="page-link" @click="changePage(siswas.current_page + 1)" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import Swal from 'sweetalert2';
import { useRouter } from "vue-router";

export default {
  name: "siswaIndex",

  setup() {
    const store = useStore();
    const data = reactive({
      // currentPage: 1,
      perPage: 100,
      search: '',
      kelas: '',
      unitlevel: '',
      level: '',
      unit: '',
      pages: "siswakelasbaru-view"

    });

    const router = useRouter();

    onMounted(async () => {
      await store.dispatch("siswa/SiswaBarudanPindahan");
      await store.dispatch("units/getAll");
      await store.dispatch("unitlevel/getAll");
      await store.dispatch("masterkelas/getAll");
      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    const siswas = computed(() => {
      return store.getters["siswa/SiswaBarudanPindahan"];
    });

    const units = computed(() => {
      return store.getters["units/getAll"];
    });

    const unitlevel = computed(() => {
      return store.getters["unitlevel/getAll"];
    });

    const kelasmaster = computed(() => {
      return store.getters["masterkelas/getAll"];
    });




    const perPageOptions = [5, 10, 15, 25, 50, 100];


    const changePage = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("siswa/SiswaBarudanPindahan", {
        page: page,
        perPage: jumlah,
        search: pencarian,
        kelas: data.kelas,
        unit: data.unit,
        unitlevel: data.level

      });
    };

    const changePerPage = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("siswa/SiswaBarudanPindahan", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        kelas: data.kelas,
        unit: data.unit,
        unitlevel: data.level
      });
    };

    const changeUnit = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("siswa/SiswaBarudanPindahan", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        kelas: data.kelas,
        unit: data.unit,
        unitlevel: data.level
      });

      await store.dispatch("unitlevel/getAll", data.unit);
    };

    const changeUnitlevel = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("siswa/SiswaBarudanPindahan", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        kelas: data.kelas,
        unit: data.unit,
        unitlevel: data.level
      });

      await store.dispatch("masterkelas/getAll", data.level);
    };

    const changeKelas = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("siswa/SiswaBarudanPindahan", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        kelas: data.kelas,
        unit: data.unit,
        unitlevel: data.level
      });


    };



    const searchData = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("siswa/SiswaBarudanPindahan", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        kelas: data.kelas,
        unit: data.unit,
        unitlevel: data.level
      });


      // Lakukan tindakan yang diinginkan dengan nilai searchQuery
    };

    function confirmDelete(id) {
      Swal.fire({
        title: 'Konfirmasi',
        text: 'Apakah Anda yakin ingin menghapus data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Ya, hapus!',
        cancelButtonText: 'Batal',
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch('siswa/destroy', id)
            .then(() => {

              store.dispatch("siswa/SiswaBarudanPindahan");
              // 
              // router.push({ name: "admin-siswa" });
              router.push({ name: "admin-siswa" });
              //alert
              Swal.fire({
                title: 'BERHASIL!',
                text: "Data Berhasil Dihapus!",
                icon: 'success',
                showConfirmButton: false,
                timer: 2000
              })

            })
        }
      });
    }


    setNavPills();
    setTooltip(store.state.bootstrap);

    return {
      siswas,
      changePage,
      changePerPage,
      perPageOptions,
      data,
      searchData,
      confirmDelete,
      units,
      unitlevel,
      kelasmaster,
      changeUnit,
      changeUnitlevel,
      changeKelas

    };
  },
};
</script>