//import axios
import axios from 'axios'

const Api = axios.create({



    // baseURL: 'http://localhost:8000/api'
    // baseURL: 'https://api-v2.ypii.net/api'
    //  baseURL: 'https://api-v2.annisaa-izada.sch.id/api'

    // baseURL: 'http://localhost:8000/api',
    // image: 'http://localhost:8000'
    // baseURL: 'https://api-v2.ypii.net/api'
    // image: 'https://api-v2.ypii.net/'
    baseURL: 'https://api-v2.annisaa-izada.sch.id/api',
    image: 'https://api-v2.annisaa-izada.sch.id/'

})

export default Api