<template>
  <div id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-auto h-100">
    <ul class="navbar-nav">

      <li class="nav-item">
        <router-link :to="{ name: 'dashboard' }" class="nav-link">
          <div class="icon icon-shape icon-sm text-center d-flex align-items-center justify-content-center">
            <i class="ni ni-ui-04 text-primary text-sm opacity-10"></i>
          </div>
          <span class="nav-link-text ms-1">Dashboard </span>
        </router-link>
      </li>

      <li class="nav-item" v-if="users.id">
        <sidenav-collapse collapse-ref="dashboardCollapse" nav-text="Profile"
          :class="getRoute() === 'default' ? 'active' : ''">
          <template #icon>
            <i class="ni ni-chart-pie-35 text-primary text-sm opacity-10"></i>
          </template>
          <template #list>

            <ul class="nav ms-4">
              <sidenav-item :to="{ name: 'admin-change-profile', params: { id: users ? users.id : '' } }" mini-icon="H"
                text="Edit Profile" />
              <sidenav-item :to="{ name: 'admin-change-password', params: { id: users ? users.id : '' } }" mini-icon="A"
                text="Edit Password" />

            </ul>
          </template>

        </sidenav-collapse>
      </li>

      <li class="nav-item" v-for="(menu, index) in menus" :key="index">

        <sidenav-collapse collapse-ref="dashboardsExamples" :nav-text="index"
          :class="getRoute() === 'dashboards' ? 'active' : ''">
          <template #icon>

            <!-- <i :class="'ni ' + menu.icon + ' text-primary text-sm opacity-10'"></i> -->
            <i class="ni ni-bold-right text-primary text-sm opacity-10"></i>
          </template>
          <template #list v-if="this.$route.name != 'login'">
            <ul class="nav ms-4">
              <!-- nav links -->

              <sidenav-item v-for="m in menu" :key="m.id" :to="{ name: m.link != '#' ? m.link : '' }" mini-icon="L"
                :text="m.name" />

            </ul>
          </template>
        </sidenav-collapse>
      </li>


    </ul>
  </div>
  <div class="mt-3 sidenav-footer"></div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
// import user from "../../store/module/user";

export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },

  setup() {
    //store vuex
    const store = useStore();

    //mounted 
    onMounted(async () => {
      //panggil action "getOrder" di module "order" vuex
      await store.dispatch("menu/getMenu");
      await store.dispatch('auth/getUser');
      await store.dispatch('auth/isLoggedIn');

    });

    const users = computed(() => {
      //panggil getters dengan nama "currentUser" dari module "auth"
      return store.getters['auth/currentUser']
    })

    const isLoggedIn = computed(() => {

      //get getter "isLoggedIn" dari module "auth"
      return store.getters['auth/isLoggedIn']

    })

    const token = isLoggedIn.value;

    //computed
    const menus = computed(() => {
      //panggil getter dengan nama "getOrder" di module "order" vuex
      return store.getters["menu/getMenu"];
    });

    //return a state and function
    return {
      store,
      menus,
      users,
      token
    };
  },
};
</script>
