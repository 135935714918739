<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Edit subkategoripenilaian</h5>

            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <form @submit.prevent="updatesubkategoripenilaian">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Tahun Ajaran <span class="text-danger">*</span></label>
                        <select class="form-control" v-model="subkategoripenilaian.id_ta">
                          <option value="">-- select tahun ajaran --</option>
                          <option v-for="tahunajaran in tahunajarans" :key="tahunajaran.id" :value="tahunajaran.id">{{
                  tahunajaran.desc }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.id_ta">{{ validation.id_ta[0] }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Kategori <span class="text-danger">*</span></label>
                        <select class="form-control" v-model="subkategoripenilaian.id_kategoripenilaian">
                          <option value="">-- select kategori --</option>
                          <option v-for="k in kategoris" :key="k.id" :value="k.id">{{ k.level }} - {{
                  k.nama_kategoripenilaian }} - {{ k.desc }}</option>
                        </select>
                        <small class="text-danger" v-if="validation.id_kategoripenilaian">{{
                  validation.id_kategoripenilaian[0] }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Type <span class="text-danger">*</span></label>
                        <select class="form-control" v-model="subkategoripenilaian.type">
                          <option value="">-- select type --</option>
                          <option value="Tugas">Tugas</option>
                          <option value="PH">Penilaian Harian</option>
                          <option value="PAS">Penilaian Akhir</option>
                          <option value="K">Keterampilan</option>
                          <option value="P"></option>
                          
                        </select>
                        <small class="text-danger" v-if="validation.id_kategoripenilaian">{{
                  validation.id_kategoripenilaian[0] }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Nama subkategori </label>
                        <input type="text" placeholder="name" class="form-control"
                          v-model="subkategoripenilaian.nama_subkategoripenilaian" />
                        <small class="text-danger" v-if="validation.nama_subkategoripenilaian">{{
                  validation.nama_subkategoripenilaian[0] }}</small>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Jumlah Generate </label>
                        <input type="number" placeholder="name" class="form-control"
                          v-model="subkategoripenilaian.jumlah_generate" min="1" />
                        <small class="text-danger" v-if="validation.jumlah_generate">{{ validation.jumlah_generate[0]
                          }}</small>
                      </div>
                    </div>
                  </div>


                  <div class="row">
                    <div class="col-12">
                      <button type="submit" class="btn btn-success btn-sm">
                        simpan
                      </button>
                      &nbsp;
                      <router-link :to="{ name: 'admin-subkategoripenilaian' }"
                        class="btn btn-danger btn-sm">kembali</router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { ref, reactive, onMounted, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import Swal from "sweetalert2";

export default {
  name: "subkategoripenilaianIndex",
  components: {},
  data() {
    return {
      showsubkategoripenilaian: false,
    };
  },

  mounted() {
    setNavPills();
    setTooltip(this.$store.state.bootstrap);
    // eslint-disable-next-line no-unused-vars
  },

  setup() {
    //user state
    const subkategoripenilaian = reactive({
      nama_subkategoripenilaian: '',
      id_ta: '',
      id_kategoripenilaian: '',
      jumlah_generate: '',
      type:''
    });

    const data = reactive({
      // currentPage: 1,

      pages: "subkategoripenilaian-edit"

    });

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    const route = useRoute();

    onMounted(async () => {
      await store.dispatch('subkategoripenilaian/getAll');
      await store.dispatch('kategoripenilaian/getAll');
      await store.dispatch('tahunajaran/getAll')
      await store.dispatch("subkategoripenilaian/getDetail", route.params.id);
      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    //computed
    const kategoris = computed(() => {
      return store.getters["kategoripenilaian/getAll"];
    });

    const tahunajarans = computed(() => {
      return store.getters["tahunajaran/getAll"];
    });



    //function register, fungsi ini di jalankan ketika form di submit
    function updatesubkategoripenilaian() {
      //define variable


      let formData = new FormData();
      formData.append('id_ta', this.subkategoripenilaian.id_ta)
      formData.append('nama_subkategoripenilaian', this.subkategoripenilaian.nama_subkategoripenilaian)
      formData.append('id_kategoripenilaian', this.subkategoripenilaian.id_kategoripenilaian)
      formData.append('jumlah_generate', this.subkategoripenilaian.jumlah_generate)
      formData.append('type', this.subkategoripenilaian.type)
      formData.append("_method", "POST");

      //panggil actions "register" dari module "auth"
      store
        .dispatch("subkategoripenilaian/update", {
          subkategoripenilaianId: route.params.id,
          payload: formData,
        })
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "admin-subkategoripenilaian" });
          Swal.fire({
            icon: "success",
            title: "Update data berhasil",
          });

          // toast.success("Tambah data berhasil")
        })
        .catch((error) => {
          //show validaation message
          //console.log(error);

          validation.value = error;
        });
    }




    watch(
      () => store.state.subkategoripenilaian.details,
      (newsubkategoripenilaian) => {
        if (newsubkategoripenilaian) {
          const {
            id_ta,
            nama_subkategoripenilaian,
            id_kategoripenilaian,
            jumlah_generate,
            type
          } = newsubkategoripenilaian;
          subkategoripenilaian.id_ta = id_ta;
          subkategoripenilaian.type = type;
          subkategoripenilaian.nama_subkategoripenilaian = nama_subkategoripenilaian;
          subkategoripenilaian.id_kategoripenilaian = id_kategoripenilaian;
          subkategoripenilaian.jumlah_generate = jumlah_generate;


        }
      }
    );

    //computed


    //return a state and function
    return {
      subkategoripenilaian, // <-- state user
      validation, // <-- state validation
      updatesubkategoripenilaian,
      kategoris,
      tahunajarans
    };
  },
};
</script>