import Api from '../../api/Api'
const roles = {

    //set namespace true
    namespaced: true,

    //state
    state: {
       
        dataroles: [],
        parent:[],
        detailrole:{},
        getall:[]
    },

    //mutations
    mutations: {
       
        SET_ROLE(state, dataroles) {
            state.dataroles = dataroles
        },
        GET_ALL(state, getall) {
            state.getall = getall
        },
        SET_PARENT(state, parent) {
            state.parent = parent
        },
        GET_DETAIL_ROLE(state,  detailrole) {
            state. detailrole =  detailrole
        },
      
    },

    _actions: {
      
        getDetailRole({ commit }, payload) {

            //define variable token
            const token = localStorage.getItem('token')

            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/role/edit/${payload}`)
                .then(response => {
                     //console.log('ini', response.data.data)
                    //commit ke mutation GET_ORDER
                    commit('GET_DETAIL_ROLE', response.data.data)

                })

        },
        getData({ commit }, payload) {
            // //console.log('ini parsing',payload);
            let page = payload ? payload.page : ''
            let perpage = payload ? payload.perPage : ''
            let pencarian = payload ? payload.search : ''
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/role?page=${page}&jumlahperpage=${perpage}&pencarian=${pencarian}`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('SET_ROLE', response.data.data)
                })
        },

        getAll({ commit }) {
            // //console.log('ini parsing',payload);
           
            const token = localStorage.getItem('token')
            Api.defaults.headers.common['Authorization'] = "Bearer " + token
            Api.get(`/role/getall`)
                .then(response => {
                    // //console.log('ini parent',response.data.data)
                    commit('GET_ALL', response.data.data)
                })
        },

      
        destroy({ commit }, payload) {
            // Define variable token
            const token = localStorage.getItem('token')

            return new Promise((resolve, reject) => {
                Api.defaults.headers.common['Authorization'] = "Bearer " + token
                Api.delete(`/role/delete/${payload}`)
                    .then(response => {
                        // Commit ke mutation SET_MENU
                        commit('SET_ROLE', response.data.data)
                        resolve(response)
                    })
                    .catch(error => {
                        console.error(error.response.data)
                        reject(error.response.data)
                    })
            })
        },


        store({ commit }, payload) {
            // //console.log('ini menu', menu);
            // //console.log('store',payload)
            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                //send data ke server
                // Api.post('/menu', {
                //     name: menu.name,
                //     link: menu.link,
                //     icon: menu.icon,
                //     key_permission: menu.key_permission,
                //     position: menu.position,
                //     parent_id: menu.parent_id,
                //     order: menu.order
                // })
                Api.post('/role/create', payload)

                    .then(response => {

                        // //console.log(response.data.data)
                        // //console.log(response.data.data)
                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        update({ commit }, { roleId, payload }) {



            //define callback promise
            return new Promise((resolve, reject) => {

                const token = localStorage.getItem('token')

                //set axios header dengan type Authorization + Bearer token
                Api.defaults.headers.common['Authorization'] = `Bearer ${token}`

                //send data ke server
                // Api.post('/menu', {
                //     name: menu.name,
                //     link: menu.link,
                //     icon: menu.icon,
                //     key_permission: menu.key_permission,
                //     position: menu.position,
                //     parent_id: menu.parent_id,
                //     order: menu.order
                // })
                Api.post(`/role/update/${roleId}`, payload)

                    .then(response => {

                        commit('')
                        resolve(response)

                    }).catch(error => {


                        //reject ke component dengan hasil response
                        reject(error.response.data)

                    })

            })
        },

        
    },
    get actions() {
        return this._actions
    },
    set actions(value) {
        this._actions = value
    },

    //getters
    getters: {
        getData(state) {
            return state.dataroles
        },

        getAll(state) {
            return state.getall
        },

        getDetailRole(state){
            return state.detailrole
        }
    }

}

export default roles