<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Edit Role</h5>
            </div>
          </div>
          <div class="card-body">
            <form @submit.prevent="updateRole">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="exampleFormControlInput1">Nama Role</label>
                    <input type="text" placeholder="Nama Role" class="form-control" v-model="role.name" />
                    <small class="text-danger" v-if="validation.name">{{
                      validation.name[0]
                    }}</small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-check">
                    <input type="checkbox" v-model="selectAll" @change="toggleAllPermissions"
                      class="form-check-input" />
                    <label>Select All</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-3" v-for="permission in allpermission" :key="permission.id">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="role.permissions" :value="permission.name"
                      :id="`check-${permission.id}`" />
                    <label class="custom-control-label" :for="`check-${permission.id}`">{{ permission.name }}</label>
                  </div>
                </div>
              </div>

              <div class="row mt-5">
                <div class="col-12">
                  <button type="submit" class="btn btn-success btn-sm">
                    simpan
                  </button>
                  &nbsp;
                  <router-link :to="{ name: 'admin-role' }" class="btn btn-danger btn-sm">kembali</router-link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Swal from 'sweetalert2';

export default {
  name: "RoleEdit",
  setup() {
    const validation = ref([]);
    const store = useStore();
    // const data = reactive({});
    const router = useRouter();
    const route = useRoute();

    const role = reactive({
      name: "",
      permissions: [],
    });

    const data = reactive({
      pages: "roles-edit"
    });

    const selectAll = ref(false);

    onMounted(async () => {
      await store.dispatch("permission/getAll");
      await store.dispatch("roles/getDetailRole", route.params.id);
      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }

    });

    watch(
      () => store.state.roles.detailrole,
      (newRole) => {
        if (newRole) {
          const { name, permissions } = newRole;
          role.name = name;
          role.permissions = permissions.map((obj) => obj.name);
        }
      }
    );

    const allpermission = computed(() => {
      return store.getters["permission/getAll"];
    });

    const toggleAllPermissions = () => {
      if (selectAll.value) {
        // Jika selectAll bernilai true, tambahkan semua izin ke dalam array permissions
        role.permissions = allpermission.value.map(
          (permission) => permission.name
        );
      } else {
        // Jika selectAll bernilai false, kosongkan array permissions
        role.permissions = [];
      }
    };

    setNavPills();
    setTooltip(store.state.bootstrap);

    function updateRole() {
      let formData = new FormData();

      formData.append("name", role.name);
      Array.from(role.permissions).forEach((permission) => {
        formData.append("permissions[]", permission);
      });

      // console.log('form insert',formData);

      // sending data to action "storeRole" vuex
      store
        .dispatch("roles/update", {
          roleId: route.params.id,
          payload: formData
        })

        //success
        .then(() => {
          //sweet alert
          store.dispatch("menu/getMenu");
          router.push({ name: "admin-role" });
          Swal.fire({
            icon: "success",
            title: "Edit data berhasil",
          });
        })

        //error
        .catch((error) => {
          //console.log(error);

          validation.value = error;
          Swal.fire({
            icon: "error",
            title: validation.value.name[0]
          });
        });
    }

    return {
      allpermission,
      data,
      role,
      router,
      selectAll,
      updateRole,
      toggleAllPermissions,
      validation,
    };
  },
};
</script>
