<template>

  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div
              class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0"
            >
              <div class="card">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Login</h4>
                  <p class="mb-0">
                    Sistem Akademik <b>Sekolah Annisaa Izada</b>
                  </p>
                </div>

                <div class="card-body">
                  <small class="text-danger mb-5" v-if="validation"
                  > {{  validation.message }}</small
                >
                
                  <form role="form" @submit.prevent="login">
                    <div class="mb-3">
                      <input
                      type="text"
                      class="form-control form-control-lg"
                      placeholder="Username/Email"
                      aria-label="username"
                      v-model="user.username"
                    />
                    <small class="text-danger" v-if="validation.username"
                      >Email atau username wajib diisi</small
                    >
                    </div>
                    <div class="mb-3">
                      <input
                          type="password"
                          class="form-control form-control-lg"
                          v-model="user.password"
                          placeholder="Password"
                          aria-label="Password"
                        />
                        <small class="text-danger" v-if="validation.password"
                          >Password wajib diisi</small
                        >
                    </div>
                 
                    <div class="text-center">
                      <argon-button
                        type="submit"
                        class="mt-4"
                        variant="gradient"
                        color="success"
                        full-width
                        size="lg"
                        > {{ isLoading ? 'Loading...' : 'Sign in' }}
                        </argon-button
                      >
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  
                </div>
              </div>
            </div>
            <div
              class="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column"
            >
              <div
                class="position-relative h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                :style="{
                  backgroundImage:
                    'url(' +
                    'https://psb.annisaa-izada.sch.id/img/bg-login02.7ba46cf2.jpg' +
                    ')',
                    backgroundSize: 'cover'
                }"
              >
                <span class="mask bg-gradient-success opacity-6"></span>

                <h4
                    class="mt-5 text-white font-weight-bolder position-relative"
                  >
                    "Sekolah An-Nisaa’ Izada"
                  </h4>
                  <p class="text-white position-relative">
                    Global Leadeship School With Islamic Values
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>

import ArgonButton from "@/components/ArgonButton.vue";
import { ref, reactive, onMounted  } from "vue";
// import { useStore, mapMutations } from "vuex";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
// const body = document.getElementsByTagName("body")[0];
export default {
  name: "SigninIllustration",
  components: {
    ArgonButton,
  },
  // created() {
  //   this.$store.state.hideConfigButton = true;
  //   this.toggleDefaultLayout();
  //   body.classList.remove("bg-gray-100");
  // },
  // beforeUnmount() {
  //   this.$store.state.hideConfigButton = false;
  //   this.toggleDefaultLayout();
  //   body.classList.add("bg-gray-100");
  // },
  setup() {
    //user state
    const isLoading = ref(false);
    const user = reactive({
      username: "",
      password: "",
    });
    

    //validation state
    const validation = ref([]);

    //store vuex
    const store = useStore();

    //route
    const router = useRouter();

    //method login
    function login() {
     
      isLoading.value = true;
      //define variable
      let username = user.username;
      let password = user.password;

      // //console.log('iniusername',password);

      //panggil action "login" dari module "auth" di vuex
      store
        .dispatch("auth/login", {
          username,
          password,
        })
        .then(() => {
          //redirect ke dashboard
          router.push({ name: "dashboard" });
        })
        .catch((error) => {
          //assign validaation message
          validation.value = error;
          isLoading.value = false;
        });
    }

    onMounted(() => {
    if(store.getters['auth/isLoggedIn']) {
       router.push({name: 'dashboard'})
    }
  })

    //return object
    return {
      user,
      validation,
      login,
      isLoading
    };
  },
  // methods: {
  //   ...mapMutations(["toggleDefaultLayout"]),
  // },
};
</script>
