<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">

            <div class="d-flex justify-content-between">
              <h5 class="mb-0">List Siswa Per Kelas</h5>
              <router-link :to="{ name: 'admin-kelas', query:{id:$route.query.id}, params: { idta: $route.params.idta } }"
                class="btn btn-danger btn-sm">kembali</router-link>
            </div>
          </div>
          <div class="card-body">
            <table style="width: 20%;">
              <tr>
                <td>Nama Kelas</td>
                <td>{{ kelas.classname }}</td>
              </tr>

            </table>
            <div class="row justify-content-between ">
              <div class="col-md-1">
                <div class="d-flex justify-content-between align-items-center">
                  <span>Show: </span>
                  <select v-model="data.perPage" class="form-control form-control-sm ml-2" @change="changePerPage">
                    <option v-for="option in perPageOptions" :key="option" :value="option">{{ option }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 d-flex mb-3">
                <input type="text" class="form-control mr-2" v-model="data.search" @keypress.enter="searchData"
                  placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2">
                <button @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2"> <i
                    class="fa fa-search"></i> </button>
              </div>
            </div>
            <div class="table-responsive">
              <table id="datatable-search" class="table table-flush small-table" style="font-size: 12px">
                <thead class="thead-light">
                  <tr style="max-height: 5px">
                    <th>No</th>
                    <th>NISN</th>
                    <th>Fullname</th>
                    <th>No Urut Absen</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(m, index) in siswas.data" :key="m.id" style="height: 1px">
                    <td>{{ index + 1 }}</td>
                    <td>{{ m ? m.nis : '' }}</td>
                    <td>{{ m ? m.fullname : '' }}</td>
                    <td>

                      <!-- <input type="number"  class="form-control"  :data-id="m.id"  @keypress.enter="updateData" :value="m.order"  placeholder="urutan siswa"/> -->
                      <input type="number" class="form-control" min="1" :data-id="m.id" @input="updateData"
                        v-model="m.order" placeholder="urutan siswa" v-if="m.id_ta == tasaktif.id" />
                      <input type="number" class="form-control" min="1" :data-id="m.id" @input="updateData"
                        v-model="m.order" placeholder="urutan siswa" readonly v-else />

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>


          </div>

          <div class="card-footer d-flex justify-content-between">
            <span>
              <p>Menampilkan {{ siswas.to }} dari {{ siswas.total }}</p>
            </span>
            <nav aria-label="Pagination">
              <ul class="pagination justify-content-end">
                <li class="page-item" :class="{ disabled: siswas.current_page === 1 }">
                  <a class="page-link" @click="changePage(siswas.current_page - 1)" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li class="page-item" v-for="page in siswas.last_page" :key="page"
                  :class="{ active: page === siswas.current_page }">
                  <a class="page-link" @click="changePage(page)" href="#">{{ page }}</a>
                </li>
                <li class="page-item" :class="{ disabled: siswas.current_page === siswas.last_page }">
                  <a class="page-link" @click="changePage(siswas.current_page + 1)" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive, watch } from "vue";
import { useStore } from "vuex";
// import Swal from 'sweetalert2';
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";

export default {
  name: "kelasIndex",


  setup() {

    const kelas = reactive({
      classname: '',
      id_ta: '',
      wali_kelas: '',
      guru_pendamping: '',
      id_unitlevel: '',
    });
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const data = reactive({
      // currentPage: 1,
      perPage: 100,
      search: '',
       pages: 'managementkelas-view'


    });


    onMounted(async () => {
      await store.dispatch("siswa/getSiswaKelasData", {
        page: 1,
        perPage: data.perPage,
        search: data.search,
        kelasId: route.params.id
      });

      await store.dispatch("kelas/getDetail", route.params.id);

      await store.dispatch("tahunajaran/getActive");
      const permissions = localStorage.getItem('permission');

      if (!permissions.includes(data.pages)) {
        router.push('/error'); // Ganti '/error-page' dengan rute halaman error Anda
      }
    });

    const tasaktif = computed(() => {
      return store.getters["tahunajaran/getActive"];
    });
    const siswas = computed(() => {
      return store.getters["siswa/getSiswaKelasData"];
    });

    const perPageOptions = [5, 10, 15, 25, 50, 100, 250, 500, 1000];


    watch(
      () => store.state.kelas.details,
      (newkelas) => {
        if (newkelas) {
          const {
            master_kelas,
            id_ta,
            id_instructor_1,
            id_instructor_2

          } = newkelas;
          kelas.classname = master_kelas.classname;
          kelas.id_ta = id_ta;
          kelas.wali_kelas = id_instructor_1;
          kelas.guru_pendamping = id_instructor_2;
          kelas.id_unitlevel = master_kelas.id_unitlevel;

        }
      }
    );

    const changePage = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("siswa/getSiswaKelasData", {
        page: page,
        perPage: jumlah,
        search: pencarian,
        kelasId: route.params.id

      });
    };

    const changePerPage = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("siswa/getSiswaKelasData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        kelasId: route.params.id
      });
    };

    async function updateData(e) {
      const inputValue = e.target.value;
      if (inputValue != '') {
        let formData = new FormData();
        formData.append('order', e.target.value)
        formData.append('id', e.target.attributes[3].nodeValue)


        await store.dispatch('siswa/updateOrder', formData);
        // Swal.fire({
        // title: 'BERHASIL!',
        // text: "Data Berhasil Diupdate!",
        // icon: 'success',
        // showConfirmButton: false,
        // timer: 2000
        // });
        router.push({
          // path:'/admin'
          name: 'admin-kelas-listsiswa',
          params: { kelasId: route.params.id },
        });


        const pencarian = data.search;
        let jumlah = data.perPage;

        store.dispatch("siswa/getSiswaKelasData", {
          page: 1,
          perPage: jumlah,
          search: pencarian,
          kelasId: route.params.id
        });
      }



    }


    const searchData = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("siswa/getSiswaKelasData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        kelasId: route.params.id
      });


      // Lakukan tindakan yang diinginkan dengan nilai searchQuery
    };




    setNavPills();
    setTooltip(store.state.bootstrap);

    return {
      siswas,
      changePage,
      changePerPage,
      perPageOptions,
      data,
      searchData,
      updateData,
      tasaktif,
      kelas,

    };
  },
};
</script>
