<template>
  <div class="py-4 container-fluid">
    <div class="mt-4 row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header">

            <div class="d-flex justify-content-between">
              <h5 class="mb-0">Data Master P5 </h5>
              <div class="gap-2">
                <router-link :to="'/masterp5/' + $route.params.id + '/create'"
                  class="btn btn-primary btn-sm">Tambah</router-link>
                <router-link :to="{ name: 'admin-p5' }" class="btn btn-danger btn-sm text-white">kembali</router-link>
              </div>

            </div>
          </div>
          <div class="card-body">
            <div class="row justify-content-between ">
              <div class="col-md-1">
                <div class="d-flex justify-content-between align-items-center">
                  <span>Show: </span>
                  <select v-model="data.perPage" class="form-control form-control-sm ml-2" @change="changePerPage">
                    <option v-for="option in perPageOptions" :key="option" :value="option">{{ option }}</option>
                  </select>
                </div>
              </div>
              <div class="col-md-4 d-flex mb-3">
                <input type="text" class="form-control mr-2" v-model="data.search" @keypress.enter="searchData"
                  placeholder="pencarian" aria-label="pencarian" aria-describedby="button-addon2">
                <button @click="searchData" class="btn btn-success mb-0 ms-2" type="button" id="button-addon2"> <i
                    class="fa fa-search"></i> </button>
              </div>
            </div>
            <div class="row">
              <div class="col ms-auto my-auto mr-5">
                <div class="form-group">
                  <label for="">Tahun Ajaran</label>
                  <select class="form-control select2" v-model="data.ta" @change="changeTa">
                    <option value="">-- select tahun ajaran --</option>
                    <option v-for="tahunajaran in tas" :key="tahunajaran.id" :value="tahunajaran.id">
                      {{ tahunajaran.desc }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col ms-auto my-auto mr-5">

              </div>

            </div>
            <div class="table-responsive">
              <table id="datatable-search" class="table table-flush small-table" style="font-size: 12px">
                <thead class="thead-light">
                  <tr style="max-height: 5px">
                    <th>Data</th>
                    <th style="width: 100px;">Desc</th>
                    <th class="text-center"># </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="m in masterp5.data" :key="m.id" style="height: 1px">

                    <td>
                      <b> Proyek </b>{{ m.proyek }}<br />
                      <b> Nama </b>{{ m.nama }}<br />
                      <b>Tema</b> {{ m.tema }}<br />
                      <b> Tahun Ajaran </b>{{ m.tahun_ajaran }}<br />
                      <b> Unit </b>{{ m.code }}<br />
                      <b> Unitlevel </b>{{ m.unitlevels }}<br />
                   
                    



                    </td>
                    <td style="width: 500px; white-space: pre-wrap;">
                      {{ m.deskripsi }}
                    </td>


                    <td class="text-center">
                      <router-link
                        :to="{ name: 'admin-masterp5-edit', params: { id: m.id, idunitlevel: m.id_unitlevel }, query: { idkelas: $route.params.id } }"
                        class="btn btn-success btn-xs m-0 me-2"> <i class="fas fa-pen"></i></router-link>

                      <button class="btn btn-danger btn-xs m-0 me-2" @click="confirmDelete(m.id)"> <i
                          class="fas fa-trash"></i> </button>

                      <router-link
                        :to="{ name: 'admin-masterp5-form', params: { id: m.id, idunit: m.id_unit, idta: m.id_ta, idunitlevel: m.id_unitlevel }, query: { idkelas: $route.params.id } }"
                        class="btn btn-secondary btn-xs m-0 me-2"> <i class="fas fa-eye"></i></router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="card-footer d-flex justify-content-between">
            <span>
              <p>Menampilkan {{ masterp5.to }} dari {{ masterp5.total }}</p>
            </span>
            <nav aria-label="Pagination">
              <ul class="pagination justify-content-end">
                <li class="page-item" :class="{ disabled: masterp5.current_page === 1 }">
                  <a class="page-link" @click="changePage(masterp5.current_page - 1)" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                  </a>
                </li>
                <li v-for="page in masterp5.links" :key="page"
                  :class="{ 'page-item': true, 'active': page.active === true }">
                  <a class="page-link" @click="changePage(page.label)" href="#"
                    v-if="page.label != 'pagination.previous' && page.label != 'pagination.next'">{{ page.label }}</a>
                </li>
                <li class="page-item" :class="{ disabled: masterp5.current_page === masterp5.last_page }">
                  <a class="page-link" @click="changePage(masterp5.current_page + 1)" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import setNavPills from "@/assets/js/nav-pills.js";
import setTooltip from "@/assets/js/tooltip.js";
import { computed, onMounted, reactive, watch } from "vue";
import { useStore } from "vuex";
import Swal from 'sweetalert2';
import { useRoute, useRouter } from "vue-router";
// import { useRoute } from "vue-router";

export default {
  name: "masterp5Index",


  setup() {
    const store = useStore();
    //  const route = useRoute();


    const router = useRouter();
    const route = useRoute();

    onMounted(async () => {
      await store.dispatch("masterp5/getData", {
        page: 1,
        perPage: data.perPage,
        search: data.search,
        tasId: data.ta,
        id: route.params.id
      });
      await store.dispatch("tahunajaran/getAll");
      await store.dispatch("tahunajaran/getActive");
      await store.dispatch("user/getDataPermission");
    });

    const datapermissions = computed(() => {
      return store.getters["user/getDataPermission"];
    });

    const masterp5 = computed(() => {
      return store.getters["masterp5/getData"];
    });

    const tas = computed(() => {
      return store.getters["tahunajaran/getAll"];
    });

    const tasaktif = computed(() => {
      return store.getters["tahunajaran/getActive"];
    });



    const data = reactive({
      // currentPage: 1,
      perPage: 10,
      search: '',
      ta: ''

    });

    watch(
      () => store.state.tahunajaran.active,
      (newactive) => {
        if (newactive) {
          const {
            id,


          } = newactive;
          data.ta = id;


        }
      }
    );

    const perPageOptions = [5, 10, 15, 25, 50, 100];


    const changePage = async (page) => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("masterp5/getData", {
        page: page,
        perPage: jumlah,
        search: pencarian,
        kelasid: route.params.id

      });
    };

    const changeTa = async () => {
      let jumlah = data.perPage;
      const pencarian = data.search;
      await store.dispatch("masterp5/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        tasId: data.ta,
        kelasid: route.params.id


      });
    };

    const changePerPage = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;

      await store.dispatch("masterp5/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        kelasid: route.params.id
      });
    };

    const searchData = async () => {
      const pencarian = data.search;
      let jumlah = data.perPage;
      await store.dispatch("masterp5/getData", {
        page: 1,
        perPage: jumlah,
        search: pencarian,
        kelasid: route.params.id
      });


      // Lakukan tindakan yang diinginkan dengan nilai searchQuery
    };

    function confirmDelete(id) {
      Swal.fire({
        title: 'Konfirmasi',
        text: 'Apakah Anda yakin ingin menghapus data?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Ya, hapus!',
        cancelButtonText: 'Batal',
      }).then((result) => {
        if (result.isConfirmed) {
          store.dispatch('masterp5/destroy', id)
            .then(() => {

              store.dispatch("masterp5/getData");

              router.push({ name: "admin-mastermasterp5" });
              //alert
              Swal.fire({
                title: 'BERHASIL!',
                text: "Data Berhasil Dihapus!",
                icon: 'success',
                showConfirmButton: false,
                timer: 2000
              })



            })
        }
      });
    }


    setNavPills();
    setTooltip(store.state.bootstrap);

    return {
      masterp5,
      changePage,
      changePerPage,
      perPageOptions,
      data,
      searchData,
      confirmDelete,
      tas,
      changeTa,
      tasaktif,
      datapermissions


    };
  },
};
</script>

<style>
.horizontal-list {
  list-style: none;
  padding: 0;
  display: flex;
}

.horizontal-list li {
  margin-right: 10px;
  /* Adjust the margin as needed */
}
</style>